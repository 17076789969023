export class TippingInfo {
    level_one;
    level_two;
    level_three;

    constructor() {
        this.level_one = 5;
        this.level_two = 7;
        this.level_three = 11;
    }
}