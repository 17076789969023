import React, { useState, useImperativeHandle, forwardRef } from "react";
import { useFirestore } from 'reactfire';
import { Col, Row, Form, Button, Container, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { AlertModal } from "../../utils/models/AlertModal";
import UsersDAO from './models/UsersDAO';
import firebase from 'firebase/app';
import { Constants } from "../../utils/constants";
import Preloader from "../../components/Preloader";
import useAlert from "../../utils/hooks/useAlert";
import { castFields } from "../../utils/utils";

export const UsersAddUpdate = forwardRef((_, ref) => {
  const [loaded, setLoaded] = useState(false);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [showModal, setShowModal] = useState(false);
  const [userBarest, setUserBarest] = useState(new UsersDAO());
  const db = useFirestore();
  const queryUsers = db.collection(Constants.USER_INFO);

  useImperativeHandle(ref, () => ({
    addOrUpdateUser(userBarestInfo) {
      setUserBarest(userBarestInfo);
      handleShowModal();
    },
  }));

  const handleHideModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  ;

  const handleChangeForm = (e) => {
    const { name, value, type } = e.target;
    setUserBarest((prevState) => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const updateUser = (e) => {
    e.preventDefault();
    setLoaded(true);
    const userUpdate = { ...userBarest };
    userUpdate.birth_date = firebase.firestore.Timestamp.fromDate(new Date(userUpdate.birth_date));
    const genderCurrent = {
      MALE: userUpdate.gender === Object.keys(Constants.GENDER)[0],
      FEMALE: userUpdate.gender === Object.keys(Constants.GENDER)[1]
    }
    delete userUpdate["NO_ID_FIELD"];
    delete userUpdate["id"];
    userUpdate.gender = genderCurrent;
    queryUsers.doc(userBarest.id).update(userUpdate).then(async () => {
      setLoaded(false);
      clearForm();
      handleHideModal();
      showAlert(`Usuario ${userBarest.email} actualizado correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al actualizar el usuario.`, "", Constants.TYPE_ALERT.error, "", error);
    });
  };

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  };

  const clearForm = () => {
    setUserBarest(new UsersDAO());
  };

  return (
    <section>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        as={Modal.Dialog}
        centered
        show={showModal}
      >
        <Modal.Header className="m-success">
          <Modal.Title className="h6 text-white">
            MODIFICAR USUARIO
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleHideModal}
          />
        </Modal.Header>
        <Modal.Body>
          <section className="d-flex align-items-center">
            <Container>
              <div className="bg-white w-100 fmxw-500 m-auto">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">Completa todos los campos</h5>
                </div>
              </div>
              <Form className="mt-4" onSubmit={updateUser}>
                <Row className="justify-content-center form-bg-image">
                  <Col md={12}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>{userBarest.email}</Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-center form-bg-image">
                  <Col md={6} sm={12}>
                    <Form.Group id="first_name" className="mb-4">
                      <Form.Label>Nombres*</Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={userBarest.first_name}
                          type="text"
                          name="first_name"
                          onChange={handleChangeForm}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group id="last_name" className="mb-4">
                      <Form.Label>Apellidos*</Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={userBarest.last_name}
                          type="text"
                          name="last_name"
                          onChange={handleChangeForm}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-center form-bg-image">
                  <Col md={6} sm={12}>
                    <Form.Group id="birth_date" className="mb-4">
                      <Form.Label>Fecha nacimiento*</Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={userBarest.birth_date}
                          type="date"
                          name="birth_date"
                          onChange={handleChangeForm}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group id="gender" className="mb-4">
                      <Form.Label>Género*</Form.Label>
                      <Form.Select id="gender" value={userBarest.gender} name="gender" onChange={handleChangeForm}>
                        <option value={Object.keys(Constants.GENDER)[0]}>{Constants.GENDER.MALE}</option>
                        <option value={Object.keys(Constants.GENDER)[1]}>{Constants.GENDER.FEMALE}</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-right mt-4">
                  <Button variant="primary" type="submit">
                    Actualizar
                  </Button>
                </div>
              </Form>
            </Container>
          </section>
        </Modal.Body>
      </Modal>
    </section>
  );
});