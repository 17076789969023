export class TemplateEmail {
    to;
    subject;
    templateName;
    parameters;
    constructor(
        _to,
        _subject,
        _templateName,
        _parameters
    ) {
        this.to = _to;
        this.subject = _subject;
        this.templateName = _templateName;
        this.parameters = _parameters;
    }
}