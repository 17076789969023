import { Constants } from "../utils/constants";

export const Routes = {
  // Logged Routes
  Branch: { path: "/establecimientos", allowedRoles: [Constants.BAREST, Constants.ADMIN] },
  Catalogue: { path: "catalogo/", allowedRoles: [Constants.BAREST, Constants.MANAGER, Constants.ADMIN] },
  Category: { path: "/catalogo/categorias", allowedRoles: [Constants.BAREST, Constants.MANAGER, Constants.ADMIN] },
  DashboardOverview: { path: "/dashboard/overview", allowedRoles: [Constants.BAREST, Constants.ADMIN, Constants.MANAGER] },
  Enterprise: { path: "/empresas", allowedRoles: [Constants.BAREST] },
  ManagerBranch: { path: "/mis-establecimientos", allowedRoles: [Constants.MANAGER] },
  Products: { path: "/catalogo/productos", allowedRoles: [Constants.BAREST, Constants.MANAGER, Constants.ADMIN] },
  Profile: { path: "/perfil", allowedRoles: [Constants.BAREST, Constants.ADMIN, Constants.MANAGER, Constants.STAFF, Constants.KITCHEN] },
  Prints: { path: "/cola-impresiones", allowedRoles: [Constants.BAREST, Constants.ADMIN, Constants.MANAGER] },
  Users: { path: "/usuarios", allowedRoles: [Constants.BAREST] },
  TablesBar: { path: "/mesas-bar", allowedRoles: [Constants.BAREST, Constants.ADMIN, Constants.MANAGER] },
  Staff: { path: "/staff", allowedRoles: [Constants.BAREST, Constants.ADMIN, Constants.MANAGER] },
  Kitchen: { path: "/usuarios-cocina", allowedRoles: [Constants.BAREST, Constants.ADMIN, Constants.MANAGER] },
  Orders: { path: "/pedidos", allowedRoles: [Constants.BAREST, Constants.ADMIN, Constants.MANAGER] },
  Configuration: { path: "/configuracion", allowedRoles: [Constants.BAREST, Constants.ADMIN, Constants.MANAGER] },
  Printers: { path: "/configuracion/impresoras", allowedRoles: [Constants.BAREST, Constants.ADMIN, Constants.MANAGER] },
  Reports: { path: "/informes/", allowedRoles: [Constants.BAREST, Constants.ADMIN, Constants.MANAGER] },
  ReportsZ: { path: "/informes/z", allowedRoles: [Constants.BAREST, Constants.ADMIN, Constants.MANAGER] },
  // Unlogged routes
  Signin: { path: "/" },
  Signup: { path: "/examples/sign-up" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  NotAuthorized: { path: "/examples/403" },
  ServerError: { path: "/examples/500" },
};
