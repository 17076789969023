import React, { useEffect, useState, useContext } from "react";
import ReactExport from "react-export-excel";
import 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Card, Breadcrumb, InputGroup, Table, Button } from '@themesberg/react-bootstrap';

// state
import { appContext } from "../../state/context";

// components
import { MenuItemTable } from '../../components/Tables';
import PaginationBarest from "../../components/Pagination";
import Preloader from "../../components/Preloader";
import { ViewDetailItemModel } from '../../components/models/ViewDetailItemModel';
import ExpandableCard from './ExpandableCard';

// utils
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import { toDateTime, formatDateString, formatDateTimeString, getPreviousDay, toDateInputValue } from "../../utils/utils"
import useAlert from "../../utils/hooks/useAlert";
import { TransformTemplateViewDetail, filterTypeBarOrTable, getRolesUser, currencyString, getDateToFirebase, formatValidateDate } from "../../utils/utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const titlesView = {
  number: "Mesa",
  type: "Tipo",
  max_capacity: "Capacidad",
}

let headersExcel = [
  { label: "Establecimiento", key: "branch" },
  { label: "Tipo", key: "type" },
  { label: "Nombre", key: "nameBarTable" },
  { label: "Fecha apertura de la mesa", key: "dateOpen" },
  { label: "# Pedido", key: "order" },
  { label: "Comentario", key: "comment" },
  { label: "Solicitado por el staff", key: "requestStaff" },
  { label: "Productos", key: "product" },
  { label: "Unidades", key: "quantity" },
  { label: "Precio", key: "price" },
  { label: "Precio total", key: "totalPrice" },
  { label: "Eliminado/Invitación", key: "statusRemove" },
  { label: "Pago", key: "paid" },
  { label: "Estado solicitud", key: "statusRequest" },
  { label: "Total factura(Bruto)", key: "totalAmountGross" },
  { label: "Total factura(Neto)", key: "totalAmountNet" },
  { label: "Total comisión Stripe", key: "totalAmountCommission" },
  { label: "Total propinas", key: "totalAmountTips" },
]

export default () => {
  const { user, currentEnterprise } = useContext(appContext);
  const db = useFirestore();
  const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid);
  const [loading, setLoading] = useState(false);
  const [dataOrders, setDataOrders] = useState(
    {
      totalRequest: 0,
      totalProducts: 0,
      totalTransaction: 0,
      totalAmountNet: 0,
      totalAmountGross: 0,
      totalAmountCommissions: 0,
      totalAmountTips: 0
    })

  // Pagination
  const [searchTableBar, setSearchTableBar] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(Number.MAX_VALUE);

  // filters
  const [timeStart, setTimeStart] = useState(toDateInputValue(getPreviousDay()));
  const [timeEnd, setTimeEnd] = useState(toDateInputValue(new Date()));
  const [branchSelected, setBranchSelected] = useState("");
  const [tableBarSelected, setTableBarSelected] = useState("all");

  const [branches, setBranches] = useState([]);
  const [tableBars, setTableBars] = useState([]);
  const [journals, setJournals] = useState([]);
  const [viewDetail, setViewDetail] = useState();
  const [dataExcel, setDataExcel] = useState([]);
  const { handleShowAlert, RenderAlert } = useAlert();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredJournals = journals.filter(item => item.tableInfo?.number.toLowerCase().includes(searchTableBar.toLowerCase())).slice(indexOfFirstItem, indexOfLastItem);

  const userRoles = user?.roles_array ? getRolesUser(user.roles_array) : [];
  const isBarestOrAdmin = userRoles.includes(Constants.BAREST) || userRoles.includes(Constants.ADMIN);

  const paginate = page => setCurrentPage(page);

  let id = 0;
  let dataOrdersTemp = {};

  useEffect(() => {
    const getBranchXEnterprise = () => {
      setLoading(true);
      const branchesQuerySearch = isBarestOrAdmin ? queryEnterprise.collection(Constants.BRANCHS) : queryEnterprise.collection(Constants.BRANCHS).where("manager_email", "==", user.email)
      branchesQuerySearch.get().then(querySnapshot => {
        const mappedBranches = [];
        querySnapshot.forEach(async (doc) => {
          mappedBranches.push({ ...doc.data(), id: doc.id })
        });
        setBranches(mappedBranches);
        setBranchSelected(querySnapshot.docs[0]?.id);
        if (querySnapshot.docs.length > 0) getTablesBar(querySnapshot.docs[0]?.id);
        setLoading(false);
      }).catch(error => {
        setLoading(false);
        showAlert(`Ocurrió un error al traer los establecimientos.`, "", Constants.TYPE_ALERT.error, "", error);
      });

    }

    getBranchXEnterprise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEnterprise]);

  useEffect(() => {
    if (branchSelected) getTablesBar(branchSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchSelected])

  const getOrderSearch = () => {
    if (timeEnd !== null && timeEnd !== "" &&
      timeStart !== null && timeStart !== "") {
      if (new Date(timeEnd) >= new Date(timeStart)) {
        if (tableBars.length) {
          if (tableBarSelected === "all")
            getJournals(tableBars, branchSelected);
          else {
            const tableBarSelect = [tableBars.find(item => item.id === tableBarSelected)];
            getJournals(tableBarSelect, branchSelected);
          }
        } else {
          setLoading(false);
        }
      } else {
        showAlert(`La fecha inicial no puede ser menor a la fecha final.`, "", Constants.TYPE_ALERT.error, "", "");
      }
    }
  }

  const generateDocumentExcel = (journals, branchSelect) => {
    let data = [];
    journals.forEach(journal => {
      let totalAmountNet = 0;
      let totalAmountGross = 0;
      let totalAmountCommissions = 0;
      let totalAmountTips = 0;
      journal.request.forEach((req, index) => {
        req.products.forEach(prod => {
          data.push(
            {
              branch: branches.find(br => br.id === branchSelect).name,
              type: journal.tableInfo ? filterTypeBarOrTable(journal.tableInfo?.type) : null,
              nameBarTable: journal.tableInfo?.number,
              dateOpen: formatDateTimeString(toDateTime(journal.open_date_time?.seconds)),
              order: `Pedido ${index + 1}`,
              comment: req.comment,
              requestStaff: req.is_staff_request ? "Si" : "No",
              product: prod.product_name,
              quantity: prod.product_quantity,
              price: prod.product_unit_price,
              totalPrice: prod.product_quantity * prod.product_unit_price,
              paid: prod.is_paid ? "Pagado" : "Pendiente de pago",
              statusRequest: Constants.STATUS_PRODUCT[prod.status] !== undefined ?
                Constants.STATUS_PRODUCT[prod.status]
                : "",
              statusRemove: Constants.STATUS_PRODUCT_REMOVE[prod.status] !== undefined ? Constants.STATUS_PRODUCT_REMOVE[prod.status] : ""
            }
          );
        })
      })

      journal.transaction.forEach((trx) => {
        let amountNetTotal = ((trx?.services_charges_value ?? 0) + (trx?.services_charges_stripe_value ?? 0));
        totalAmountNet += (trx.amount - amountNetTotal - (trx?.tip_value ?? 0));
        totalAmountGross += trx.amount;
        totalAmountCommissions += amountNetTotal;
        totalAmountTips += trx?.tip_value ?? 0;
      })
      if (data.length > 0) {
        data[data.length - 1]["totalAmountGross"] = currencyString(totalAmountGross);
        data[data.length - 1]["totalAmountNet"] = currencyString(totalAmountNet);
        data[data.length - 1]["totalAmountCommission"] = currencyString(totalAmountCommissions);
        data[data.length - 1]["totalAmountTips"] = currencyString(totalAmountTips);
      }
    })

    data.push({
      order: `Total pedidos: ${dataOrdersTemp.totalRequest}`,
      product: `Total productos: ${dataOrdersTemp.totalProducts}`,
      totalAmountGross: `Total(bruto) facturado: ${currencyString(dataOrdersTemp.totalAmountGross)}`,
      totalAmountNet: `Total(neto) facturado: ${currencyString(dataOrdersTemp.totalAmountNet)}`,
      totalAmountCommission: `Total comisiones: ${currencyString(dataOrdersTemp.totalAmountCommissions)}`,
      totalAmountTips: `Total propinas: ${currencyString(dataOrdersTemp.totalAmountTips)}`,
    })

    setDataExcel(data);
  }

  const getTablesBar = (branchSelected) => {
    setLoading(true);
    const tablesBarsQuery = queryEnterprise.collection(Constants.BRANCHS).doc(branchSelected).collection(Constants.TABLES_BAR);

    tablesBarsQuery.get()
      .then(querySnapshot => {
        const mappedTablesAndBars = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data?.status?.deleted === false || data?.status?.deleted === undefined) {
            mappedTablesAndBars.push({ ...data, id: doc.id })
          }
        });
        setTableBars(mappedTablesAndBars);
        getJournals(mappedTablesAndBars, branchSelected);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        showAlert(`Ocurrió un error al traer las mesas/barras.`, "", Constants.TYPE_ALERT.error, "", error);
      });
  }

  const getJournals = (mappedTablesAndBars, branchSelected) => {
    setLoading(true);

    const journalsQuery = db
      .collection(Constants.JOURNALS)
      .where("branch_id", "==", branchSelected)
      .where("open_date_time", ">=", getDateToFirebase(formatValidateDate(`${timeStart} 00:00:00`)))
      .where("open_date_time", "<=", getDateToFirebase(formatValidateDate(`${timeEnd} 23:59:00`)))
      .orderBy("open_date_time", "desc");

    const getTableInfo = journalTableBarId => {
      const tablesAndBarsId = mappedTablesAndBars.map(item => item?.id);
      const tableIndex = tablesAndBarsId.findIndex(id => journalTableBarId.includes(id));
      return tableIndex >= 0 ? mappedTablesAndBars[tableIndex] : null;
    }

    const getRequestProducts = async (journalId, requestId) => {
      const products = [];
      const productsQuery = await db.collection(Constants.JOURNALS).doc(journalId).collection(Constants.REQUESTS).doc(requestId).collection(Constants.PRODUCT_ORDER).get();
      productsQuery.forEach(doc => {
        products.push({ ...doc.data(), id: doc.id });
      });
      return products;
    }

    const getTransactions = async (journalId) => {
      const transactions = [];
      const transactionsQuery = await db.collection(Constants.JOURNALS)
        .doc(journalId)
        .collection(Constants.TRANSACTION)
        .where("status", "==", Constants.TYPE_STATUS_TRANSACTION.approved)
        .get();
      transactionsQuery.forEach(doc => {
        transactions.push({ ...doc.data(), id: doc.id });
      });
      return transactions;
    }

    const getPayments = async (journalId) => {
      const payments = [];
      const paymentsQuery = await db.collection(Constants.JOURNALS)
        .doc(journalId)
        .collection(Constants.PAYMENTS)
        .where("is_paid", "==", true)
        .get();
      paymentsQuery.forEach(doc => {
        payments.push({ ...doc.data(), id: doc.id });
      });
      return payments;
    }

    const getJournalRequests = async journalId => {
      const requestsItems = [];
      const requestsQuery = await db.collection(Constants.JOURNALS).doc(journalId).collection(Constants.REQUESTS).get();
      for (const doc of requestsQuery.docs) {
        requestsItems.push({
          ...doc.data(),
          id: doc.id,
          products: (await getRequestProducts(journalId, doc.id)).concat(await getProductsRemove(journalId, doc.id))
        });
      };
      return requestsItems;
    }

    const getProductsRemove = async (journalId, requestId) => {
      const productRemoveItems = [];
      const productRemoveQuery = await db.collection(Constants.JOURNALS)
        .doc(journalId)
        .collection(Constants.PRODUCT_REMOVE)
        .where("request_id", "==", requestId)
        .get();
      for (const doc of productRemoveQuery.docs) {
        productRemoveItems.push({
          ...doc.data(),
          id: doc.id
        });
      };
      return productRemoveItems;
    }

    journalsQuery.get()
      .then(querySnapshot => {
        let mappedJournals = [];
        querySnapshot.forEach(doc => {
          mappedJournals.push({ ...doc.data(), id: doc.id });
        });

        mappedJournals = mappedJournals.map(async journal => {
          return {
            ...journal,
            refPath: journal.tableBarId?.path,
            tableInfo: getTableInfo(journal.tableBarId?.path),
            request: await getJournalRequests(journal.id),
            transaction: await getTransactions(journal.id),
            payments: await getPayments(journal.id)
          };
        })

        Promise.all(mappedJournals).then(value => {
          setJournals(value);
          getDataOrders(value);
          generateDocumentExcel(value, branchSelected);
          setLoading(false);
        })
      })
      .catch(error => {
        setLoading(false);
        showAlert(`Ocurrió un error al traer las mesas/barras.`, "", Constants.TYPE_ALERT.error, "", error);
      });
  }

  const getDataOrders = (journals) => {
    let totalRequests = 0;
    let totalProducts = 0;
    let totalTransaction = 0;
    let totalAmountNet = 0;
    let totalAmountGross = 0;
    let totalAmountCommissions = 0;
    let totalAmountTips = 0;

    journals.forEach(journal => {
      journal.request.forEach(request => {
        totalRequests++;
        request.products.forEach(prod => {
          if (Constants.STATUS_PRODUCT_REMOVE[prod.status] === undefined) {
            totalProducts++;
          }
        })
      })
      journal.transaction.forEach(trx => {
        let amountNetTotal = ((trx?.services_charges_value ?? 0) + (trx?.services_charges_stripe_value ?? 0));
        totalTransaction++;
        totalAmountNet += (trx.amount + (trx?.tip_value ?? 0) - amountNetTotal);
        totalAmountGross += trx.amount + (trx?.tip_value ?? 0);
        totalAmountCommissions += amountNetTotal;
        totalAmountTips += trx?.tip_value ?? 0;
      })
    })

    let dataOrdersItems = {
      totalRequest: totalRequests,
      totalProducts: totalProducts,
      totalTransaction: totalTransaction,
      totalAmountNet: totalAmountNet,
      totalAmountGross: totalAmountGross,
      totalAmountCommissions: totalAmountCommissions,
      totalAmountTips: totalAmountTips
    }

    setDataOrders(dataOrdersItems);
    dataOrdersTemp = dataOrdersItems;
  }

  const changeTimeStart = evt => setTimeStart(evt.target.value);
  const changeTimeEnd = evt => setTimeEnd(evt.target.value);
  const changeBranch = (evt) => setBranchSelected(evt.target.value.trim());
  const changeTableBar = (evt) => setTableBarSelected(evt.target.value.trim());

  const viewTablesAndBars = journalItem => {
    let journalItemView = { ...journalItem };
    journalItemView = JSON.parse(JSON.stringify(journalItemView));
    journalItemView.tableInfo.status = journalItemView.tableInfo.status?.active ? "Si" : "No";
    journalItemView.tableInfo.type = filterTypeBarOrTable(journalItemView.tableInfo.type);
    journalItemView.tableInfo.max_capacity = journalItemView.tableInfo.max_capacity + " Personas";
    let viewDetail = TransformTemplateViewDetail(titlesView, journalItemView.tableInfo);
    setViewDetail(new ViewDetailItemModel(
      `Orden en: ${journalItemView.tableInfo.type} ${journalItemView.tableInfo.number}`,
      `Fecha apertura: ${formatDateString(toDateTime(journalItemView.open_date_time.seconds))}`,
      viewDetail
    ));
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const handleSearch = (event) => {
    setSearchTableBar(event.target.value);
  }

  const getTotalPrice = (products) => products.reduce((a, product) => { return Constants.STATUS_PRODUCT_REMOVE[product.status] === undefined ? a + product.product_unit_price * product.product_quantity : a }, 0)


  const JournalSummary = journalItem => {
    return (
      <Row className="justify-content-between align-items-center">
        <Col xs={12}>
          <h6 className="font-weight-bold">Pedidos</h6>
        </Col>
        <Col xs={12}>
          {journalItem.request.length > 0 ? (
            <div className="request-list" style={{ maxHeight: 500, overflowY: 'scroll', padding: 10 }}>
              {journalItem.request.map((requestItem, index) => (
                <ExpandableCard key={requestItem.id} item={requestItem} index={index} />
              ))}
            </div>
          ) : (
            <span style={{ fontWeight: 100 }}>No hay pedidos por mostrar</span>
          )}
        </Col>
      </Row>
    );
  }

  const checkIsPaid = (products) => {
    const isAllPaid = products.every(elem => elem.is_paid === true);
    const isNotAllPaid = products.every(elem => elem.is_paid === false);
    if (isAllPaid) return "Pagado"
    else if (isNotAllPaid) return "Pendiente de pago"
    else return "Pago parcial"
  }

  return (
    <>
      <Preloader show={loading ? true : false} />
      <RenderAlert></RenderAlert>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Pedidos</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Pedidos</h4>
          <p className="mb-0">Resumen pedidos</p>
        </div>
      </div>

      <div className="table-settings mb-1">
        <Row className="align-items-center">
          <Col xs={12} md={6} lg={3} xl={4}>
            <Form.Group className="mb-2">
              <Form.Label>Buscar establecimiento:</Form.Label>
              <Form.Select id="TimeInterval" onChange={changeBranch}>
                {
                  branches.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
                }
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={6} lg={3} xl={4}>
            <Form.Group className="mb-2">
              <Form.Label>Mesa o barra:</Form.Label>
              <Form.Select id="table-bar-search" value={tableBarSelected} onChange={changeTableBar}>
                <option value="all">Todos</option>
                {
                  tableBars.map((item, i) => <option key={i} value={item.id}>{item.number}</option>)
                }
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} lg={3} xl={4}>
            <Form.Group className="mb-2">
              <Form.Label>Fecha inicio:</Form.Label>
              <InputGroup>
                <Form.Control type="date" id="timeStart" name="timeStart" value={timeStart} onChange={changeTimeStart}></Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={4} lg={3} xl={4}>
            <Form.Group className="mb-2">
              <Form.Label>Fecha fin:</Form.Label>
              <InputGroup>
                <Form.Control type="date" id="timeEnd" name="timeEnd" value={timeEnd} onChange={changeTimeEnd}></Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={4} lg={3} xl={4}>
            <Form.Label></Form.Label><br></br>
            <Button variant="primary" className="px-4" onClick={getOrderSearch}>Buscar/Refrescar</Button>
          </Col>
        </Row>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Buscar" value={searchTableBar} onChange={handleSearch} />
            </InputGroup>
          </Col>
          <Col xs={12} md="auto">
            <ExcelFile
              filename={`Pedidos-${dataExcel.length ? dataExcel[0].branch : ""}`}
              element={<Button variant="primary" className="px-4">Exportar informe</Button>}>
              <ExcelSheet data={dataExcel} name="Pedido">
                {
                  headersExcel.map(item => <ExcelColumn key={item.key} label={item.label} value={item.key} />)
                }
              </ExcelSheet>
            </ExcelFile>
          </Col>
        </Row>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          {journals.length > 0 ? (
            <>
              <Table hover className="user-table align-items-center">
                <thead>
                  <tr>
                    <th className="border-bottom">#</th>
                    <th className="border-bottom">Fecha de apertura</th>
                    <th className="border-bottom"># Mesa o barra</th>
                    <th className="border-bottom">Productos</th>
                    <th className="border-bottom">Precio</th>
                    <th className="border-bottom">Unidades</th>
                    <th className="border-bottom">Total precio</th>
                    <th className="border-bottom">Estado del producto</th>
                    <th className="border-bottom">Estado de pago</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredJournals.map((journal) => {
                      return journal.request.map((request) => {
                        id += 1;
                        return <tr key={journal.id + "-" + id}>
                          <td>{(((id - 1) + 1) + (currentPage - 1) * itemsPerPage)}</td>
                          <td>{formatDateTimeString(toDateTime(journal.open_date_time.seconds))}</td>
                          <td>{journal.tableInfo.number}</td>
                          <td>
                            {
                              request.products.map((p, j) =>
                                Constants.STATUS_PRODUCT_REMOVE[p.status] === undefined ?
                                  <div key={"prod-" + j}>{p.product_name}</div> : null
                              )
                            }
                          </td>
                          <td>
                            {
                              request.products.map((p, j) =>
                                Constants.STATUS_PRODUCT_REMOVE[p.status] === undefined ?
                                  <div key={"up-" + j}>{currencyString(p.product_unit_price)}</div> : null
                              )
                            }
                          </td>
                          <td>
                            {
                              request.products.map((p, j) =>
                                Constants.STATUS_PRODUCT_REMOVE[p.status] === undefined ?
                                  <div key={"qt-" + j}>x{p.product_quantity}</div> : null
                              )
                            }
                          </td>
                          <td>
                            {currencyString(getTotalPrice(request.products))}
                          </td>
                          <td>
                            {
                              Constants.STATUS_PRODUCT[request.products[0]?.status] !== undefined ?
                                Constants.STATUS_PRODUCT[request.products[0]?.status]
                                : ""
                            }
                          </td>
                          <td>
                            {
                              checkIsPaid(request.products)
                            }
                          </td>
                          <MenuItemTable
                            isEditVisible={false}
                            isRemoveVisible={false}
                            viewItem={viewTablesAndBars}
                            itemSelected={journal}
                            contentViewDetail={viewDetail}
                            customDetailBody={JournalSummary(journal)}
                          ></MenuItemTable>
                        </tr>
                      })
                    })
                  }
                </tbody>
              </Table>

              {
                filteredJournals.length > 0 ?
                  <Card bg="primary" className="px-4 py-4 mt-4 text-white">
                    <Row>
                      <Col>Total Mesas abiertas: {filteredJournals.filter((item,
                        index) => filteredJournals.indexOf(item) === index).length}</Col>
                      <Col>Total pedidos: {dataOrders.totalRequest}</Col>
                      <Col>Total productos: {dataOrders.totalProducts}</Col>
                      <Col>Total transacciones: {dataOrders.totalTransaction}</Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>Total(bruto) facturado: {currencyString(dataOrders.totalAmountGross)}</Col>
                      <Col>Total comisiones: {currencyString(dataOrders.totalAmountCommissions)}</Col>
                      <Col>Total(neto) facturado: {currencyString(dataOrders.totalAmountNet)}</Col>
                      <Col>Total propinas: {currencyString(dataOrders.totalAmountTips)}</Col>
                    </Row>
                  </Card>
                  : null
              }
              <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                <PaginationBarest
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  totalItems={journals.length}
                  paginate={paginate}
                />
              </Card.Footer>
            </>
          ) : <p className="mt-3 mb-0">No hay datos para mostrar</p>}
        </Card.Body>
      </Card>
    </>
  );
};
