import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import 'firebase/firestore';
import { Col, Row, Form, Card, Button, Breadcrumb, InputGroup, Table } from '@themesberg/react-bootstrap';
import { useFirestore } from 'reactfire';
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import { EnterpriseAddUpdate } from './EnterpriseAddUpdate';
import { MenuItemTable } from '../../components/Tables';
import PaginationBarest from "../../components/Pagination";
import Preloader from "../../components/Preloader";
import useAlert from "../../utils/hooks/useAlert";
import { TransformTemplateViewDetail } from "../../utils/utils";
import { ViewDetailItemModel } from '../../components/models/ViewDetailItemModel';

export default () => {
  const [loaded, setLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(Constants.ITEMS_PER_PAGE);
  const [enterprises, setEnterprises] = useState([]);
  const [searchEnterprise, setSearchEnterprise] = useState('');
  const { handleShowAlert, RenderAlert } = useAlert();
  const [viewDetail, setViewDetail] = useState();
  const db = useFirestore();
  const childAddEnterprisePassRef = useRef();
  const queryEnterprise = db.collection(Constants.ENTERPRISE);

  const titlesView = {
    enterprise_id: "CIF Empresa",
    name: "Nombre de la empresa",
    address: "Dirección",
    is_active: "¿La empresa se encuentra activa?",
    contact_name: "Nombre de contacto",
    contact_email: "Email de contacto",
    contact_mobile_number: "Número telefónico de contacto",
  }

  useEffect(() => {
    console.log(childAddEnterprisePassRef.current);
  }, [childAddEnterprisePassRef]);

  useEffect(() => {
    getEnterprises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filterEnterprise = enterprises.filter(item => item.name.toLowerCase().includes(searchEnterprise.toLowerCase())).slice(indexOfFirstItem, indexOfLastItem);

  const paginate = page => setCurrentPage(page);

  const getEnterprises = () => {
    setEnterprises([]);
    setLoaded(true);
    queryEnterprise.get()
      .then(querySnapshot => {
        setLoaded(false);
        querySnapshot.forEach((doc) => {
          setEnterprises(prev =>
            [...prev, { ...doc.data(), id: doc.id }]
          )
        })
      })
      .catch(error => {
        setLoaded(false);
        showAlert(`Ocurrió un error al traer la información de las empresas.`, "", Constants.TYPE_ALERT.error, "", error);
      });
  }

  const viewEnterprise = async (item) => {
    item = JSON.parse(JSON.stringify(item));
    item.is_active = item.is_active ? "Habilitada" : "Inhabilitada";
    item.contact_info.contact_mobile_number = `+${item.contact_info.contact_country_call_code} ${item.contact_info.contact_mobile_number}`;
    item = { ...item, ...item.contact_info }
    let viewDetailtEnterprise = TransformTemplateViewDetail(titlesView, item);
    setViewDetail(new ViewDetailItemModel(
      `Empresa ${item.name}`,
      `Datos de la empresa ${item.name}`,
      viewDetailtEnterprise
    ));
  }

  const removeEnterprise = (item) => {
    setLoaded(true);
    queryEnterprise.doc(item.id).update({
      is_active: false
    })
      .then(() => {
        setLoaded(false);
        getEnterprises();
        showAlert(`Empresa ${item.name} inhabilitada`, "", Constants.TYPE_ALERT.success, "");
      })
      .catch((error) => {
        setLoaded(false);
        showAlert(`Ocurrió un error al inhabilitada la empresa '${item.name}'. `, "", Constants.TYPE_ALERT.error, "", error);
      });
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const openAddUpdateEnterprise = (isUpdate, branch) => {
    childAddEnterprisePassRef.current.addOrUpdateEnterprise(isUpdate, branch);
  }

  const editEnterprise = async (item) => {
    item = { ...item };
    openAddUpdateEnterprise(true, item);
  }

  const handleSearchEnterprise = (event) => {
    setSearchEnterprise(event.target.value);
  }

  return (
    <>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <EnterpriseAddUpdate ref={childAddEnterprisePassRef} getEnterprises={getEnterprises} />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Empresas</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Empresas</h4>
          <p className="mb-0">Empresas registradas</p>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" value={searchEnterprise} onChange={handleSearchEnterprise} />
            </InputGroup>
          </Col>
          <Col className="text-right">
            <Button variant="primary" type="button" onClick={() => openAddUpdateEnterprise(false)}>Agregar nuevo</Button>
          </Col>
        </Row>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">CIF empresa</th>
                <th className="border-bottom">Nombre empresa</th>
                <th className="border-bottom">Dirección</th>
                <th className="border-bottom">Activa</th>
              </tr>
            </thead>
            <tbody>
              {
                filterEnterprise.map((res, index) => {
                  return <tr key={index}>
                    <td>{((index + 1) + (currentPage - 1) * itemsPerPage)}</td>
                    <td>{res.enterprise_id}</td>
                    <td>{res.name}</td>
                    <td>{res.address}</td>
                    <td>{res.is_active ? "Habilitada" : "Inhabilitada"}</td>
                    <MenuItemTable
                      editItem={editEnterprise}
                      removeItem={removeEnterprise}
                      viewItem={viewEnterprise}
                      itemSelected={res}
                      contentViewDetail={viewDetail}
                    ></MenuItemTable>
                  </tr>
                })
              }
            </tbody>
          </Table>

          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <PaginationBarest
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={enterprises.length}
              paginate={paginate}
            />
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};
