import React, { useState, Fragment } from "react";
import Alerts from "../../components/Alerts/Alerts";

const useAlert = () => {
  const [hiddenAlerts, setHiddenAlerts] = useState([]);

  const handleShowAlert = (alertId) => {
    const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
    setHiddenAlerts(hiddenAlertsUpdated);
  };

  const handleCloseAlert = (alertId) => {
    setHiddenAlerts(hiddenAlerts.filter((_, index) => index !== alertId));
  };

  const RenderAlert = () => (
    <Fragment>
      {hiddenAlerts.map((res, index) => {
        return (
          <Alerts
            key={index}
            content={res}
            i={index}
            closeAlert={handleCloseAlert}
          ></Alerts>
        );
      })}
    </Fragment>
  );

  return {
    handleShowAlert,
    handleCloseAlert,
    RenderAlert,
  };
};

export default useAlert;
