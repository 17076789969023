import { Constants } from "../../../utils/constants";

export const tableConfig = [
  {
    label: 'Fecha y hora de creación',
    key: 'create_date'
  },
  {
    label: 'Fecha inicial reporte',
    key: 'start_date_report'
  },
  {
    label: 'Fecha final reporte',
    key: 'end_date_report'
  },
  {
    label: 'Descargar .excel',
    key: 'file_excel_path',
    type: Constants.TYPE_COLUMN_DATA_TABLE.file,
    ext: Constants.TYPE_FILE_EXTENSION.xls
  },
  {
    label: 'Descargar .PDF',
    key: 'file_pdf_path',
    type: Constants.TYPE_COLUMN_DATA_TABLE.file,
    ext: Constants.TYPE_FILE_EXTENSION.pdf
  },
]

