import { Constants } from "../../../utils/constants";

export default class Branch {
  id;
  name;
  address;
  country;
  city;
  phone_number;
  longitud_georeference;
  latitud_georeference;
  manager_email;
  currency;
  status;
  tipping_info;
  payments_accounts;
  percentage_service;
  percentage_service_barest;
  fixed_value_service;
  id_tablet;
  opening_time;
  closing_time;

  constructor(
    _name,
    _address,
    _country,
    _city,
    _phoneNumber,
    _latitud_georeference,
    _longitud_georeference,
    _managerEmail,
    _currency,
    _tipping_info,
    _payments_accounts,
    _percentage_service,
    _fixed_value_service,
    _percentage_service_barest,
    _id_tablet,
    _opening_time,
    _closing_time,
  ) {
    this.name = _name ?? "";
    this.address = _address ?? "";
    this.country = _country ?? "España";
    this.city = _city ?? "";
    this.phone_number = _phoneNumber ?? "";
    this.latitud_georeference = _latitud_georeference ?? 0;
    this.longitud_georeference = _longitud_georeference ?? 0;
    this.manager_email = _managerEmail ?? "";
    this.currency = _currency ?? "";
    this.status = true;
    this.tipping_info = _tipping_info;
    this.payments_accounts = new PaymentsAccounts();
    this.fixed_value_service = _fixed_value_service ?? Constants.FIXED_VALUE_SERVICES;
    this.percentage_service = _percentage_service ?? Constants.PERCENTAGE_SERVICES_STRIPE;
    this.percentage_service_barest = _percentage_service_barest ?? Constants.PERCENTAGE_SERVICES_BAREST;
    this.id_tablet = _id_tablet ?? "";
    this.closing_time = _closing_time ?? "";
    this.opening_time = _opening_time ?? "";
  }
}

export class PaymentsAccounts {
  stripe;
}