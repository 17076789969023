export default class ContactInfo {
    contact_name;
    contact_email;
    contact_country_call_code;
    contact_mobile_number;

    constructor(
        _contact_name,
        _contact_email,
        _contact_country_call_code,
        _contact_mobile_number
    ) {
        this.contact_name = _contact_name ?? "";
        this.contact_email = _contact_email ?? "";
        this.contact_country_call_code = _contact_country_call_code ?? "+34";
        this.contact_mobile_number = _contact_mobile_number ?? 0;
    }
}