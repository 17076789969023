import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import { PDFDownloadLink } from '@react-pdf/renderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faQrcode } from '@fortawesome/free-solid-svg-icons';
import 'firebase/firestore';
import { Col, Row, Form, Card, Breadcrumb, InputGroup, Table, Button, Modal } from '@themesberg/react-bootstrap';
import { useFirestore } from 'reactfire';
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import { tablesBars } from "../../utils/models/Lists";
import { TableAndBarAddUpdate } from './TableAndBarAddUpdate';
import { MenuItemTable } from '../../components/Tables';
import PaginationBarest from "../../components/Pagination";
import Preloader from "../../components/Preloader";
import useAlert from "../../utils/hooks/useAlert";
import { QRCodeTablesBar } from '../../components/QRComponent';
import QRCode from 'qrcode.react';
import { TransformTemplateViewDetail, filterTypeBarOrTable, getRolesUser } from "../../utils/utils";
import { ViewDetailItemModel } from '../../components/models/ViewDetailItemModel';
import { appContext } from "../../state/context";

export default () => {
  const { user, currentEnterprise } = useContext(appContext)
  const [loaded, setLoaded] = useState(false);
  const [searchTableBar, setSearchTableBar] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(Constants.ITEMS_PER_PAGE);
  const [isDownload, setIsDownload] = useState(false);
  const [QRImage, setQRImage] = useState([]);
  const [idTableBarQR, setIdTableBarQR] = useState([]);
  const [typeBarTable, setTypeBarTable] = useState(tablesBars[0].value);
  const [branchSelected, setBranchSelected] = useState("");
  const { handleShowAlert, RenderAlert } = useAlert();
  const [branch, setBranchs] = useState([]);
  const [tableBar, setTableBar] = useState([]);
  const [viewDetail, setViewDetail] = useState();
  const childAddTablesBarPassRef = useRef();
  const db = useFirestore();
  const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid);

  const userRoles = user?.roles_array ? getRolesUser(user.roles_array) : [];
  const isBarest = userRoles.includes(Constants.BAREST) || userRoles.includes(Constants.ADMIN);


  const qrCodeCanvas = document.getElementsByClassName("QR-print-table-bar");

  useEffect(() => {
    if (qrCodeCanvas.length) {
      [...qrCodeCanvas].forEach(res => {
        const qrCodeDataUri = res.toDataURL('image/jpg', 0.5);
        setQRImage(prev => [...prev, { image: qrCodeDataUri, name: res.id, type: res.getAttribute("data-type") }]);
      });
    }
  }, [idTableBarQR, qrCodeCanvas]);

  const branchesQuerySearch = useMemo(() => {
    return isBarest ? queryEnterprise.collection(Constants.BRANCHS) : queryEnterprise.collection(Constants.BRANCHS).where("manager_email", "==", user.email)
  }, [isBarest, user, queryEnterprise]);

  const titlesView = {
    number: "Nombre o número",
    type: "Tipo",
    max_capacity: "Capacidad de personas",
    status: "Habilitada"
  }

  useEffect(() => {
    console.log(childAddTablesBarPassRef.current);
  }, [childAddTablesBarPassRef]);

  useEffect(() => {
    getBranchXEnterprise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEnterprise]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filterTableBar = tableBar.filter(item => item?.number.toLowerCase().includes(searchTableBar.toLowerCase())).slice(indexOfFirstItem, indexOfLastItem);

  const paginate = page => setCurrentPage(page);

  const handleHideModalQRPDF = () => setIsDownload(false);
  const handleShowModalQRPDF = () => setIsDownload(true);

  const urlQREncodeTableAndBar = urlQR => {
    const firstUrl = "https://barest.page.link/?link=https://thebarestapp.com/?mode%3DopenTableBar%26openInApp%3D1%26tableBar%3D%2F";
    const QRTableBarEncode = urlQR.replaceAll("/", "%2F");
    const lastUrl = "&apn=com.netto.barest&isi=1572761869&ibi=com.netto.barest&efr=1";
    return `${firstUrl}${QRTableBarEncode}${lastUrl}`
  }

  const getBranchXEnterprise = async () => {
    setBranchs([]);
    setLoaded(true);
    let branchSelect;
    await branchesQuerySearch.get()
      .then(querySnapshot => {
        setLoaded(false);
        querySnapshot.forEach(async (doc) => {
          setBranchs(prev =>
            [...prev, { ...doc.data(), id: doc.id }]
          )
        })
        branchSelect = querySnapshot.docs.length > 0 ? querySnapshot.docs[0]?.id : "0";
        setBranchSelected(branchSelect);
      })
      .catch(error => {
        setLoaded(false);
        showAlert(`Ocurrió un error al traer los establecimientos.`, "", Constants.TYPE_ALERT.error, "", error);
      });

    getTablesBar(branchSelect, "ALL");
  }

  const getTablesBar = (branchSelect, typeBarOrTable) => {
    const query = queryEnterprise.collection(Constants.BRANCHS).doc(branchSelect).collection(Constants.TABLES_BAR);
    setTableBar([]);
    setLoaded(true);
    let queryTables =
      (typeBarOrTable === Constants.TYPE_TABLES_BAR.TABLE) ?
        query.where(`type.${Constants.TYPE_TABLES_BAR.TABLE}`, "==", true) :
        typeBarOrTable === Constants.TYPE_TABLES_BAR.BAR ?
          query.where(`type.${Constants.TYPE_TABLES_BAR.BAR}`, "==", true) :
          query;
    queryTables.get()
      .then(querySnapshot => {
        setLoaded(false);
        querySnapshot.forEach(async (doc) => {
          const data = doc.data();
          if (data?.status?.deleted === false || data?.status?.deleted === undefined) {
            setTableBar(prev =>
              [...prev, { ...data, id: doc.id }]
            )
          }
        })
      })
      .catch(error => {
        setLoaded(false);
        showAlert(`Ocurrió un error al traer las mesas/barras.`, "", Constants.TYPE_ALERT.error, "", error);
      });
  }

  const changeTypeTableBar = (evt) => {
    setTypeBarTable(evt.target.value.trim());
    getTablesBar(branchSelected, evt.target.value.trim());
  }

  const changeBranch = (evt) => {
    setBranchSelected(evt.target.value.trim());
    getTablesBar(evt.target.value.trim(), typeBarTable);
  }

  const viewTablesAndBars = (items) => {
    const item = JSON.parse(JSON.stringify(items));
    item.status = item.status?.active ? "Si" : "No";
    item.type = filterTypeBarOrTable(item.type);
    item.max_capacity = item.max_capacity + " Personas";
    let viewDetail = TransformTemplateViewDetail(titlesView, item);
    setViewDetail(new ViewDetailItemModel(
      `${filterTypeBarOrTable(items.type)} ${item.number}`,
      `Datos  ${filterTypeBarOrTable(items.type)} ${item.number}`,
      viewDetail
    ));
  }

  const openAddUpdateTableBar = (isUpdate, tableBar) => {
    if (branchSelected !== null && branchSelected !== "" && branchSelected !== "0") {
      childAddTablesBarPassRef.current.addOrUpdateTableBar(isUpdate, tableBar);
    }else{
      showAlert(`Debes primero crear un establecimiento para crear mesas o barras`, "", Constants.TYPE_ALERT.error, "", "");
    }
  }

  const removeTableBars = (item) => {
    setLoaded(true);
    const query = queryEnterprise.collection(Constants.BRANCHS).doc(branchSelected).collection(Constants.TABLES_BAR);
    query.doc(item.id).update({
      status: {
        [Constants.STATUS_TABLES_BAR.ACTIVE]: false,
        [Constants.STATUS_TABLES_BAR.INACTIVE]: false,
        [Constants.STATUS_TABLES_BAR.DELETED]: true
      }
    }).then(() => {
      setLoaded(false);
      setTableBar(tableBar.filter(res => res.id !== item.id));
      showAlert(`${filterTypeBarOrTable(item.type)} ${item.number} eliminado correctamente`, "", Constants.TYPE_ALERT.success, "");
    })
      .catch((error) => {
        setLoaded(false);
        showAlert(`Ocurrió un error al eliminar ${filterTypeBarOrTable(item.type)} ${item.number}. `, "", Constants.TYPE_ALERT.error, "", error);
      });
  }

  const editTablesBars = (item) => {
    openAddUpdateTableBar(true, item);
  }

  const generateQR = (item) => {
    setIdTableBarQR([]);
    setQRImage([]);
    handleShowModalQRPDF();
    setIdTableBarQR(prev =>
      [...prev, urlQREncodeTableAndBar(`${Constants.ENTERPRISE}/${currentEnterprise.uid}/${Constants.BRANCHS}/${branchSelected}/${Constants.TABLES_BAR}/${item.id}`)]
    );
    setIsDownload(true);
  }

  const generateAllQR = () => {
    setIdTableBarQR([]);
    setQRImage([]);
    handleShowModalQRPDF();
    tableBar.forEach(res => {
      setIdTableBarQR(prev =>
        [...prev, urlQREncodeTableAndBar(`${Constants.ENTERPRISE}/${currentEnterprise.uid}/${Constants.BRANCHS}/${branchSelected}/${Constants.TABLES_BAR}/${res.id}`)]
      );
    });

    setIsDownload(true);
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const handleSearch = (event) => {
    setSearchTableBar(event.target.value);
  }

  const getTableBarName = (idTableBar) => {
    const id = idTableBar.split("/");
    const findItem = tableBar.find(res => res.id === id[id.length - 1]);
    return findItem ? findItem : { number: "", type: { TABLE: false, BAR: false } };
  }

  return (
    <>
      <div className="d-none position-absolute">
        {
          idTableBarQR.map((res, index) => <QRCode className="QR-print-table-bar" data-type={getTableBarName(res).type.BAR === true ? "Barra" : "Mesa"} id={getTableBarName(res).number} key={index} value={res} />)
        }
      </div>
      {
        isDownload ? <Modal size="lg"
          backdrop="static"
          keyboard={false}
          as={Modal.Dialog} centered show={isDownload}>
          <Modal.Header className="m-success">
            <Modal.Title className="h6 text-white">Descargar QR</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleHideModalQRPDF} />
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="mb-3">
              <QRCode value={idTableBarQR[0]} />
            </div>
            <PDFDownloadLink document={<QRCodeTablesBar qrTablesBars={QRImage} />} fileName={`QR-${idTableBarQR[0]}.pdf`}>
              {({ blob, url, loading, error }) => (loading ? 'Cargando PDF...' : 'Descargar PDF')}
            </PDFDownloadLink>
          </Modal.Body>
        </Modal> : ""
      }
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <TableAndBarAddUpdate
        ref={childAddTablesBarPassRef}
        getTablesBar={getTablesBar}
        selectBarOrTable={typeBarTable}
        selectBranch={branchSelected}
      />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Mesas y barras</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Mesas y barras</h4>
          <p className="mb-0">Mesas y barras registrados</p>
        </div>
      </div>

      <div className="table-settings mb-1">
        <Row className="align-items-center">
          <Col xs={12} md={6} lg={3} xl={4}>
            <Form.Group className="mb-2">
              <Form.Label>Buscar tipo:</Form.Label>
              <Form.Select id="typeTableBar" value={typeBarTable} onChange={changeTypeTableBar}>
                {
                  tablesBars.map((item, i) => <option key={i} value={item.value}>{item.name}</option>)
                }
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={6} lg={3} xl={4}>
            <Form.Group className="mb-2">
              <Form.Label>Buscar establecimiento:</Form.Label>
              <Form.Select id="typeTableBar" onChange={changeBranch}>
                {
                  branch.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
                }
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Buscar" value={searchTableBar} onChange={handleSearch} />
            </InputGroup>
          </Col>
          <Col className="text-right">
            <Button variant="secondary" className="mx-2" type="button" onClick={() => generateAllQR()}>
              <span className="c-pointer">
                <FontAwesomeIcon icon={faQrcode} /> Generar QR
              </span>
            </Button>
            <Button variant="primary" type="button" onClick={() => openAddUpdateTableBar(false)}>Agregar nuevo</Button>
          </Col>
        </Row>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">Número de mesa o barra</th>
                <th className="border-bottom">Capacidad máxima</th>
                <th className="border-bottom">Estado</th>
                <th className="border-bottom">Tipo</th>
              </tr>
            </thead>
            <tbody>
              {
                filterTableBar.map((res, index) => {
                  return <tr key={index}>
                    <td>{((index + 1) + (currentPage - 1) * itemsPerPage)}</td>
                    <td>{res.number}<small className="text-gray opacity-50">({res.id})</small></td>
                    <td>{res.max_capacity} Personas</td>
                    <td>{res.status?.active ? "Habilitada" : "Inhabilitada"}</td>
                    <td>{filterTypeBarOrTable(res.type)}</td>
                    <td>
                      <span className="c-pointer" onClick={() => generateQR(res)}>
                        <FontAwesomeIcon icon={faQrcode} /> QR
                      </span>
                    </td>
                    <MenuItemTable
                      editItem={editTablesBars}
                      removeItem={removeTableBars}
                      viewItem={viewTablesAndBars}
                      itemSelected={res}
                      contentViewDetail={viewDetail}
                    ></MenuItemTable>
                  </tr>
                })
              }
            </tbody>
          </Table>

          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <PaginationBarest
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={tableBar.length}
              paginate={paginate}
            />
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};
