export const tableConfig = [
  {
    label: 'Fecha creación',
    key: 'create_date'
  },
  {
    label: 'Id Journal',
    key: 'journal_id'
  },
]

