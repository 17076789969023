export class ViewDetailItemModel {
  titleHeader;
  titleBody;
  contentBody;

  constructor(
    _titleHeader,
    _titleBody,
    _contentBody,
  ) {
    this.titleHeader = _titleHeader;
    this.titleBody = _titleBody;
    this.contentBody = _contentBody;
  }

}