
import React, { useState, useRef, useContext } from "react";
import 'firebase/auth';
import { useFirebaseApp, useFirestore } from 'reactfire';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { appContext } from "../../state/context";
import { Col, Row, Form, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { ForgotPassword } from '../ForgotPassword';
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import { getDefaultRoute, getRolesUser } from "../../utils/utils";
import useModal from "../../utils/hooks/useModal";

export default (props) => {
  const { handleShow, RenderModal } = useModal();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [modal, setModal] = useState({});
  const [loading, setLoading] = useState(false);
  const firebase = useFirebaseApp();
  const childRecoverPassRef = useRef();
  const { setUser, setEnterprises, setCurrentEnterprise } = useContext(appContext);
  const db = useFirestore();

  const login = e => {
    e.preventDefault();
    setLoading(true);

    if (email.trim() === "" || email === undefined || password.trim() === "" || password === undefined)
      return;

    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(async user => {
        let res = await db.collection(Constants.OTHER_USERS_INFO).doc(user.user.uid).get();
        const userInfo = res.data();
        setUser({ ...userInfo, uid: res.id, email: user.user.email })

        const enterprisesInfo = []
        const role = userInfo.roles_array[0];
        const currentRoleInfo = role['role']

        const currentRole = Object.keys(currentRoleInfo).filter(rol => currentRoleInfo[rol])

        // Getting the enterprise info
        role.entity_uuid.get().then(enterprise => {
          enterprisesInfo.push({ ...enterprise.data(), uid: enterprise.id });
          setEnterprises(enterprisesInfo);
          setCurrentEnterprise({ ...enterprisesInfo[0], currentRole, index: 0 });
          props.history.push(getDefaultRoute(userInfo.first_login, currentRole[0]));
        });

        const userRoles = userInfo?.roles_array ? getRolesUser(userInfo.roles_array) : [];
        
        // If BAREST user
        if (userRoles.includes(Constants.BAREST)) {
          let enterprises = [];
          const res = await db.collection(Constants.ENTERPRISE).get();
          res.forEach(doc => {
            enterprises.push({ ...doc.data(), uid: doc.id })
          })
          setEnterprises(enterprises);
          if (enterprises.length) setCurrentEnterprise({ ...enterprises[0], currentRole, index: 0 });
        }
      })
      .catch((error) => {
        console.log(error)
        const msgError =
          (error.code === Constants.CODE_AUTH_NOT_FOUND_USER || error.code === Constants.CODE_AUTH_WRONG_PASS) ?
            "El usuario no se encuentra registrado o ingresaste mal tu usuario y/o contraseña. ¡Intenta de nuevo!" :
            error.message;

        setModal(new AlertModal(
          msgError,
          "Error iniciando sesión",
          Constants.TYPE_ALERT.error
        ));
        setLoading(false);
        handleShow();
      })
  }

  /* const openModalRecoverPass = () => {
    childRecoverPassRef.current.handleShowModal();
  }
 */
  return (
    <main>
      <ForgotPassword ref={childRecoverPassRef} />
      <RenderModal content={modal}></RenderModal>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Ingresa a la plataforma</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" onChange={(event) => setEmail(event.target.value.trim())} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Constraseña</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" onChange={(event) => setPassword(event.target.value.trim())} />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      {/* <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Recordarme</FormCheck.Label>
                      </Form.Check> */}
                      {/*  <Card.Link className="small text-end" onClick={openModalRecoverPass} >¿Olvidaste la contraseña?</Card.Link> */}
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" onClick={login} className="w-100">
                    Ingresar
                    {loading && (
                      <FontAwesomeIcon icon={faSpinner} spin className="mx-2" />
                    )}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
