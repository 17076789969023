import React, { useState, useRef, useCallback, useEffect, useContext } from "react";
import "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import DataTable from "../../components/dataTable";
import { ViewDetailItemModel } from "../../components/models/ViewDetailItemModel";
import { BranchAddUpdate } from "./BranchAddUpdate";
import useAlert from "../../utils/hooks/useAlert";
/* import firebase from 'firebase/app'; */
import { tableConfig } from './tableConfig'
import { TransformTemplateViewDetail } from "../../utils/utils";
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import { appContext } from "../../state/context";

const BranchData = () => {
  const db = useFirestore();
  const { currentEnterprise } = useContext(appContext)
  const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid);
  const branchesQuery = queryEnterprise.collection(Constants.BRANCHS).where("status", "==", true);
  const { data: branchesDataObserver } = useFirestoreCollectionData(branchesQuery);
  const [branchs, setBranchs] = useState(
    branchesDataObserver.map(item => ({ ...item, id: item['NO_ID_FIELD'] }))
  );
  const { handleShowAlert, RenderAlert } = useAlert();
  const childAddBranchPassRef = useRef();

  useEffect(() => {
    setBranchs(branchesDataObserver.map(item => ({ ...item, id: item['NO_ID_FIELD'] })))
  }, [branchesDataObserver])

  const titlesView = {
    name: "Nombre del establecimiento",
    address: "Dirección",
    country: "País",
    city: "Ciudad",
    phone_number: "Teléfono",
    lat_lon_georeference: "Latitud y longitud",
    manager_email: "Email",
    currency_value: "Moneda",
    tipping_info: "Propinas",
    payments_accounts: "ID Stripe",
    percentage_service: "Procentaje gastos de servicio",
    fixed_value_service: "Valor fijo gastos de servicio",
    opening_time: "Hora de apertura",
    closing_time: "Hora de cierre"
  };

  const [viewDetail, setViewDetail] = useState();

  const openAddUpdateBranch = useCallback((isUpdate, branch) => {
    childAddBranchPassRef.current.addOrUpdateBranch(isUpdate, branch);
  }, [childAddBranchPassRef]);

  const getCurrency = async (branch) => {
    let currencyValue;
    if (typeof branch.currency === "object") {
      currencyValue = await branch.currency.get();
      currencyValue = currencyValue.data();
      return currencyValue;
    }
  };

  const editBranch = async (item) => {
    item = { ...item };
    item.currency = await getCurrency(item);
    item.currency = item.currency?.code;
    item.latitud_georeference =
      item.lat_lon_georeference.split(",")[0].trim() ?? 0;
    item.longitud_georeference =
      item.lat_lon_georeference.split(",")[1].trim() ?? 0;
    //setBranchSelected(item);
    openAddUpdateBranch(true, item);
  };

  const viewBranch = async (item) => {
    let itemTemp = { ...item };
    item = JSON.parse(JSON.stringify(item));
    item.currency_value = await getCurrency(itemTemp);
    item.currency_value = `${item.currency_value?.code}-${item.currency_value?.name}`;
    let tippings = [];
    item.tipping_info = item.tipping_info ?? {};
    Object.keys(item.tipping_info).forEach((key) => {
      tippings.push(`${item.tipping_info[key]}%`);
    });
    item.tipping_info = tippings;
    let viewDetailtBranch = TransformTemplateViewDetail(titlesView, item);
    setViewDetail(
      new ViewDetailItemModel(
        `Establecimiento ${item.name}`,
        `Datos del establecimiento ${item.name}`,
        viewDetailtBranch
      )
    );
  };

  const removeBranch = (item) => {
    const query = queryEnterprise.collection(Constants.BRANCHS)
      .doc(item.id);
    query.update({
      status: false,
    }).then(() => {
      showAlert(
        `Establecimiento ${item.name} eliminado correctamente`,
        "",
        Constants.TYPE_ALERT.success,
        ""
      )
    }).catch((error) => {
      showAlert(
        `Ocurrió un error al eliminar el establecimiento '${item.name}'. `,
        "",
        Constants.TYPE_ALERT.error,
        "",
        error
      );
    });
  };

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  };

  return (
    <>
      <RenderAlert></RenderAlert>
      <BranchAddUpdate ref={childAddBranchPassRef} branchs={branchs} />
      <DataTable
        items={branchs}
        tableConfig={tableConfig}
        addNewHandler={() => openAddUpdateBranch(false)}
        editItemHandler={editBranch}
        removeItemHandler={removeBranch}
        viewItemHandler={viewBranch}
        contentViewDetail={viewDetail}
        addNewLabel="Agregar Nuevo"
        searchFilterKey="name"
      />
    </>
  );
};

export default BranchData;
