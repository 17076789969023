import React, { useState, useImperativeHandle, forwardRef, useContext, useRef, useCallback } from "react";
import { useFirestore, useFirebaseApp } from 'reactfire';
import { Col, Row, Form, Button, Container, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { appContext } from "../../state/context"
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import { CreateNewUserAuth } from "../../components/CreateNewUser";
import KitchenUserModel from './models/KitchenUserModel';
import { TemplateEmail } from "../../utils/models/TemplateEmail";
import Preloader from "../../components/Preloader";
import useAlert from "../../utils/hooks/useAlert";
import { User } from "../../utils/models/User";

export const KitchenAddUpdate = forwardRef(({ branch, existingUsers }, ref) => {
  const { currentEnterprise } = useContext(appContext);
  const [loaded, setLoaded] = useState(false);
  const [userAlreadyExist, setUserAlreadyExist] = useState(false);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [showModal, setShowModal] = useState(false);
  const [kitchenUser, setKitchenUser] = useState(new KitchenUserModel());
  const firebase = useFirebaseApp();
  const db = useFirestore();
  const childPassRef = useRef();

  const messages = {
    success: "Se ha enviado un correo al cocinero asignado al estalecimiento",
    error: "No se pudo crear y enviar el email al cocinero asignado al estalecimiento"
  }

  const queryKitchen = db
    .collection(Constants.ENTERPRISE)
    .doc(currentEnterprise.uid)
    .collection(Constants.BRANCHS)
    .doc(branch?.id)
    .collection(Constants.KITCHEN_USERS);

  useImperativeHandle(ref, () => ({
    addOrUpdateKitchen(isUpdate, kitchenUserInfo) {
      if (isUpdate) {
        setKitchenUser(kitchenUserInfo);
      } else {
        clearForm();
        setUserAlreadyExist(false);
      }
      handleShowModal();
    },
  }));

  const handleHideModal = () => setShowModal(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setKitchenUser((prevState) => ({ ...prevState, [name]: value }));

    if (existingUsers.filter(user => user.email === value).length) {
      setUserAlreadyExist(true)
    }
  };

  const addKitchenUser = (e) => {
    e.preventDefault();
    setLoaded(true);
    queryKitchen.add(kitchenUser).then(async () => {
      await addNewUserAuth();
      setLoaded(false);
      clearForm();
      handleHideModal();
      showAlert(`Usuario de Kitchening, ${kitchenUser.email} creado correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al crear el usuario.`, "", Constants.TYPE_ALERT.error, "", error);
    });
  };

  const addNewUserAuth = useCallback(async () => {
    const ischeckedEmail = await firebase.auth().fetchSignInMethodsForEmail(kitchenUser.email);
    const params = {
      EMAIL: kitchenUser.email,
      BRANCH: branch.name,
      ROL: "usuario de cocina",
      URL: process.env.REACT_APP_URL_BAREST
    }
    const templateEmail = new TemplateEmail(
      kitchenUser.email,
      "¡Bienvenido!",
      ischeckedEmail.length ? Constants.NEW_ASSIGN_BRANCH : Constants.NEW_USER,
      params
    )
    let user = new User();
    user.roles_array.push(Constants.ROLES);
    user.roles_array[0]["role"]["STAFF"] = true;
    user.email = kitchenUser.email;
    user = JSON.parse(JSON.stringify(user));
    user.roles_array[0]["entity_uuid"] = db.doc(`/${Constants.ENTERPRISE}/${currentEnterprise?.uid}`);
    user.roles_array[0]["branch_uuid"] = branch?.id;
    childPassRef.current.addOrUpdateNewUser(user, templateEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childPassRef, kitchenUser, branch, currentEnterprise]);

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  };

  const clearForm = () => {
    setKitchenUser(new KitchenUserModel());
    setUserAlreadyExist(false);
  };

  return (
    <section>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <CreateNewUserAuth userSaved={() => { }} messages={messages} ref={childPassRef} />
      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        as={Modal.Dialog}
        centered
        show={showModal}
      >
        <Modal.Header className="m-success">
          <Modal.Title className="h6 text-white">
            CREAR USUARIO COCINA
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleHideModal}
          />
        </Modal.Header>
        <Modal.Body>
          <section className="d-flex align-items-center">
            <Container>
              <div className="bg-white w-100 fmxw-500 m-auto">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">Completa todos los campos requeridos</h5>
                </div>
              </div>
              <Form className="mt-4" onSubmit={addKitchenUser}>
                <Row className="justify-content-center form-bg-image">
                  <Col md={12}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Email</Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={kitchenUser.email}
                          type="email"
                          name="email"
                          onChange={handleChangeForm}
                          required
                        />
                      </InputGroup>
                      {userAlreadyExist && (
                        <p className="my-2 mt-error">
                          <FontAwesomeIcon icon={faExclamationCircle} className="mx-2" />
                          Este usuario ya existe
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-right mt-4">
                  <Button
                    variant="link"
                    className="text-gray ms-auto mx-2"
                    onClick={clearForm}
                  >
                    Limpiar
                  </Button>
                  <Button variant="primary" type="submit" disabled={userAlreadyExist}>
                    Guardar
                  </Button>
                </div>
              </Form>
            </Container>
          </section>
        </Modal.Body>
      </Modal>
    </section>
  );
});