export default class Product {
  id;
  UID_subcategory_document_reference;
  sku;
  name;
  description;
  image_url;
  price;
  position_order;
  is_enabled;
  is_available_in_bar;
  require_kitchen;
  timer_cook;
  allergens;
  branch_id;
  sub_category_id;

  constructor(
    _UID_subcategory_document_reference,
    _position_order,
    _sku,
    _name,
    _description,
    _image_url,
    _price,
    _is_enabled,
    _is_available_in_bar,
    _require_kitchen,
    _allergens,
    _branch_id,
    _sub_category_id,
    _timer_cook
  ) {
    this.UID_subcategory_document_reference = _UID_subcategory_document_reference ?? "";
    this.sku = _sku ?? "";
    this.name = _name ?? "";
    this.description = _description ?? "";
    this.image_url = _image_url ?? null;
    this.price = _price ?? 0;
    this.position_order = _position_order ?? 0;
    this.is_enabled = _is_enabled ?? true;
    this.is_available_in_bar = _is_available_in_bar ?? false;
    this.require_kitchen = _require_kitchen ?? false;
    this.allergens = _allergens;
    this.branch_id = _branch_id;
    this.sub_category_id = _sub_category_id;
    this.timer_cook = _timer_cook;
  }
}