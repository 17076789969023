import React, { useContext, useRef, useState } from 'react';
import PrintersAddUpdate from './PrintersAddUpdate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card, Button, Breadcrumb, Table } from '@themesberg/react-bootstrap';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Preloader from '../../../components/Preloader';
import useAlert from '../../../utils/hooks/useAlert';
import BranchSelect from '../../../components/BrachSelect';
import { Constants } from '../../../utils/constants';
import { AlertModal } from '../../../utils/models/AlertModal';
import { useFirestore } from 'reactfire';
import { appContext } from '../../../state/context';
import { MenuItemTable } from '../../../components/Tables';
import { TransformTemplateViewDetail } from '../../../utils/utils';
import { ViewDetailItemModel } from '../../../components/models/ViewDetailItemModel';

export default () => {
	const { currentEnterprise } = useContext(appContext)
	const [loaded, setLoaded] = useState(false);
	const { handleShowAlert, RenderAlert } = useAlert();
	const [branchCurrent, setBranchCurrent] = useState();
	const [printers, setPrinters] = useState([]);
	const [viewDetail, setViewDetail] = useState();
	const childAddPrinterPassRef = useRef();
	const db = useFirestore();
	const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid);

	const titlesView = {
    name: "Nombre impresora",
    ip: "IP",
    type: "Tipo",
  }

	const branchSelected = (branch) => {
		setBranchCurrent(branch);
		if (branch.hasOwnProperty('id')) {
			getPrinters(branch.id);
		}
	}

	const openAddUpdatePrint = (isUpdate, printerUpdate) => {
		if (branchCurrent !== null && branchCurrent !== "" && branchCurrent !== "0") {
			childAddPrinterPassRef.current.addOrUpdatePrinters(isUpdate, printerUpdate);
		} else {
			showAlert(`Debes primero crear un establecimiento para asignarle impresoras`, "", Constants.TYPE_ALERT.error, "", "");
		}
	}

	const getPrinters = (branchSelectedCurrent) => {
		branchSelectedCurrent = branchSelectedCurrent ? branchSelectedCurrent : branchCurrent.id;
		let printersLoad = [];
		const query = queryEnterprise
			.collection(Constants.BRANCHS)
			.doc(branchSelectedCurrent)
			.collection(Constants.PRINTERS);
		setPrinters([]);
		setLoaded(true);
		query.get()
			.then(querySnapshot => {
				setLoaded(false);
				querySnapshot.forEach(async (doc) => {
					const data = doc.data();
					printersLoad.push({ ...data, id: doc.id });
				})
				setPrinters(printersLoad);
			})
			.catch(error => {
				setLoaded(false);
				showAlert(`Ocurrió un error al traer las impresoras.`, "", Constants.TYPE_ALERT.error, "", error);
			});
	}

	const showAlert = (body, title, typeAlert, typeModal, err) => {
		handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
	}

	const editPrinter = (item) => {
    openAddUpdatePrint(true, item);
	}

	const removePrinter = (item) => {
		setLoaded(true);
		const query = queryEnterprise
			.collection(Constants.BRANCHS)
			.doc(branchCurrent.id)
			.collection(Constants.PRINTERS);
		query.doc(item.id).delete().then(() => {
			setLoaded(false);
			getPrinters();
			showAlert(`Impresora ${item.name} eliminada correctamente`, "", Constants.TYPE_ALERT.success, "");
		})
			.catch((error) => {
				setLoaded(false);
				showAlert(`Ocurrió un error al eliminar la impresora ${item.name}. `, "", Constants.TYPE_ALERT.error, "", error);
			});
	}

	const viewPrinter = (items) => {
		const item = JSON.parse(JSON.stringify(items));
    item.type = Constants.TYPE_PRINTERS[item.type];
    let viewDetail = TransformTemplateViewDetail(titlesView, item);
    setViewDetail(new ViewDetailItemModel(
      `Impresora ${item.name}`,
      `Datos impresora ${item.name}`,
      viewDetail
    ));
	}

	return (
		<>
			<Preloader show={loaded ? true : false} />
			<RenderAlert></RenderAlert>
			<PrintersAddUpdate
				selectedBranch={branchCurrent}
				printers={printers}
				getPrinters={getPrinters}
				ref={childAddPrinterPassRef} />
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
						<Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
						<Breadcrumb.Item active>Impresoras</Breadcrumb.Item>
					</Breadcrumb>
					<h4>Impresoras</h4>
					<p className="mb-0">Impresoras registradas (Solo puedes registrar una impresora por tipo)</p>
				</div>
			</div>

			<div className="table-settings mb-4">
				<Row className="justify-content-between align-items-center">
					<Col xs={8} md={6} lg={3} xl={4}>
						<BranchSelect branchSelected={branchSelected}></BranchSelect>
					</Col>
					<Col className="text-right">
						<Button variant="primary" type="button" onClick={() => openAddUpdatePrint(false)}>Agregar impresora</Button>
					</Col>
				</Row>
			</div>
			<Card border="light" className="table-wrapper table-responsive shadow-sm">
				<Card.Body className="pt-0">
					<Table hover className="user-table align-items-center">
						<thead>
							<tr>
								<th className="border-bottom">#</th>
								<th className="border-bottom">Nombre Impresora</th>
								<th className="border-bottom">IP</th>
								<th className="border-bottom">Tipo</th>
								<th className="border-bottom"></th>
							</tr>
						</thead>
						<tbody>
							{
								printers.map((res, index) => {
									return <tr key={`${res.id}-${index}`}>
										<td>{index + 1}</td>
										<td>{res.name}<small className="text-gray opacity-50">({res.id})</small></td>
										<td>{res.ip}</td>
										<td>{Constants.TYPE_PRINTERS[res.type]}</td>
										<MenuItemTable
											editItem={editPrinter}
											removeItem={removePrinter}
											viewItem={viewPrinter}
											itemSelected={res}
											contentViewDetail={viewDetail}
										></MenuItemTable>
									</tr>
								})
							}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</>
	)
}