const Consts = {
  TYPE_ALERT: {
    success: "OK",
    warning: "WARN",
    error: "BAD",
  },
  TYPE_MODAL: {
    info: "INFO",
    confirm: "CONFIRM",
  },
  TYPE_TABLES_BAR: {
    TABLE: "TABLE",
    BAR: "BAR",
  },
  GENDER: {
    MALE: "MASCULINO",
    FEMALE: "FEMENINO",
  },
  STATUS_TABLES_BAR: {
    ACTIVE: "active",
    INACTIVE: "inactive",
    DELETED: "deleted",
  },
  ROLES: {
    role: {
      BAREST: false,
      ADMIN: false,
      MANAGER: false,
      STAFF: false,
      KITCHEN: false
    }
  },
  TIME_INTERVALS: {
    day: 'day',
    week: 'week',
    month: 'month',
  },
  PASSWORD_ERRORS: {
    WEAK_PASSWORD: "auth/weak-password",
    WRONG_PASSWORD: "auth/wrong-password",
  },
  TYPE_PRINTERS: {
    KITCHEN: "Cocina",
    BAR: "Barra",
    ALL: "Cocina y Barra"
  },
  STATUS_PRODUCT: {
    pending: "Pendiente",
    accepted: "Aceptado",
    in_progress: "En progreso",
    delivered: "Entregado",
    cancelled: "Cancelado",
    prepared: "Preparado"
  },
  STATUS_PRODUCT_REMOVE: {
    delete: "Eliminado",
    invitation: "Invitación"
  },
  TYPE_STATUS_TRANSACTION: {
    approved: "approved",
    error: "error",
  },

  TYPE_COLUMN_DATA_TABLE: {
    file: "file",
    string: "string"
  },
  TYPE_FILE_EXTENSION: {
    xls: "xls",
    pdf: "pdf"
  },

  URL_IMAGES_PRODUCTS: "images/products",
  URL_FILES_REPORTS_Z: "file/reportsZ",

  CODE_AUTH_WRONG_PASS: "auth/wrong-password",
  CODE_AUTH_NOT_FOUND_USER: "auth/user-not-found",
  CODE_NOT_FOUND: "not-found",
  CODE_CANCELLED: "cancelled",
  CODE_UNKNOWN: "unknown",
  CODE_INVALID_ARGUMENT: "invalid-argument",
  CODE_DEADLINE_EXCEEDED: "deadline-exceeded",
  CODE_ALREADY_EXISTS: "already-exists",
  CODE_PERMISSION_DENIED: "permission-denied",
  CODE_RESOURCE_EXHAUSTED: "resource-exhausted",
  CODE_FAILED_PRECONDITION: "failed-precondition",
  CODE_ABORTED: "aborted",
  CODE_OUT_OF_RANGE: "out-of-range",
  CODE_UNIMPLEMENTED: "unimplemented",
  CODE_INTERNAL: "internal",
  CODE_UNAVAILABLE: "unavailable",
  CODE_DATA_LOSS: "data-loss",
  CODE_UNAUTHENTICATED: "unauthenticated",
  ITEMS_PER_PAGE: 10,

  /*Collections*/
  PRODUCT_ORDER: "Product_order",
  REQUESTS: "Request",
  JOURNALS: "Journals",
  BRANCHS: "Branch",
  CATEGORIES: "Category",
  SUBCATEGORIES: "Sub_category",
  TABLES_BAR: "Tables_and_Bar",
  ENTERPRISE: "Enterprise",
  CURRENCYS: "Currency",
  TIPPING_INFO: "Tipping_Info",
  PRODUCT: "Product",
  STAFF_USERS: "Staff",
  OTHER_USERS_INFO: "Other_users_info",
  USER_INFO: "User_info",
  KITCHEN_USERS: "Kitchen",
  PRINTERS: "Printer",
  PAYMENTS: "Payment",
  PRODUCT_REMOVE: "Product_remove",
  PRINTS: "Print",
  TRANSACTION: "Transaction",
  REPORTZ: "Report_z",

  // Roles
  BAREST: 'BAREST',
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  STAFF: 'STAFF',
  KITCHEN: 'KITCHEN',

  //Templates email
  NEW_USER: "new-user",
  NEW_ASSIGN_BRANCH: "to-assign-branch",
  NEW_USER_ADMIN_ENTERPRISE: "new-user-admin-enterprise",
  NEW_ASSIGN_ENTERPRISE: "to-assign-enterprise",

  PERCENTAGE_SERVICES_BAREST: 1,
  PERCENTAGE_SERVICES_STRIPE: 1.6,
  FIXED_VALUE_SERVICES: 0.25,
};

export const Constants = { ...Consts };