import React, { useState, useRef, useCallback, useEffect, useContext, useMemo } from "react";
import { Col, Row, Form } from '@themesberg/react-bootstrap';
import "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { appContext } from "../../state/context";
import DataTable from "../../components/dataTable";
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import { ProductAddUpdate } from "./ProductsAddUpdate";
import useAlert from "../../utils/hooks/useAlert";
import { tableConfig } from './models/tableConfig'
import { getRolesUser, TransformTemplateViewDetail } from "../../utils/utils";
import Preloader from "../../components/Preloader";
import { ViewDetailItemModel } from "../../components/models/ViewDetailItemModel";

const ProductData = () => {
  const { user, currentEnterprise } = useContext(appContext)
  const [loaded, setLoaded] = useState(false);
  const db = useFirestore();
  const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid);
  const branchesQuery = queryEnterprise.collection(Constants.BRANCHS);
  const [docCurrentBranch, setDocCurrentBranch] = useState("");
  const [nameBranch, setNameBranch] = useState("");
  const [docCurrentCategorie, setDocCurrentCategorie] = useState("");
  const [docCurrentSubCategorie, setDocCurrentSubCategorie] = useState("");
  const [currencyBranch, setCurrencyBranch] = useState("");
  const [branchs, setBranchs] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [currentRefProduct, setCurrentRefProduct] = useState("0");
  const { handleShowAlert, RenderAlert } = useAlert();
  const childAddProductPassRef = useRef();
  const [viewDetail, setViewDetail] = useState();
  const productsQuery = db.collection(Constants.PRODUCT);
  const { data: productsDataObserver } = useFirestoreCollectionData(productsQuery.where("UID_subcategory_document_reference", "==", currentRefProduct).orderBy("position_order"));
  tableConfig.find(res => res.key === "price").label = `Precio (${currencyBranch?.code} - ${currencyBranch?.symbol})`;

  const userRoles = user?.roles_array ? getRolesUser(user.roles_array) : [];
  const isBarest = userRoles.includes(Constants.BAREST) || userRoles.includes(Constants.ADMIN);

  const branchesQuerySearch = useMemo(() => {
    return isBarest ? queryEnterprise.collection(Constants.BRANCHS) : queryEnterprise.collection(Constants.BRANCHS).where("manager_email", "==", user.email)
  }, [isBarest, user, queryEnterprise]);

  useEffect(() => {
    setProducts(productsDataObserver.map(item => (
      {
        ...item, id: item['NO_ID_FIELD'],
        enabled: item.is_enabled ? "Si" : "No",
        available_bar: item.is_available_in_bar ? "Si" : "No"
      }
    )))
  }, [productsDataObserver])

  useEffect(() => {
    setDocCurrentCategorie("");
    setCategories([]);
    setSubcategories([]);
    if (docCurrentBranch) {
      const query = branchesQuery.doc(docCurrentBranch).collection(Constants.CATEGORIES);
      async function getCategories() {
        const categoriesSearch = await query.orderBy("position_order").get();
        categoriesSearch.forEach((doc) => {
          setCategories(prev => [...prev, { ...doc.data(), id: doc.id }])
        })
        if (categoriesSearch?.docs.length > 0)
          setDocCurrentCategorie(categoriesSearch.docs[0].id);
        else {
          setProducts([]);
          setDocCurrentCategorie("0");
        }

      }

      async function getCurrency() {
        let currencyValue;
        const currentBranch = branchs.find(res => res.id === docCurrentBranch);
        if (typeof currentBranch?.currency === "object") {
          currencyValue = await currentBranch?.currency.get();
          currencyValue = currencyValue.data();
          setCurrencyBranch(currencyValue);
        }
      }
      getCategories();
      getCurrency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docCurrentBranch])

  useEffect(() => {
    setDocCurrentSubCategorie("");
    setSubcategories([]);
    async function changeCategorie() {
      if (docCurrentCategorie) {
        const query = branchesQuery.doc(docCurrentBranch).collection(Constants.CATEGORIES);
        await getSubCategories(query, docCurrentCategorie);
      }
    }
    changeCategorie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docCurrentCategorie])

  useEffect(() => {
    if (docCurrentSubCategorie) {
      let subCategoryDocRef = branchesQuery
        .doc(docCurrentBranch)
        .collection(Constants.CATEGORIES)
        .doc(docCurrentCategorie)
        .collection(Constants.SUBCATEGORIES)
        .doc(docCurrentSubCategorie);
      setCurrentRefProduct(subCategoryDocRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docCurrentSubCategorie])

  useEffect(() => {
    getBranchs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEnterprise])

  const titlesView = {
    name: "Nombre producto",
    sku: "Código",
    description: "Descripción",
    price: `Precio(${currencyBranch?.symbol ?? ""})`,
    enabled: "¿Habilitada?",
    available_bar: "¿Habilitada en barra?",
    require_kitchen: "¿Requiere cocinar?",
    allergens: "Alérgenos",
    timer_cook: "Tiempo de cocina"
  };

  const openAddUpdateProduct = useCallback((isUpdate, branch) => {
    childAddProductPassRef.current.addOrUpdateProduct(isUpdate, branch);
  }, [childAddProductPassRef]);

  function getBranchs() {
    setBranchs([]);
    setLoaded(true);
    branchesQuerySearch.get().then(query => {
      setLoaded(false);
      query.forEach((doc) => {
        setBranchs(prev => [...prev, { ...doc.data(), id: doc.id }])
      })
      if (query.docs.length > 0) {
        const firsBranch = query.docs[0];
        setNameBranch(firsBranch.data().name.split(" ").join("_"));
        setDocCurrentBranch(firsBranch.id);
      }

    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al traer tus establecimiento asignados.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const getSubCategories = async (query, refCategorie) => {
    const subCategoriesSearch = await query.doc(refCategorie)
      .collection(Constants.SUBCATEGORIES)
      .orderBy("position_order").get();
    subCategoriesSearch.forEach((doc) => {
      setSubcategories(prev => [...prev, { ...doc.data(), id: doc.id }])
    })
    if (subCategoriesSearch?.docs.length > 0)
      setDocCurrentSubCategorie(subCategoriesSearch.docs[0].id);
    else {
      setProducts([]);
      setDocCurrentSubCategorie("");
    }
  }

  const editProduct = async (item) => {
    openAddUpdateProduct(true, item);
  };

  const viewProduct = async (item) => {
    let itemTemp = { ...item };
    itemTemp.require_kitchen = itemTemp.require_kitchen ? "Si" : "No";
    let viewDetailtProduct = TransformTemplateViewDetail(titlesView, itemTemp);
    setViewDetail(
      new ViewDetailItemModel(
        `Producto ${item.name}`,
        `Datos del producto ${item.name}`,
        viewDetailtProduct
      )
    );
  };

  const removeProduct = (item) => {
    setLoaded(true);
    productsQuery.doc(item.id).delete().then(() => {
      setLoaded(false);
      showAlert(`Producto ${item.name} eliminado correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al eliminar el producto.`, "", Constants.TYPE_ALERT.error, "", error);
    });
  };

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  };

  const changeBranch = (e) => {
    const value = e.target.value.split("-");
    setDocCurrentBranch(value[0]);
    setNameBranch(value[1].split(" ").join("_"));
  }

  return (
    <>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <ProductAddUpdate
        currentBranch={docCurrentBranch}
        currentCategorie={docCurrentCategorie}
        currentSubCategorie={docCurrentSubCategorie}
        nameBranch={nameBranch}
        products={products}
        currencyBranch={currencyBranch}
        ref={childAddProductPassRef}
        currentRefProduct={currentRefProduct} />
      <div className="table-settings mb-1">
        <Row className="align-items-center">
          <Col xs={12} md={4}>
            <Form.Group className="mb-2">
              <Form.Label>Buscar por establecimiento:</Form.Label>
              <Form.Select id="branch" onChange={(e) => changeBranch(e)}>
                {
                  branchs.map((item, i) => <option key={i} value={`${item.id}-${item.name}`}>{item.name}</option>)
                }
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-2">
              <Form.Label>Categoría:</Form.Label>
              <Form.Select id="categorie" onChange={(e) => setDocCurrentCategorie(e.target.value)}>
                {
                  categories.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
                }
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-2">
              <Form.Label>Sub categoría:</Form.Label>
              <Form.Select id="subCategorie" onChange={(e) => setDocCurrentSubCategorie(e.target.value)}>
                {
                  subcategories.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
                }
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </div>
      <DataTable
        items={products}
        tableConfig={tableConfig}
        addNewHandler={() => openAddUpdateProduct(false)}
        editItemHandler={editProduct}
        removeItemHandler={removeProduct}
        viewItemHandler={viewProduct}
        contentViewDetail={viewDetail}
        addNewLabel="Agregar Nuevo"
        searchFilterKey="name"
      />
    </>
  );
};

export default ProductData;
