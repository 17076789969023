import React from 'react';
import { Page, View, Document, StyleSheet, Image, Text } from '@react-pdf/renderer';
import logoBarest from "../assets/img/logo-barest.png"

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: 10
  },
  section: {
    margin: 10,
    borderWidth: 3,
    borderRadius: 15,
    padding: 25,
    paddingRight: 10,
    paddingVertical: 0
  },
  image: {
    width: 115,
    height: 115,
    marginRight: 15
  },
  iconBarest: {
    width: 40,
    alignSelf: "flex-end",
    marginHorizontal: 5,
    marginTop: 10,
    marginBottom: 6
  },
  textBarTable: {
    fontSize: 9,
    color: "#FFAC00",
    fontWeight: "900",
    marginTop: 7
  },
  texTitle: {
    fontSize: 8,
    color: "#0D0043",
    marginVertical: 2,
    marginBottom: 8
  }
});

export const QRCodeTablesBar = ({ qrTablesBars }) => (
  <Document>
    <Page size="A4" style={styles.page} wrap>
      {
        qrTablesBars.map((res, index) =>
          <View key={index} style={styles.section} wrap={false}>
            <Text style={styles.textBarTable}>{res.type} {res.name}</Text>
            <Text style={styles.texTitle}>Pide y paga desde la app</Text>
            <Image style={styles.image} source={{ uri: res.image }}></Image>
            <Image style={styles.iconBarest} source={{ uri: logoBarest }}></Image>
          </View>)
      }
    </Page>
  </Document>
);