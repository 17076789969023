import { Constants } from "../constants";
import { messageCodeErrorsFB } from "../utils";

export class AlertModal {
  body;
  title;
  type;
  typeModal;

  constructor(_body, _title, _type, _typeModal, _error) {
    this.body = _body ?? "¡Algo anda mal!";
    this.title = _title ?? "¡Error!";
    this.type = _type ?? Constants.TYPE_ALERT.error;
    this.typeModal = _typeModal ?? Constants.TYPE_MODAL.info;
    if (_error) this.setErrorFB(_error);
  }

  setErrorFB(_error) {
    const error = messageCodeErrorsFB(_error.code);
    this.body = `${this.body} ${error === "ERROR" ? _error.message : error}`;
  }
}
