import React, { useState, useRef, useContext, useMemo } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Col, Row, Form, Card, InputGroup, Table, Button } from '@themesberg/react-bootstrap';
import { KitchenAddUpdate } from "./KitchenAddUpdate"
import { appContext } from "../../state/context"
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import { MenuItemTable } from '../../components/Tables';
import PaginationBarest from "../../components/Pagination";
import Preloader from "../../components/Preloader";
import useAlert from "../../utils/hooks/useAlert";
import { TransformTemplateViewDetail, toDateTime, formatDateString, getRolesUser } from "../../utils/utils";
import RecoverPassword from "../../components/RecoverPassword";
import { ViewDetailItemModel } from "../../components/models/ViewDetailItemModel";
import { useEffect } from "react";

export default () => {
  const { user, currentEnterprise } = useContext(appContext);
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(Constants.ITEMS_PER_PAGE);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [viewDetail, setViewDetail] = useState();
  const KitchenPassRef = useRef();
  const db = useFirestore();

  const userRoles = user?.roles_array ? getRolesUser(user.roles_array) : [];

  const isBarest = userRoles.includes(Constants.BAREST) || userRoles.includes(Constants.ADMIN);
  const [activeBranchUid, setActiveBranchUid] = useState("0");
  const [branchSelected, setBranchSelected] = useState();
  const [branches, setBranches] = useState([])

  const queryEnterprise = useMemo(() => {
    return db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid);
  }, [currentEnterprise, db])

  const branchesQuery = useMemo(() => {
    return isBarest ? queryEnterprise.collection(Constants.BRANCHS) : queryEnterprise.collection(Constants.BRANCHS).where("manager_email", "==", user.email)
  }, [isBarest, user, queryEnterprise]);

  const kitchenQuery = queryEnterprise.collection(Constants.BRANCHS).doc(activeBranchUid).collection(Constants.KITCHEN_USERS);
  const { data: kitchenDataObserver } = useFirestoreCollectionData(kitchenQuery);

  const [kitchen, setKitchen] = useState(
    kitchenDataObserver.map(item => ({ ...item, id: item['NO_ID_FIELD'] }))
  )

  useEffect(() => {
    setKitchen(kitchenDataObserver.map(item => ({ ...item, id: item['NO_ID_FIELD'] })))
  }, [kitchenDataObserver])

  useEffect(() => {
    const getBranches = async () => {
      setBranches([]);
      const branchesSnapshot = await branchesQuery.get();
      const branchesArray = branchesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setBranches(branchesArray ?? []);
      setActiveBranchUid(branchesArray[0]?.id ?? "0");
      setBranchSelected(branchesArray[0] ?? {});
    }

    getBranches();
  }, [branchesQuery, currentEnterprise])

  const titlesView = {
    email: "Email",
    creation_date_time: "Fecha de creación",
    status_date_time: "Fecha de modificación",
    is_active: "Estado",
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredKitchenUsers = kitchen.filter(item => item?.email.toLowerCase().includes(searchFilter.toLowerCase())).slice(indexOfFirstItem, indexOfLastItem);
  const paginate = page => setCurrentPage(page);

  const changeBranch = e => {
    setActiveBranchUid(e.target.value);
    setBranchSelected(branches.find(res => res.id === e.target.value));
  }

  const viewKitchenUser = (items) => {
    const item = JSON.parse(JSON.stringify(items));
    item.creation_date_time = formatDateString(toDateTime(item.creation_date_time.seconds));
    item.status_date_time = formatDateString(toDateTime(item.status_date_time.seconds));
    item.is_active = item.is_active ? 'Activo' : 'Inactivo';
    let viewDetail = TransformTemplateViewDetail(titlesView, item);
    setViewDetail(new ViewDetailItemModel(
      "Usuario De Cocina",
      "Datos del usuario:",
      viewDetail
    ));
  }

  const addOrUpdateKitchen = () => KitchenPassRef.current.addOrUpdateKitchen(false, null, activeBranchUid);

  const removeKitchenUser = item => {
    setLoading(true);
    kitchenQuery.doc(item.id).update({
      is_active: false,
      status_date_time: new Date()
    }).then(() => {
      setLoading(false);
      showAlert(`${item.email} eliminado correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoading(false);
      showAlert(`Ocurrió un error al eliminar el usuario ${item.email}.`, "", Constants.TYPE_ALERT.error, "", error);
    });
  }

  const activateKitchenUser = item => {
    setLoading(true);
    kitchenQuery.doc(item.id).update({
      is_active: true,
      status_date_time: new Date()
    }).then(() => {
      setLoading(false);
      showAlert(`${item.email} ha sido activado correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoading(false);
      showAlert(`Ocurrió un error al activar el usuario ${item.email}.`, "", Constants.TYPE_ALERT.error, "", error);
    });
  }

  const editKitchenUser = (item) => addOrUpdateKitchen(true, item, activeBranchUid);

  const showAlert = (body, title, typeAlert, typeModal, err) => handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));

  const handleSearch = event => setSearchFilter(event.target.value);

  return (
    <>
      <Preloader show={loading ? true : false} />
      <RenderAlert></RenderAlert>
      {!loading && (
        <>
          {activeBranchUid && (
            <KitchenAddUpdate
              ref={KitchenPassRef}
              branch={branchSelected}
              existingUsers={kitchen}
            />
          )}
          <div className="kitchen-settings mb-1">
            <Row className="align-items-center">
              <Col xs={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-2">
                  <Form.Label>Buscar establecimiento:</Form.Label>
                  <Form.Select id="kitchen-users" onChange={changeBranch} value={activeBranchUid}>
                    {branches.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="kitchen-settings mb-4">
            <Row className="justify-content-between align-items-center">
              <Col xs={8} md={8} lg={4} xl={4}>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Buscar"
                    value={searchFilter}
                    onChange={handleSearch}
                  />
                </InputGroup>
              </Col>
              <Col className="text-right">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => addOrUpdateKitchen(false)}
                >
                  Agregar nuevo
                </Button>
              </Col>
            </Row>
          </div>

          <Card border="light" className="table-wrapper table-responsive shadow-sm">
            <Card.Body className="pt-0">
              <Table hover className="user-table align-items-center">
                <thead>
                  <tr>
                    <th className="border-bottom">#</th>
                    <th className="border-bottom">Email</th>
                    <th className="border-bottom">Fecha Creación</th>
                    <th className="border-bottom">Fecha Modificación Estado</th>
                    <th className="border-bottom">Estado</th>
                    <th className="border-bottom">Restablecer contraseña</th>
                    <th className="border-bottom"></th>
                  </tr>
                </thead>
                <tbody>
                  {kitchen.map((kitchenUser, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{kitchenUser.email}</td>
                        <td>
                          {formatDateString(
                            toDateTime(kitchenUser.creation_date_time.seconds)
                          )}
                        </td>
                        <td>
                          {formatDateString(
                            toDateTime(kitchenUser.status_date_time.seconds)
                          )}
                        </td>
                        <td>{kitchenUser.is_active ? "Activo" : "Inactivo"}</td>
                        <td>
                          <RecoverPassword
                            email={kitchenUser.email}
                            textButton="Restablecer">
                          </RecoverPassword>
                        </td>
                        <MenuItemTable
                          editItem={editKitchenUser}
                          removeItem={removeKitchenUser}
                          viewItem={viewKitchenUser}
                          itemSelected={kitchenUser}
                          contentViewDetail={viewDetail}
                          isViewVisible={false}
                          isEditVisible={false}
                          isRemoveVisible={kitchenUser.is_active}
                          customActions={[
                            {
                              label: 'Activar',
                              action: activateKitchenUser,
                              icon: faClipboardCheck,
                              visible: !kitchenUser.is_active
                            }
                          ]}
                        ></MenuItemTable>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                <PaginationBarest
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  totalItems={filteredKitchenUsers.length}
                  paginate={paginate}
                />
              </Card.Footer>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};
