import React, { useState, useEffect } from 'react';
import { Switch, Redirect } from "react-router-dom";
import { Routes } from "../../routing/routes";
import ProtectedRoute from "../../routing/ProtectedRoute";
import { BarestProvider } from "../../state/context"
import { Constants } from '../../utils/constants';

// pages
import Signup from "../general/Signup";
import ResetPassword from "../general/ResetPassword";
import Lock from "../general/Lock";

// Barest Pages
import Branchs from "../branchs";
import BranchManager from '../branchs/BranchManager';
import Category from '../categorys';
import DashboardOverview from "../dashboard";
import Enterprises from '../enterprises';
import Signin from "../Signin";
import Staff from "../Staff";
import Kitchen from "../Kitchen";
import TableAndBars from "../tableBars";
import NotFoundPage from "../general/NotFound";
import NotAuthorizedPage from "../general/NotAuthorized";
import Products from "../products";
import Orders from "../Orders";
import Profile from "../profile";
import ServerError from "../general/ServerError";

// components
import RouteWithSidebar from "../../components/RouteWithSidebar";
import RouteWithLoader from "../../components/RouteWithLoader";
import Printers from '../configuration/printers';
import QueuePrints from '../QueuePrints';
import Users from '../Users';
import ReportZ from '../Reports/ReportZ';

export default () => {
  const [user, setUser] = useState();
  const [currentEnterprise, setCurrentEnterprise] = useState(null);
  const [enterprises, setEnterprises] = useState([]);
  const [firstLogin, setFirstLogin] = useState(false);

  useEffect(() => {
    if (user?.first_login) {
      setFirstLogin(true);
    }
  }, [user])

  useEffect(() => {
    setCurrentEnterprise({ ...enterprises[0], currentRole: currentEnterprise?.currentRole ?? [Constants.BAREST], index: 0 });
    // eslint-disable-next-line
  }, [enterprises])

  return (
    <BarestProvider value={{ user, setUser, currentEnterprise, setCurrentEnterprise, enterprises, setEnterprises }}>
      <Switch>

        <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
        <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
        <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
        <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
        <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
        <RouteWithLoader exact path={Routes.NotAuthorized.path} component={NotAuthorizedPage} />
        <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

        {/* pages */}

        <ProtectedRoute path={Routes.Profile.path} allowedRoles={Routes.Profile.allowedRoles}>
          <RouteWithSidebar component={Profile} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Enterprise.path} allowedRoles={Routes.Enterprise.allowedRoles}>
          <RouteWithSidebar exact component={Enterprises} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Branch.path} allowedRoles={Routes.Branch.allowedRoles}>
          <RouteWithSidebar exact component={Branchs} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Category.path} allowedRoles={Routes.Category.allowedRoles}>
          <RouteWithSidebar exact component={Category} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Products.path} allowedRoles={Routes.Products.allowedRoles}>
          <RouteWithSidebar exact component={Products} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.ManagerBranch.path} allowedRoles={Routes.ManagerBranch.allowedRoles}>
          <RouteWithSidebar exact component={BranchManager} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.TablesBar.path} allowedRoles={Routes.TablesBar.allowedRoles}>
          <RouteWithSidebar exact component={TableAndBars} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Staff.path} allowedRoles={Routes.Staff.allowedRoles}>
          <RouteWithSidebar exact component={Staff} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Orders.path} allowedRoles={Routes.Orders.allowedRoles}>
          <RouteWithSidebar exact component={Orders} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Kitchen.path} allowedRoles={Routes.Kitchen.allowedRoles}>
          <RouteWithSidebar exact component={Kitchen} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.DashboardOverview.path} allowedRoles={Routes.DashboardOverview.allowedRoles}>
          <RouteWithSidebar component={DashboardOverview} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Printers.path} allowedRoles={Routes.Printers.allowedRoles}>
          <RouteWithSidebar component={Printers} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Prints.path} allowedRoles={Routes.Prints.allowedRoles}>
          <RouteWithSidebar component={QueuePrints} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Users.path} allowedRoles={Routes.Users.allowedRoles}>
          <RouteWithSidebar component={Users} />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.ReportsZ.path} allowedRoles={Routes.ReportsZ.allowedRoles}>
          <RouteWithSidebar exact component={ReportZ} />
        </ProtectedRoute>

        {firstLogin && (
          <Redirect to={Routes.Profile.path} />
        )}

        <Redirect to={Routes.NotFound.path} />
      </Switch>
    </BarestProvider>
  );
}
