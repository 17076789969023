import React from 'react';
import { currencyString } from '../../../utils/utils'

const ValueItems = ({ id, data, index }) => {
  const currentValue = data.currentValues[index] !== undefined ? data.currentValues[index].value : 0;
  const beforeValue = data.beforeValues[index] !== undefined ? data.beforeValues[index].value : 0;
  return (
    <div className="value-items">
      {id !== 'facturacion' ? (
        <>
          <span className="text-info">{currentValue}</span>
          <span className="text-warning">{beforeValue}</span>
        </>
      ) : (
        <>
          <p className="text-info">{currencyString(currentValue)}</p>
          <p className="text-warning">{currencyString(beforeValue)}</p>
        </>
      )}
    </div>
  );
}

export default ValueItems;
