export default class SubCategory {
  id;
  name;
  position_order;
  is_enabled;
  is_available_in_bar;
  branch_id;
  category_id;

  constructor(
    _name,
    _position_order,
    _is_enabled,
    _is_available_in_bar,
    _branch_id,
    _category_id
  ) {
    this.name = _name ?? "";
    this.position_order = _position_order ?? 0;
    this.is_enabled = _is_enabled ?? true;
    this.is_available_in_bar = _is_available_in_bar ?? true;
    this.branch_id = _branch_id ?? "";
    this.category_id = _category_id;
  }
}