import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp, faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import './index.scss'

const ExpandableCard = ({ item, index }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div key={item.id} className="expandable-item">
      <div className="expandable-item__header" onClick={() => setExpanded(!expanded)}>
        <span className="mx-2 my-2" style={{ display: "block" }}>
          Pedido {index + 1}
        </span>
        <FontAwesomeIcon icon={expanded ? faArrowCircleUp : faArrowCircleDown} style={{ color: 'white', marginLeft: 10, marginRight: 10 }} />
      </div>
      <ul className={`products-list ${expanded ? 'expanded' : ''}`}>
        {item.products.length > 0 ? item.products.map(product => (
          <li key={product.id}>
            <span className="title">{product.product_name}</span>
            <span className="quantity">x {product.product_quantity}</span>
          </li>
        )) : <span style={{ fontWeight: 100 }}>No hay Productos</span>}
      </ul>
    </div>
  );
}

export default ExpandableCard;
