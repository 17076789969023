
import React, { useContext } from "react";
import { Link } from "react-router-dom"
import { useAuth } from 'reactfire';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { appContext } from "../state/context";
import { Nav, Image, Navbar, Dropdown, Container } from '@themesberg/react-bootstrap';
import Profile3 from "../assets/img/team/profile-admin.png";
import logoBarest from "../assets/img/logo-barest.png"


export default () => {
  const auth = useAuth();
  const { user } = useContext(appContext);  
  const signOut = () => auth.signOut().then(() => console.log('logout'));

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <img src={logoBarest} className="w-50" alt="logo-barest" />
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={Profile3} className="user-avatar md-avatar rounded-circle"/>
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      { user.first_name || user.last_name ? (
                        `${user.first_name} ${user.last_name}`
                      ) : <span>Usuario</span> }
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item as="button" className="fw-bold">
                  <Link to="/perfil">
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> Mi perfil
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item as="button" className="fw-bold" onClick={signOut}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Cerrar sesión
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
