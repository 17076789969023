import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "firebase/firestore";
import { useStorage } from "reactfire";
import { Col, Row, Form, Card, Button, InputGroup, Table } from "@themesberg/react-bootstrap";
import { MenuItemTable } from "./Tables";
import PaginationBarest from "./Pagination";
import { Constants } from "../utils/constants";
import iconExcel from "../assets/img/icon-excel.png";
import iconPDF from "../assets/img/icon-pdf.png";
import './dataTable.scss'
import { downloadFileStorage } from "../utils/services";


const DataTable = ({
  items,
  addNewHandler,
  editItemHandler,
  removeItemHandler,
  viewItemHandler,
  contentViewDetail,
  addNewLabel,
  tableConfig,
  searchFilterKey,
  isRemoveVisible = true,
  isEditVisible = true,
  isViewVisible = true
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(Constants.ITEMS_PER_PAGE);
  const [searchFilter, setSearchFilter] = useState("");
  const storage = useStorage();

  // Filtering the list
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredItems = searchFilterKey !== null ? items.filter((item) => item[searchFilterKey].toLowerCase().includes(searchFilter.toLowerCase())).slice(indexOfFirstItem, indexOfLastItem) : items;

  // Pagination handler
  const paginate = useCallback((page) => setCurrentPage(page), []);

  const handleSearchFilter = (event) => {
    setSearchFilter(event.target.value);
  };

  const getImageTypeFileExtension = (type) => {
    switch (type) {
      case Constants.TYPE_FILE_EXTENSION.pdf:
        return iconPDF;
      case Constants.TYPE_FILE_EXTENSION.xls:
        return iconExcel;
      default:
        return iconPDF;
    }
  }

  const dowloadFile = (pathFile) => {
    const gsReference = storage.ref(pathFile);
    downloadFileStorage(gsReference, pathFile);
  }

  const getColumXType = (column, i, item) => {
    const generalElement = <th className="border-bottom" key={i}>{item[column['key']]}</th>
    if (column.type !== undefined) {
      if (column.type === Constants.TYPE_COLUMN_DATA_TABLE.file) {
        return <th className="border-bottom" key={i}>
          <div onClick={() => { dowloadFile(item[column['key']]) }} className="c-pointer">
            <img alt="icon-file" className="icon-file-width" src={getImageTypeFileExtension(column.ext)} />
          </div>
        </th >;
      } else {
        return generalElement;
      }
    } else return generalElement;
  }

  return (
    <>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {
              searchFilterKey !== null ?
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  {
                    searchFilterKey !== null ?
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        value={searchFilter}
                        onChange={handleSearchFilter}
                      /> : ""
                  }
                </InputGroup> : ""
            }
          </Col>
          {
            addNewHandler != null ?
              <Col className="text-right">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => addNewHandler()}
                >
                  {addNewLabel}
                </Button>
              </Col> : ""
          }
        </Row>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">#</th>
                {tableConfig.map(column => (
                  <th className="border-bottom" key={column['label']}>{column['label']}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{((index + 1) + (currentPage - 1) * itemsPerPage)}</td>
                    {tableConfig.map((column, i) => (
                      getColumXType(column, i, item)
                    ))}
                    <MenuItemTable
                      editItem={editItemHandler}
                      removeItem={removeItemHandler}
                      viewItem={viewItemHandler}
                      itemSelected={item}
                      contentViewDetail={contentViewDetail}
                      isRemoveVisible={isRemoveVisible}
                      isEditVisible={isEditVisible}
                      isViewVisible={isViewVisible}
                    ></MenuItemTable>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <PaginationBarest
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={searchFilter ? filteredItems.length : items.length}
              paginate={paginate}
            />
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};

export default DataTable;
