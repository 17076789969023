import { Constants } from "../../../utils/constants";

export default class Printers {
    id;
    name;
    ip;
    type;

    constructor(
        _id,
        _name,
        _ip,
        _type
    ) {
        this.id = _id ?? "";
        this.name = _name ?? "";
        this.ip = _ip ?? "";
        this.type = _type ?? Object.keys(Constants.TYPE_PRINTERS)[0];
    }
}