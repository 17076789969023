import {
  faStore,
  faBuilding,
  faChalkboard,
  faUserCircle,
  faUsers,
  faSwatchbook,
  faUtensils,
  /* faDatabase, */
  faCogs,
  faPrint,
  faUserCog,
  faChartArea
} from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../routing/routes";

const menu = [
  /* {
    title: "Dashboard",
    link: Routes.DashboardOverview.path,
    icon: faDatabase,
    isChilds: false,
    childs: [],
    allowed: Routes.DashboardOverview.allowedRoles,
  }, */
  {
    title: "Empresas",
    link: Routes.Enterprise.path,
    icon: faBuilding,
    isChilds: false,
    childs: [],
    allowed: Routes.Enterprise.allowedRoles,
  },
  {
    title: "Establecimientos",
    link: Routes.Branch.path,
    icon: faStore,
    isChilds: false,
    childs: [],
    allowed: Routes.Branch.allowedRoles,
  },
  {
    title: "Mis establecimientos",
    link: Routes.ManagerBranch.path,
    icon: faStore,
    isChilds: false,
    childs: [],
    allowed: Routes.ManagerBranch.allowedRoles,
  },
  {
    title: "Mesas y barras",
    link: Routes.TablesBar.path,
    icon: faChalkboard,
    isChilds: false,
    childs: [],
    allowed: Routes.TablesBar.allowedRoles,
  },
  {
    title: "Usuarios Staff",
    link: Routes.Staff.path,
    icon: faUsers,
    isChilds: false,
    childs: [],
    allowed: Routes.Staff.allowedRoles,
  },
  {
    title: "Gestión catálogos",
    eventKey: Routes.Catalogue.path,
    icon: faSwatchbook,
    isChilds: true,
    childs: [
      {
        title: "Categorías",
        link: Routes.Category.path,
      },
      {
        title: "Productos",
        link: Routes.Products.path,
      },
    ],
    allowed: Routes.Catalogue.allowedRoles,
  },
  {
    title: "Pedidos",
    link: Routes.Orders.path,
    icon: faUtensils,
    isChilds: false,
    childs: [],
    allowed: Routes.Orders.allowedRoles,
  },
  {
    title: "Usuarios",
    link: Routes.Users.path,
    icon: faUserCog,
    isChilds: false,
    childs: [],
    allowed: Routes.Users.allowedRoles,
  },
  {
    title: "Cola de impresiones",
    link: Routes.Prints.path,
    icon: faPrint,
    isChilds: false,
    childs: [],
    allowed: Routes.Prints.allowedRoles,
  },
  {
    title: "Reportes",
    link: Routes.Reports.path,
    icon: faChartArea,
    isChilds: true,
    childs: [
      {
        title: "Informe Z",
        link: Routes.ReportsZ.path,
      },
    ],
    allowed: Routes.Prints.allowedRoles,
  },
  {
    title: "Mi Perfil",
    link: Routes.Profile.path,
    icon: faUserCircle,
    isChilds: false,
    childs: [],
    allowed: Routes.Profile.allowedRoles,
  },
  {
    title: "Configuración",
    eventKey: Routes.Printers.path,
    icon: faCogs,
    isChilds: true,
    childs: [
      {
        title: "Impresoras",
        link: Routes.Printers.path,
      },
    ],
    allowed: Routes.Printers.allowedRoles,
  },
];

export function chooseMenu(firstLogin, rolesList) {
  if (!rolesList) return [];
  
  if (firstLogin) return menu.filter((menuItem) => menuItem.link === Routes.Profile.path);

  const roles = rolesList
    .map((rolesItem) => {
      return Object.keys(rolesItem.role).filter((rol) => rolesItem.role[rol]);
    })
    .flat();

  return menu.filter((menuItem) => {
    let allowed = false;
    roles.forEach((role) => {
      if (menuItem.allowed.includes(role)) {
        allowed = true;
      }
    });
    return allowed;
  });
}
