import ContactInfo from "./ContactInfo";

export default class Enterprise {
  id;
  name;
  enterprise_id;
  address;
  is_active;
  contact_info;

  constructor(
    _name,
    _enterprise_id,
    _address,
    _is_active,
    _contact_info
  ) {
    this.name = _name ?? "";
    this.enterprise_id = _enterprise_id ?? "";
    this.address = _address ?? "";
    this.is_active = _is_active ?? true;
    this.contact_info = new ContactInfo();
  }
}