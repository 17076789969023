import React, { useContext } from 'react';
import { useFirestore } from 'reactfire';
import { appContext } from "../../state/context";
import useAlert from "../../utils/hooks/useAlert";
import ProfileCard from "../components/ProfileCard"
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";

const Profile = () => {
  const { user, currentEnterprise, setUser } = useContext(appContext);
  const { handleShowAlert, RenderAlert } = useAlert();

  const userDetailsRef = useFirestore().collection(Constants.OTHER_USERS_INFO).doc(user.uid);

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const updateUserInfo = async userInfo => {
    try {
      await userDetailsRef.update({ ...userInfo, first_login: false });
      setUser({ ...userInfo, first_login: false });
      showAlert(`Usuario actualizado correctamente`, "", Constants.TYPE_ALERT.success, "");
    } catch (err) {
      showAlert(`Ocurrió un error al actualizar el usuario.`, "", Constants.TYPE_ALERT.error, "", err);
    }
  }

  return (
    <>
      <RenderAlert></RenderAlert>
      <ProfileCard userData={user} role={currentEnterprise?.currentRole[0] || ''} updateUserInfo={updateUserInfo} showAlert={showAlert} />
    </>
  );

}

export default Profile;
