import React, { useState, useContext, useImperativeHandle, forwardRef } from "react";
import { Col, Row, Form, Button, Container, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { useFirestore } from 'reactfire';
import 'firebase/firestore';
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import { tablesBars, statusTableBars } from "../../utils/models/Lists";
import Preloader from "../../components/Preloader";
import { TableAndBarsModel } from './models/TableAndBarsModel';
import useAlert from "../../utils/hooks/useAlert";
import { isTypeBarOrTable, getObjectKeyTrue, castFields } from "../../utils/utils";
import { appContext } from "../../state/context";

export const TableAndBarAddUpdate = forwardRef((props, ref) => {
  const { currentEnterprise } = useContext(appContext)
  const listTableBar = tablesBars.filter(res => res.value !== "ALL");
  const listStatusTableBar = statusTableBars.filter(res => res.value !== Constants.STATUS_TABLES_BAR.DELETED);
  const [loaded, setLoaded] = useState(false);
  const [typeBarTable, setTypeBarTable] = useState(listTableBar[0].value);
  const [typeStatusBarTable, setTypeStatusBarTable] = useState(listStatusTableBar[0].value);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [isSave, setIsSave] = useState(true);
  const [showModalTableBar, setShowModalTableBar] = useState(false);
  const [tableBar, setTableBar] = useState(new TableAndBarsModel());
  const db = useFirestore();

  let modelTableBar = {
    [Constants.TYPE_TABLES_BAR.TABLE]: false,
    [Constants.TYPE_TABLES_BAR.BAR]: false,
  }

  let modelStatusTableBar = {
    [Constants.STATUS_TABLES_BAR.ACTIVE]: false,
    [Constants.STATUS_TABLES_BAR.INACTIVE]: false,
    [Constants.STATUS_TABLES_BAR.DELETED]: false
  }

  const queryEnterprise =
    db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid)
      .collection(Constants.BRANCHS);

  useImperativeHandle(ref, () => ({
    addOrUpdateTableBar(isUpdate, TableBarItem) {
      if (isUpdate) {
        setIsSave(false);
        setTableBar(TableBarItem);
        setTypeStatusBarTable(getObjectKeyTrue(TableBarItem.status));
        setTypeBarTable(getObjectKeyTrue(TableBarItem.type));
      }
      else {
        clearForm();
        setIsSave(true);
      }
      handleShowModalTableBar();
    }
  }));

  const handleHideModalTableBar = () => setShowModalTableBar(false);
  const handleShowModalTableBar = () => { setShowModalTableBar(true) }

  const handleChangeForm = e => {
    const { name, value, type } = e.target;
    setTableBar(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const addUpdateMesaBar = (e) => {
    e.preventDefault();
    modelTableBar[typeBarTable] = true;
    modelStatusTableBar[typeStatusBarTable] = true;
    let tableBarModelFB = {};
    if (isSave) {
      tableBarModelFB = {
        branchId: db.doc(`/${Constants.ENTERPRISE}/${currentEnterprise?.uid}/${Constants.BRANCHS}/${props.selectBranch}`),
        number: tableBar.number,
        type: modelTableBar,
        max_capacity: Number(tableBar.max_capacity),
        status: modelStatusTableBar,
        is_available: tableBar.is_available,
        current_journal: tableBar.current_journal,
      }
      addTableBar(tableBarModelFB)
    } else {
      tableBarModelFB = {
        max_capacity: Number(tableBar.max_capacity),
        number: tableBar.number,
        status: modelStatusTableBar
      }
      updateTableBar(tableBarModelFB);
    }
  }

  const addTableBar = (tableOrModel) => {
    setLoaded(true);
    queryEnterprise.doc(props.selectBranch)
      .collection(Constants.TABLES_BAR).add(tableOrModel).then(() => {
        setLoaded(false);
        handleHideModalTableBar();
        props.getTablesBar(props.selectBranch, props.selectBarOrTable);
        showAlert(`${isTypeBarOrTable(typeBarTable)} ${tableOrModel.number} creado correctamente`, "", Constants.TYPE_ALERT.success, "");
        clearForm();
      }).catch((error) => {
        setLoaded(false);
        showAlert(`Ocurrió un error al guardar ${isTypeBarOrTable(typeBarTable)} ${tableOrModel.number}.`, "", Constants.TYPE_ALERT.error, "", error);
      })
  }

  const updateTableBar = (tableOrModel) => {
    setLoaded(true);
    queryEnterprise.doc(props.selectBranch)
      .collection(Constants.TABLES_BAR).doc(tableBar.id).update(tableOrModel).then(() => {
        setLoaded(false);
        clearForm();
        handleHideModalTableBar();
        props.getTablesBar(props.selectBranch, props.selectBarOrTable);
        showAlert(`${isTypeBarOrTable(typeBarTable)} ${tableBar.number} actualizado correctamente`, "", Constants.TYPE_ALERT.success, "");
      }).catch((error) => {
        setLoaded(false);
        showAlert(`Ocurrió un error al actualizar ${isTypeBarOrTable(typeBarTable)} ${tableBar.number}.`, "", Constants.TYPE_ALERT.error, "", error);
      })
  }

  const handleIsNameTableBar = (event) => {
    const name = event.target.value;
    setLoaded(true);
    queryEnterprise.doc(props.selectBranch)
      .collection(Constants.TABLES_BAR).where("number", "==", name).get().then(querySnapshot => {
        setLoaded(false);
        if (querySnapshot.docs.length > 0) {
          showAlert(`Ya existe una ${isTypeBarOrTable(typeBarTable)} con el nombre ${name}`, "", Constants.TYPE_ALERT.success, "");
          setTableBar(prevState => ({ ...prevState, number: "" }));
        }
      }).catch((error) => {
        setLoaded(false);
        showAlert(`Ocurrió un error al traer ${isTypeBarOrTable(typeBarTable)} ${name}.`, "", Constants.TYPE_ALERT.error, "", error);
      })
  }

  const changeTypeTableBar = (evt) => {
    setTypeBarTable(evt.target.value.trim());
    clearModelTableBar();
  }

  const clearModelTableBar = () => {
    modelTableBar = {
      [Constants.TYPE_TABLES_BAR.TABLE]: false,
      [Constants.TYPE_TABLES_BAR.BAR]: false,
    }
  }

  const clearForm = () => {
    setTableBar(new TableAndBarsModel());
    setIsSave(true);
    clearModelTableBar();
    modelStatusTableBar = {
      [Constants.STATUS_TABLES_BAR.ACTIVE]: false,
      [Constants.STATUS_TABLES_BAR.INACTIVE]: false,
      [Constants.STATUS_TABLES_BAR.DELETED]: false
    }
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  return (
    <section>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <Modal size="lg"
        backdrop="static"
        keyboard={false}
        as={Modal.Dialog} centered show={showModalTableBar}>
        <Modal.Header className="m-success">
          <Modal.Title className="h6 text-white">CREAR MESA O BAR</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleHideModalTableBar} />
        </Modal.Header>
        <Modal.Body>
          <section className="d-flex align-items-center">
            <Container>
              <div className="bg-white w-100 fmxw-500 m-auto">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">Completa todos los campos requeridos</h5>
                </div>
              </div>
              <Form className="mt-4" onSubmit={addUpdateMesaBar}>
                <Row className="justify-content-center form-bg-image">
                  <Col xs={12} md={6}>
                    <Form.Group id="country" className="mb-4">
                      <Form.Label>Tipo*</Form.Label>
                      <Form.Select id="typeTableBar" disabled={isSave ? false : true} value={typeBarTable} onChange={(event) => { changeTypeTableBar(event); setTableBar(prevState => ({ ...prevState, number: "" })); }}>
                        {
                          listTableBar.map((item, i) => <option key={i} value={item.value}>{item.name}</option>)
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="number" className="mb-4">
                      <Form.Label>Nombre o número de {isTypeBarOrTable(typeBarTable)}*</Form.Label>
                      <InputGroup>
                        <Form.Control value={tableBar.number} type="string" name="number" onBlur={handleIsNameTableBar} onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="max_capacity" className="mb-4">
                      <Form.Label>Capacidad de personas en {isTypeBarOrTable(typeBarTable)}*</Form.Label>
                      <InputGroup>
                        <Form.Control value={tableBar.max_capacity} type="number" name="max_capacity" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="city" className="mb-4">
                      <Form.Label>Estado*</Form.Label>
                      <Form.Select id="status" name="status" value={typeStatusBarTable} onChange={(event) => setTypeStatusBarTable(event.target.value)}>
                        {
                          listStatusTableBar.map((item, i) => <option key={i} value={item.value}>{item.name}</option>)
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-right mt-4">
                  {
                    isSave ? <Button variant="link" className="text-gray ms-auto" onClick={clearForm}>Limpiar</Button> : ""
                  }
                  <Button variant="primary" type="submit">
                    {isSave ? "Guardar" : "Actualizar"}
                  </Button>
                </div>
              </Form>
            </Container>
          </section>
        </Modal.Body>
      </Modal>
    </section>
  )
});