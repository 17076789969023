export const tableConfig = [
  {
    label: 'NOMBRE DEL SITIO',
    key: 'name'
  },
  {
    label: 'DIRECCIÓN',
    key: 'address'
  },
  {
    label: 'PAÍS',
    key: 'country'
  },
  {
    label: 'CIUDAD',
    key: 'city'
  },
  {
    label: 'TELÉFONO',
    key: 'phone_number'
  },
]
