export default class UsersDAO {
  username;
  email;
  first_name;
  last_name;
  birth_date
  gender;

  constructor(
    _username,
    _email,
    _first_name,
    _last_name,
    _birth_date,
    _gender,
  ) {
    this.username = _username ?? "";
    this.email = _email ?? "";
    this.first_name = _first_name ?? "";
    this.last_name = _last_name ?? "";
    this.birth_date = _birth_date ?? "";
    this.gender = _gender ?? "";
  }
}