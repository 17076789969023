import React, { useImperativeHandle, forwardRef } from "react";
import 'firebase/auth';
import firebase from 'firebase/app';
import { useFirestore } from 'reactfire';
import { AlertModal } from "../utils/models/AlertModal";
import { Constants } from '../utils/constants';
import { firebaseConfig } from '../utils/config/firebase'
import { sendEmail } from '../utils/services';
import useAlert from "../utils/hooks/useAlert";
import { generatePassword } from '../utils/utils';

export const CreateNewUserAuth = forwardRef(({ userSaved, messages }, ref) => {
  const { handleShowAlert, RenderAlert } = useAlert();
  const db = useFirestore();
  const queryUsers = db.collection(Constants.OTHER_USERS_INFO);

  useImperativeHandle(ref, () => ({
    addOrUpdateNewUser(user, template) {
      createAuthUser(user, template);
    }
  }));

  const createOtherUSerInfo = async (user, uuid) => {
    return await queryUsers.doc(uuid).set(user).then(() => {
      console.log("Usuario creado " + user.email);
    })
  }

  const updateOtherUSerInfoRole = async (user, uuid) => {
    return await queryUsers.doc(uuid).update({
      roles_array: user.roles_array
    }).then(() => {
      console.log("Usuario actualizado " + user.email);
    })
  }

  const getOtherUSerInfoForEmail = async (email) => {
    return await queryUsers.where("email", "==", email).get();
  }

  const createAuthUser = async (user, templateEmail) => {
    try {
      const password = generatePassword();
      const appFireBase = (firebase.apps.some(res => res.name === "CreateAuth")) ? firebase.app("CreateAuth") : firebase.initializeApp(firebaseConfig, "CreateAuth");
      const ischeckedEmail = await appFireBase.auth().fetchSignInMethodsForEmail(user.email);
      if (ischeckedEmail.length <= 0) {
        const createUser = await appFireBase.auth().createUserWithEmailAndPassword(user.email, password);
        appFireBase.auth().signOut();
        user["user_id"] = createUser.user.uid;
        templateEmail.parameters["PASSWORD"] = password;
        user.birth_date = firebase.firestore.Timestamp.fromDate(new Date());
        await createOtherUSerInfo(user, createUser.user.uid);
        userSaved(createUser.user);
      } else {
        let userInfo = await getOtherUSerInfoForEmail(user.email);
        const id = userInfo.docs[0].id;
        if (userInfo.docs.length) {
          userInfo = userInfo.docs[0].data();
          let rolesUser = userInfo.roles_array[0];
          const roleKeyTrue = Object.keys(user.roles_array[0].role).find(key => user.roles_array[0].role[key] === true);
          rolesUser.role[roleKeyTrue] = true;
          if (user.roles_array[0].hasOwnProperty('branch_uuid')) {
            if (rolesUser["branch_uuid"] != null || rolesUser["branch_uuid"] !== undefined) {
              if (typeof rolesUser["branch_uuid"] == 'string') {
                rolesUser["branch_uuid"] = [rolesUser["branch_uuid"]].concat(user.roles_array[0].branch_uuid);
              } else if (typeof rolesUser["branch_uuid"] == 'object') {
                rolesUser["branch_uuid"] = rolesUser["branch_uuid"].concat(user.roles_array[0].branch_uuid);
              } else {
                rolesUser["branch_uuid"] = user.roles_array[0].branch_uuid;
              }
            } else {
              rolesUser["branch_uuid"] = user.roles_array[0].branch_uuid;
            }
          }
          if (user.roles_array[0].hasOwnProperty('entity_uuid'))
            rolesUser["entity_uuid"] = user.roles_array[0].entity_uuid;
          user.roles_array[0] = rolesUser;
          await updateOtherUSerInfoRole(user, id);
        }
      }
      await sendEmail(templateEmail);
      showAlert(messages.success, "", Constants.TYPE_ALERT.success, "");
    } catch (err) {
      showAlert(messages.error, "", Constants.TYPE_ALERT.error, "", err);
    }
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  return (
    <>
      <RenderAlert></RenderAlert>
    </>
  )
});
