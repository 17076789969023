import { Constants } from "./constants";
import firebase from 'firebase/app';

export function TransformTemplateViewDetail(templateView, items) {
  let value, title = "";
  let template = [];
  Object.keys(templateView).forEach((key) => {
    title = templateView[key];
    value = items[key];
    template.push({
      title: title,
      value: value ?? "",
    });
  });

  return template;
}

export function filterTypeBarOrTable(type) {
  const typeTableBar = Object.keys(type).filter((res) => type[res] === true)[0];
  return isTypeBarOrTable(typeTableBar);
}

export function isTypeBarOrTable(typeTableBar) {
  let typeTextTableBar = "";
  if (typeTableBar === Constants.TYPE_TABLES_BAR.BAR) {
    typeTextTableBar = "Barra";
  } else if (typeTableBar === Constants.TYPE_TABLES_BAR.TABLE) {
    typeTextTableBar = "Mesa";
  }
  return typeTextTableBar;
}

export function getObjectKeyTrue(element) {
  let key = "";
  Object.keys(element).forEach(res => {
    if (element[res]) key = res;
  })
  return key;
}

export function generatePassword() {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    pass = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    pass += charset.charAt(Math.floor(Math.random() * n));
  }
  return pass;
}

export function castFields(value, type) {
  let castValue;

  const validation = {
    isBool: function (value) {
      return (value === "true" || value === "false" || value === true || value === false) ? true : false;
    },
    isNumber: function (value) {
      return (isNaN(Number(value)) || !value.trim()) ? value : Number(value);
    }
  }

  if (type === "number") {
    castValue = validation.isNumber(value);
  } else if (type === "date") {
    castValue = value;
  } else {
    if (validation.isBool(value)) {
      castValue = (value === "true");
    } else {
      castValue = value;
    }
  }

  return castValue;
}

export function getRolesUser(roles) {
  return roles.map(rolesItem => {
    return Object.keys(rolesItem.role).filter(rol => rolesItem.role[rol])
  }).flat();
}

export function messageCodeErrorsFB(codeError) {
  let msg = "";
  switch (codeError) {
    case Constants.CODE_AUTH_WRONG_PASS:
      msg =
        "El usuario no se encuentra registrado o ingresaste mal tu usuario y/o contraseña. ¡Intenta de nuevo!";
      break;
    case Constants.CODE_AUTH_NOT_FOUND_USER:
      msg =
        "No existe ningún registro de usuario que corresponda al identificador proporcionado.";
      break;
    case Constants.CODE_NOT_FOUND:
      msg =
        "El documento, registro o colección no se encontró en la base de datos.";
      break;
    case Constants.CODE_CANCELLED:
      msg = "Se canceló la operación.";
      break;
    case Constants.CODE_UNKNOWN:
      msg = "Ha ocurrido un error desconocido.";
      break;
    case Constants.CODE_INVALID_ARGUMENT:
      msg = "Se envió un argumento o campo no válido.";
      break;
    case Constants.CODE_DEADLINE_EXCEEDED:
      msg =
        "La fecha límite expiró antes de que se pudiera completar la operación.";
      break;
    case Constants.CODE_ALREADY_EXISTS:
      msg = "Se está creando un documento que ya existe en base de datos";
      break;
    case Constants.CODE_PERMISSION_DENIED:
      msg = "No tienes permisos para ejecutar esta operación.";
      break;
    case Constants.CODE_RESOURCE_EXHAUSTED:
      msg =
        "Se ha agotado algún recurso, tal vez una cuota por usuario, o tal vez todo el sistema de archivos se haya quedado sin espacio.";
      break;
    case Constants.CODE_FAILED_PRECONDITION:
      msg =
        "La operación fue rechazada porque el sistema no está en un estado requerido para la ejecución de la operación.";
      break;
    case Constants.CODE_ABORTED:
      msg = "La operación fue cancelada";
      break;
    case Constants.CODE_OUT_OF_RANGE:
      msg = "Se intentó la operación más allá del rango válido.";
      break;
    case Constants.CODE_UNIMPLEMENTED:
      msg =
        "La operación no está implementada o no es compatible / habilitada.";
      break;
    case Constants.CODE_INTERNAL:
      msg = "Ha ocurrido un error interno del servidor";
      break;
    case Constants.CODE_UNAVAILABLE:
      msg = "El servicio no está disponible en estos momentos.";
      break;
    case Constants.CODE_DATA_LOSS:
      msg = "Pérdida o corrupción de datos irrecuperable.";
      break;
    case Constants.CODE_UNAUTHENTICATED:
      msg =
        "La solicitud no tiene credenciales de autenticación válidas para la operación.";
      break;
    default:
      msg = "ERROR";
  }

  return msg;
}

export const getDefaultRoute = (firstLogin, role) => {
  if (firstLogin) {
    return '/perfil';
  }

  switch (role) {
    case Constants.BAREST:
    case Constants.ADMIN:
      return '/dashboard/overview'

    case Constants.MANAGER:
      return '/mis-establecimientos'

    case Constants.STAFF:
    case Constants.KITCHEN:
      return '/perfil'

    default:
      return '/dashboard/overview'
  }
}

// Date Utils

// format date
export const formatDateString = date => new Intl.DateTimeFormat().format(new Date(date))


// format datetime
export const formatDateTimeString = date => {
  const datetimeCurrent = new Date(date)
  const datetimeFormat = ('0' + datetimeCurrent.getDate()).slice(-2) +
    "-" + ('0' + (datetimeCurrent.getMonth() + 1)).slice(-2) +
    "-" + datetimeCurrent.getFullYear() +
    " / " + ('0' + datetimeCurrent.getHours()).slice(-2) +
    ":" + ('0' + datetimeCurrent.getMinutes()).slice(-2) +
    ":" + ('0' + datetimeCurrent.getSeconds()).slice(-2)

  return datetimeFormat;
}

// format datetime
export const formatDateTimeStringInputDate = date => {
  const datetimeCurrent = new Date(date)
  const datetimeFormat = (datetimeCurrent.getFullYear() +
    "-" + ('0' + (datetimeCurrent.getMonth() + 1)).slice(-2) +
    "-" + ('0' + datetimeCurrent.getDate()).slice(-2)) +
    "T" + ('0' + datetimeCurrent.getHours()).slice(-2) +
    ":" + ('0' + datetimeCurrent.getMinutes()).slice(-2)

  return datetimeFormat;
}

// Secs to Date
export const toDateTime = secs => {
  var t = new Date(secs * 1000);
  return t;
}

// date to date for input value
export const toDateInputValue = date => {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}

// Currency Utils
export const currencyString = number => new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(parseFloat(number))

export const getPreviousDay = (date = new Date()) => {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);
  return previous;
}

export const getDateToFirebase = (date) => {
  const dateFirebase = new Date(date);
  return firebase.firestore.Timestamp.fromDate(dateFirebase);
}

export const formatValidateDate = (dateString) => {
  return dateString.split("-").join("/");
} 