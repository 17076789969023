import React, { Suspense } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import BranchsData from "./BranchData"
import InlineLoader from "../../components/InlineLoader"

export default () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Establecimientos</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Establecimientos</h4>
          <p className="mb-0">Establecimientos registrados</p>
        </div>
      </div>

      <Suspense fallback={<InlineLoader />}>
        <BranchsData />
      </Suspense>
    </>
  );
};
