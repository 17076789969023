import React, { useState, useEffect, useImperativeHandle, forwardRef, useContext, useRef, useCallback } from "react";
import { Col, Row, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import 'firebase/firestore';
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import Orderitems from "../../components/OrderItems";
import SubCategory from "./models/SubCategoryModel";
import useAlert from "../../utils/hooks/useAlert";
import useModal from "../../utils/hooks/useModal";
import { appContext } from "../../state/context";
import { castFields } from "../../utils/utils";

export const SubCategoryAddUpdate = forwardRef(({ addSubCategories, currentBranch, currentCategory, isSaveCategory, subCategories, hidePanelSubCategorie }, ref) => {
  const { currentEnterprise } = useContext(appContext)
  const { handleShowAlert, RenderAlert } = useAlert();
  const { handleShow, RenderModal } = useModal();
  const [modal, setModal] = useState({});
  const [isSave, setIsSave] = useState(true);
  const [editOrder, setEditOrder] = useState(false);
  const [subCategory, setSubCategory] = useState(new SubCategory());
  const [subCategoriesOrder, setSubCategoriesOrder] = useState([]);
  const db = useFirestore();
  const queryCategories = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid)
    .collection(Constants.BRANCHS).doc(currentBranch)
    .collection(Constants.CATEGORIES);
  const querySubCategory = queryCategories.doc(currentCategory).collection(Constants.SUBCATEGORIES);
  const queryCategoriesLastItem = querySubCategory.orderBy("position_order", "desc");
  const { data: subCategoriesLastItemDataObserver } = useFirestoreCollectionData(queryCategoriesLastItem);
  const childPassRef = useRef();

  const modelItems = {
    id: "position_order",
    name: "name"
  }

  useEffect(() => {
    if (!editOrder)
      setSubCategory(prev => ({ ...prev, position_order: subCategoriesLastItemDataObserver.length > 0 ? subCategoriesLastItemDataObserver[0].position_order + 1 : 0 }))
  }, [subCategoriesLastItemDataObserver, editOrder]);

  useEffect(() => {
    if (isSave)
      setSubCategory(prev => ({ ...prev, position_order: subCategories.length }));
    setSubCategoriesOrder(subCategories);
  }, [subCategories, isSave]);

  useImperativeHandle(ref, () => ({
    addOrUpdateSubCategory(isUpdate, subCategoryItem) {
      if (isUpdate) {
        setIsSave(false);
        setSubCategory(subCategoryItem);
      }
      else {
        clearForm();
        setIsSave(true);
      }
    }
  }));

  const openOrderItems = useCallback(() => {
    setEditOrder(true);
    childPassRef.current.handleShowModal();
  }, [childPassRef]);

  const handleChangeForm = e => {
    const { name, value, type } = e.target;
    setSubCategory(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const isSubCategoryName = (event) => {
    const name = event.target.value;
    const isCategoryTemp = subCategoriesOrder.filter(res => res.name === name);
    if (isCategoryTemp.length > 0) {
      showAlert(`Ya existe una sub categoría con el nombre ${name}`, "", Constants.TYPE_ALERT.success, "");
      setSubCategory(prevState => ({ ...prevState, name: "" }));
      return;
    }

    queryCategories.doc(currentCategory)
      .collection(Constants.SUBCATEGORIES).where("name", "==", name).get().then(querySnapshot => {
        if (querySnapshot.docs.length > 0) {
          showAlert(`Ya existe una sub categoría con el nombre ${name}`, "", Constants.TYPE_ALERT.success, "");
          setSubCategory(prevState => ({ ...prevState, name: "" }));
        }
      }).catch((error) => {
        showAlert(`Ocurrió un error al traer la sub categoría ${name}.`, "", Constants.TYPE_ALERT.error, "", error);
      })
  }

  const addUpdateSubCategory = (e) => {
    e.preventDefault();
    setEditOrder(false);
    const subCategoryModelFB = {
      name: subCategory.name,
      position_order: subCategory.position_order,
      is_enabled: subCategory.is_enabled,
      is_available_in_bar: subCategory.is_available_in_bar,
      branch_id: currentBranch,
      category_id: currentCategory
    }
    isSave ? addSubCategory(subCategoryModelFB) : updateSubCategory(subCategoryModelFB);
  }

  const addSubCategory = async (subCategoryModel) => {
    if (!isSaveCategory) {
      await updateOrderSubCategories();
      queryCategories
        .doc(currentCategory)
        .collection(Constants.SUBCATEGORIES)
        .add(subCategoryModel).then(() => {
          clearForm();
          showAlert(`sub categoría ${subCategory.name} creada correctamente`, "", Constants.TYPE_ALERT.success, "");
        }).catch((error) => {
          showAlert(`Ocurrió un error al guardar la sub categoría.`, "", Constants.TYPE_ALERT.error, "", error);
        })
    } else {
      let itemsSubCategories = subCategoriesOrder;
      if (!editOrder) itemsSubCategories.push(subCategoryModel);
      setSubCategoriesOrder(itemsSubCategories);
      addSubCategories(itemsSubCategories);
      clearForm();
    }
  }

  const updateSubCategory = async (subCategoryModel) => {
    await updateOrderSubCategories();
    queryCategories
      .doc(currentCategory)
      .collection(Constants.SUBCATEGORIES)
      .doc(subCategory.id).update(subCategoryModel).then(() => {
        clearForm();
        hidePanelSubCategorie();
        showAlert(`Sub categoría ${subCategory.name} actualizada correctamente`, "", Constants.TYPE_ALERT.success, "");
      }).catch((error) => {
        showAlert(`Ocurrió un error al actualizar la sub categoría.`, "", Constants.TYPE_ALERT.error, "", error);
      });
  }

  const updateOrderSubCategories = async () => {
    if (editOrder) {
      const batch = db.batch();
      subCategoriesOrder.forEach((res, index) => {
        if (res.id && res.name !== subCategory.name) {
          let itemUpdate = querySubCategory.doc(res.id);
          batch.update(itemUpdate, { "position_order": index });
        }
      })
      await batch.commit().then().catch();
    }
  }

  const removeSubCategory = () => {
    queryCategories
      .doc(currentCategory)
      .collection(Constants.SUBCATEGORIES)
      .doc(subCategory.id).delete().then(() => {
        clearForm();
        hidePanelSubCategorie();
        showAlert(`Sub categoría ${subCategory.name} eliminada correctamente`, "", Constants.TYPE_ALERT.success, "");
      }).catch((error) => {
        showAlert(`Ocurrió un error al eliminar la sub categoría.`, "", Constants.TYPE_ALERT.error, "", error);
      });
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const orderCategories = (subCategoriesItems) => {
    setSubCategory(prevState => ({ ...prevState, position_order: subCategoriesItems.findIndex(res => res.name === subCategory.name) }));
    setSubCategoriesOrder(subCategoriesItems);
  }

  const clearForm = () => {
    setIsSave(true);
    setSubCategory(new SubCategory("", subCategories.length, true, true, "", ""));
  }

  const confirmRemove = () => {
    setModal(new AlertModal(
      `¿Seguro deseas eliminar la subcategoría ${subCategory.name}?`,
      "Eliminar subcategoría",
      Constants.TYPE_ALERT.warning,
      Constants.TYPE_MODAL.confirm
    ));
    handleShow();
  }

  return (
    <section>
      <RenderAlert></RenderAlert>
      <RenderModal content={modal} confirm={removeSubCategory}></RenderModal>
      <Orderitems items={isSave ? (editOrder ? [...subCategoriesOrder, subCategory] : [subCategories, subCategory]) : subCategories} modelItems={modelItems} saveOrderItems={orderCategories} ref={childPassRef} />
      <h5 className="font-weight-bold d-block mb-4 mt-3">Crear sub categoría</h5>
      <Form onSubmit={addUpdateSubCategory}>
        <Row className="justify-content-center form-bg-image">
          <Col xs={12} md={6}>
            <Form.Group id="name" className="mb-4">
              <Form.Label>Nombre*</Form.Label>
              <InputGroup>
                <Form.Control value={subCategory.name} type="text" name="name" onBlur={isSubCategoryName} onChange={handleChangeForm} required />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group id="postionOrder" className="mb-4">
              <Form.Label>Orden de visualización*</Form.Label>
              <div className="d-flex align-items-center">
                <InputGroup className="pe-2">
                  <Form.Control value={subCategory.position_order} type="number" name="postionOrder" readOnly onChange={handleChangeForm} required />
                </InputGroup>
                <Button variant="secondary" type="button" className="ml-2" onClick={openOrderItems} disabled={subCategory.name ? false : true}>Ordenar</Button>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group id="is_enabled" className="mb-4">
              <Form.Label>¿Habilitada?*</Form.Label>
              <Form.Select value={subCategory.is_enabled} name="is_enabled" onChange={handleChangeForm} required>
                <option value={true}>Si</option>
                <option value={false}>No</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group id="is_available_in_bar" className="mb-4">
              <Form.Label>¿Habilitada para bar?*</Form.Label>
              <Form.Select value={subCategory.is_available_in_bar} name="is_available_in_bar" onChange={handleChangeForm} required>
                <option value={true}>Si</option>
                <option value={false}>No</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <div className="text-right mt-4 d-flex justify-content-between">
          {(isSave || !subCategory.id) ?
            <div></div> : <Button variant="danger" onClick={confirmRemove}>Eliminar</Button>
          }
          <div>
            <Button variant="link" className="text-gray ms-auto" onClick={clearForm}>Limpiar</Button>
            <Button variant="primary" type="submit">
              {isSave ? "Guardar" : "Actualizar"}
            </Button>
          </div>
        </div>
      </Form>
    </section>
  )
});