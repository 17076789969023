
import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import { useFirestore } from 'reactfire';
import 'firebase/firestore';
import { AlertModal } from "../../utils/models/AlertModal";
import Branch from './models/BranchsModel';
import { Constants } from "../../utils/constants";
import { countrys } from "../../utils/models/Lists";
import Preloader from "../../components/Preloader";
import { TippingInfo } from "./models/TippingInfo";
import useAlert from "../../utils/hooks/useAlert";
import { appContext } from "../../state/context";
import { castFields } from "../../utils/utils";


export default () => {
  const { user, currentEnterprise } = useContext(appContext)
  const [loaded, setLoaded] = useState(false);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [branch, setBranch] = useState(new Branch());
  const [tipping, setTipping] = useState(new TippingInfo());
  const [branchs, setBranchs] = useState([]);
  const [currencys, setCurrencys] = useState([]);
  const db = useFirestore();
  const managerEmail = user?.email;
  const queryBranch = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid).collection(Constants.BRANCHS);

  useEffect(() => {
    getBranchs();
    getCurrencys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeForm = e => {
    const { name, value, type } = e.target;
    setBranch(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const handleChangeFormTipping = e => {
    const { name, value, type } = e.target;
    setTipping(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const getCurrencys = () => {
    setLoaded(true);
    db.collection(Constants.CURRENCYS).get().then(query => {
      setLoaded(false);
      query.forEach((doc) => {
        setCurrencys(prev => [...prev, { ...doc.data() }])
      })
    })
  }

  const getBranchs = () => {
    setLoaded(true);
    queryBranch.where("manager_email", "==", managerEmail).get().then(query => {
      setLoaded(false);
      query.forEach((doc) => {
        setBranchs(prev => [...prev, { ...doc.data(), id: doc.id }])
      })
      if (query.docs.length > 0) {
        const data = query.docs[0];
        selectedBranchFormat({ ...data.data(), id: data.id });
      }

    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al traer tus establecimiento asignados.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const updateBranch = (e) => {
    e.preventDefault();
    const branchModel = {
      name: branch.name,
      address: branch.address,
      country: branch.country,
      city: branch.city,
      phone_number: branch.phone_number,
      lat_lon_georeference: branch.latitud_georeference + ", " + branch.longitud_georeference,
      currency: db.doc(`/${Constants.CURRENCYS}/${branch.currency}`),
      status: branch.status,
      tipping_info: JSON.parse(JSON.stringify(tipping))
    }

    setLoaded(true);
    queryBranch.doc(branch.id).update(branchModel).then(() => {
      setLoaded(false);
      showAlert(`Establecimiento ${branchModel.name} actualizado correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al actualizar el establecimiento.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const changeBranch = (id) => {
    let branchSelected = branchs.find(res => res.id === id.trim());
    selectedBranchFormat(branchSelected);
  }

  const selectedBranchFormat = async (branchSelected) => {
    const currencyBranch = await branchSelected.currency.get();
    setTipping(branchSelected.tipping_info ?? new TippingInfo());
    branchSelected.latitud_georeference = branchSelected.lat_lon_georeference.split(",")[0].trim() ?? 0;
    branchSelected.longitud_georeference = branchSelected.lat_lon_georeference.split(",")[1].trim() ?? 0;
    branchSelected.currency = currencyBranch.data()?.code;
    setBranch(branchSelected);
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <Card.Body>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h4>Mis establecimientos</h4>
            <p className="mb-0">Puedes editar la información de tus establecimientos asignados</p>
          </div>
        </div>
        <div className="table-settings mb-1">
          <Row className="align-items-center">
            <Col xs={12} md={6}>
              <Form.Group className="mb-2">
                <Form.Label>Buscar establecimiento:</Form.Label>
                <Form.Select id="branch" onChange={(event) => changeBranch(event.target.value)}>
                  {
                    branchs.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
                  }
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <hr></hr>
        {
          branch.name !== '' ?
            <Form className="mt-4" onSubmit={updateBranch}>
              <Row className="justify-content-center form-bg-image">
                <Col md={12}>
                  <Form.Group id="name" className="mb-4">
                    <Form.Label>Nombre establecimiento*</Form.Label>
                    <InputGroup>
                      <Form.Control value={branch.name} type="text" name="name" onChange={handleChangeForm} required />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group id="country" className="mb-4">
                    <Form.Label>País*</Form.Label>
                    <Form.Select value={branch.country} name="country" onChange={handleChangeForm} required>
                      {
                        countrys.map((item, i) => <option key={i} value={item}>{item}</option>)
                      }
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group id="city" className="mb-4">
                    <Form.Label>Ciudad*</Form.Label>
                    <InputGroup>
                      <Form.Control value={branch.city} type="text" name="city" onChange={handleChangeForm} required />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group id="address" className="mb-4">
                    <Form.Label>Dirección*</Form.Label>
                    <InputGroup>
                      <Form.Control value={branch.address} type="text" name="address" onChange={handleChangeForm} required />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group id="phone_number" className="mb-4">
                    <Form.Label>Número telefónico*</Form.Label>
                    <InputGroup>
                      <Form.Control value={branch.phone_number} type="number" name="phone_number" onChange={handleChangeForm} required />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group id="currency" className="mb-4">
                    <Form.Label>Moneda*</Form.Label>
                    <Form.Select value={branch.currency} name="currency" onChange={handleChangeForm} required>
                      {
                        currencys.map((item, i) => <option key={i} value={`${item.code}`}>{item.code}-{item.symbol}</option>)
                      }
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group id="latitud_georeference" className="mb-4">
                    <Form.Label>Latitud*</Form.Label>
                    <InputGroup>
                      <Form.Control value={branch.latitud_georeference} type="text" name="latitud_georeference" onChange={handleChangeForm} required />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group id="longitud_georeference" className="mb-4">
                    <Form.Label>Longitud*</Form.Label>
                    <InputGroup>
                      <Form.Control value={branch.longitud_georeference} type="text" name="longitud_georeference" onChange={handleChangeForm} required />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Form.Label>Propinas<small>(Puedes ingresar 3 opciones de propina)*</small></Form.Label>
                <Col xs={6} md={4}>
                  <Form.Group id="level_one" className="mb-4">
                    <InputGroup className="d-flex align-items-center">
                      <Form.Control value={tipping.level_one} type="number" name="level_one" onChange={handleChangeFormTipping} max="100" min="0" required />%
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                  <Form.Group id="level_two" className="mb-4">
                    <InputGroup className="d-flex align-items-center">
                      <Form.Control value={tipping.level_two} type="number" name="level_two" onChange={handleChangeFormTipping} max="100" min="0" required />%
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                  <Form.Group id="level_three" className="mb-4">
                    <InputGroup className="d-flex align-items-center">
                      <Form.Control value={tipping.level_three} type="number" name="level_three" onChange={handleChangeFormTipping} max="100" min="0" required />%
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-right mt-4">
                <Button variant="primary" type="submit">
                  Actualizar
                </Button>
              </div>
            </Form> : ""
        }
      </Card.Body>
    </Card>
  );
};
