import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef, useCallback, useContext, Fragment } from "react";
import { Col, Row, Form, Button, Container, InputGroup, Modal, Collapse } from '@themesberg/react-bootstrap';
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFirestore, useFirebaseApp } from 'reactfire';
import 'firebase/firestore';
import { AlertModal } from "../../utils/models/AlertModal";
import Branch, { PaymentsAccounts } from './models/BranchsModel';
import { Constants } from "../../utils/constants";
import { countrys } from "../../utils/models/Lists";
import { appContext } from "../../state/context"
import Preloader from "../../components/Preloader";
import { TippingInfo } from "./models/TippingInfo";
import useAlert from "../../utils/hooks/useAlert";
import { CreateNewUserAuth } from "../../components/CreateNewUser";
import { TemplateEmail } from "../../utils/models/TemplateEmail";
import { User } from "../../utils/models/User";
import { castFields } from "../../utils/utils";
import useModal from "../../utils/hooks/useModal";
import RecoverPassword from "../../components/RecoverPassword";
import { getRolesUser } from "../../utils/utils";

export const BranchAddUpdate = forwardRef(({ branchs }, ref) => {
  const { user } = useContext(appContext);
  const { currentEnterprise } = useContext(appContext);
  const [openCopyCatalog, setOpenCopyCatalog] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [isSave, setIsSave] = useState(true);
  const [lastEmailManager, setLastEmailManager] = useState("");
  const { handleShow, RenderModal } = useModal();
  const [modal, setModal] = useState({});
  const [showModalBranch, setShowModalBranch] = useState(false);
  const [branch, setBranch] = useState(new Branch());
  const [paymentsAccount, setPaymentsAccount] = useState(new PaymentsAccounts());
  const [tipping, setTipping] = useState(new TippingInfo());
  const [currencys, setCurrencys] = useState([]);
  const db = useFirestore();
  const firebase = useFirebaseApp();
  const enterpriseID = currentEnterprise?.uid;
  const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(enterpriseID).collection(Constants.BRANCHS);
  const childPassRef = useRef();
  const [branchSelected, setBranchSelected] = useState("");

  const userRoles = user?.roles_array ? getRolesUser(user.roles_array) : [];
  const isBarest = userRoles.includes(Constants.BAREST);

  const messages = {
    success: "Se ha enviado un correo al administrador del estalecimiento",
    error: "No se pudo crear y enviar el email al administrador del estalecimiento"
  }

  useEffect(() => {
    getCurrencys();
    console.log(branchs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    addOrUpdateBranch(isUpdate, branchItem) {
      if (isUpdate) {
        setIsSave(false);
        setLastEmailManager(branchItem.manager_email);
        setBranch(branchItem);
        setTipping(branchItem.tipping_info);
        setPaymentsAccount(branchItem.payments_accounts);
        setBranchSelected(branchs.length > 1 ? branchs.filter(res => res.id !== branchItem?.id)[0].id : "");
      }
      else {
        clearForm();
        setBranch(prevState => ({ ...prevState, currency: currencys[0].code }))
        setIsSave(true);
      }
      handleShowModalBranch();
    }
  }));

  const handleHideModalBranch = () => setShowModalBranch(false);
  const handleShowModalBranch = () => { setShowModalBranch(true) }

  const handleChangeForm = e => {
    const { name, value, type } = e.target;
    setBranch(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const handleChangeFormTipping = e => {
    const { name, value, type } = e.target;
    setTipping(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const handlePaymentsAccount = e => {
    const { name, value, type } = e.target;
    setPaymentsAccount(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const getCurrencys = () => {
    db.collection(Constants.CURRENCYS).get().then(query => {
      query.forEach((doc) => {
        setCurrencys(prev => [...prev, { ...doc.data() }])
      })
    })
  }

  const addUpdateBranch = (e) => {
    e.preventDefault();
    const branchModelFB = {
      id: isSave ? db.collection(Constants.BRANCHS).doc().id : branch.id,
      name: branch.name,
      address: branch.address,
      country: branch.country,
      city: branch.city,
      phone_number: branch.phone_number,
      lat_lon_georeference: branch.latitud_georeference + ", " + branch.longitud_georeference,
      manager_email: branch.manager_email,
      currency: db.doc(`/${Constants.CURRENCYS}/${branch.currency}`),
      status: branch.status,
      payments_accounts: { stripe: paymentsAccount?.stripe },
      tipping_info: JSON.parse(JSON.stringify(tipping)),
      percentage_service_barest: branch.percentage_service_barest ?? Constants.PERCENTAGE_SERVICES_BAREST,
      id_tablet: branch.id_tablet ?? "",
      opening_time: branch.opening_time,
      closing_time: branch.closing_time
    }

    isSave ? addBranch(branchModelFB) : updateBranch(branchModelFB);
  }

  const addBranch = (branchModel) => {
    setLoaded(true);
    queryEnterprise.doc(branchModel.id).set(branchModel).then(async () => {
      await addNewUserAuth(branchModel.id);
      setLoaded(false);
      clearForm();
      handleHideModalBranch();
      showAlert(`Establecimiento ${branchModel.name} creado correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al guardar el establecimiento.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const updateBranch = (branchModel) => {
    setLoaded(true);
    queryEnterprise.doc(branch.id).update(branchModel).then(async () => {
      if (lastEmailManager !== branch.manager_email)
        await addNewUserAuth(branchModel.id);
      setLoaded(false);
      clearForm();
      handleHideModalBranch();
      showAlert(`Establecimiento ${branchModel.name} actualizado correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al actualizar el establecimiento.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const userSaved = (user) => { console.log(user) }

  const addNewUserAuth = useCallback(async (idBranch) => {
    const ischeckedEmail = await firebase.auth().fetchSignInMethodsForEmail(branch.manager_email);
    const params = {
      EMAIL: branch.manager_email,
      BRANCH: branch.name,
      ROL: "administrador",
      URL: process.env.REACT_APP_URL_BAREST
    }
    const templateEmail = new TemplateEmail(
      branch.manager_email,
      "Nuevo administrador",
      ischeckedEmail.length ? Constants.NEW_ASSIGN_BRANCH : Constants.NEW_USER,
      params
    )
    let user = new User();
    user.roles_array.push(Constants.ROLES);
    user.roles_array[0]["role"]["MANAGER"] = true;
    user.email = branch.manager_email;
    user = JSON.parse(JSON.stringify(user));
    user.roles_array[0]["entity_uuid"] = db.doc(`/${Constants.ENTERPRISE}/${enterpriseID}`);
    user.roles_array[0]["branch_uuid"] = [idBranch];
    childPassRef.current.addOrUpdateNewUser(user, templateEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childPassRef, branch]);

  const acceptCopyCatalog = async => {
    setModal(
      new AlertModal(
        `¿Seguro desea copiar el catálogo del establecimiento seleccionado?`,
        "Copiar catálogo",
        Constants.TYPE_ALERT.warning,
        Constants.TYPE_MODAL.confirm
      )
    );
    handleShow();
  }

  const copyCatalog = async () => {
    const currentBranchFromCopy = branchs.find(item => item.id === branchSelected);
    let batch = db.batch();
    let categoriesFrom = [];
    try {

      setLoaded(true);
      const categoriesToQuery = queryEnterprise.doc(branch.id).collection(Constants.CATEGORIES);
      const categoriesQueryFrom = queryEnterprise.doc(branchSelected).collection(Constants.CATEGORIES);
      const productsQueryFrom = db.collection(Constants.PRODUCT).where("branch_id", "==", branchSelected);

      const categoriesDocFrom = await categoriesQueryFrom.get();
      categoriesDocFrom.forEach((doc) => {
        const data = doc.data();
        const categoryCurrent = {
          UID_branch_document_reference: db.doc(`/${Constants.ENTERPRISE}/${currentEnterprise?.uid}/${Constants.BRANCHS}/${branch.id}`),
          name: data.name,
          position_order: data.position_order,
          is_enabled: data.is_enabled,
          is_available_in_bar: data.is_available_in_bar,
          branch_id: branch.id
        }

        const docRef = categoriesToQuery.doc(doc.id);
        batch.set(docRef, categoryCurrent);
      });

      await batch.commit();

      batch = db.batch();

      const categoriesFromDoc = await categoriesQueryFrom.get();
      categoriesFromDoc.forEach((doc) => {
        categoriesFrom.push({ ...doc.data(), id: doc.id });
      });

      await Promise.all(categoriesFrom.map(async (res) => {
        const subCategoriesToQuery = db.collectionGroup(Constants.SUBCATEGORIES).where("category_id", "==", res.id);
        const subCategoriesToDoc = await subCategoriesToQuery.get();
        subCategoriesToDoc.forEach((doc) => {
          const data = doc.data();
          const subCategoryCurrent = {
            name: data.name,
            position_order: data.position_order,
            is_enabled: data.is_enabled,
            is_available_in_bar: data.is_available_in_bar,
            branch_id: branch.id,
            category_id: res.id
          }

          const docRef = categoriesToQuery.doc(res.id).collection(Constants.SUBCATEGORIES).doc(doc.id);
          batch.set(docRef, subCategoryCurrent);
        });
      }));
      await batch.commit();

      batch = db.batch();

      const productsDocFrom = await productsQueryFrom.get();
      productsDocFrom.forEach((doc) => {
        const data = doc.data();
        let ref = data.UID_subcategory_document_reference.path.replace(branchSelected, branch.id);
        const productCurrent = {
          UID_subcategory_document_reference: db.doc(ref),
          sku: data.sku,
          name: data.name,
          description: data.description,
          price: data.price,
          position_order: data.position_order,
          is_enabled: data.is_enabled,
          is_available_in_bar: data.is_available_in_bar,
          require_kitchen: data.require_kitchen,
          timer_cook: data.require_kitchen ? data.timer_cook : null,
          allergens: data.allergens,
          branch_id: branch.id,
          sub_category_id: data.sub_category_id,
          image_url: data.image_url ?? ""
        }

        const docRef = db.collection(Constants.PRODUCT).doc();
        batch.set(docRef, productCurrent);
      });

      await batch.commit();


      setLoaded(false);
      showAlert(`Se ha copiado el catálogo del establecimiento ${currentBranchFromCopy.name} correctamente`, "", Constants.TYPE_ALERT.success, "");

    } catch (e) {
      console.log(e);
      setLoaded(false);
      showAlert(`Ocurrió un error al copiar el catálogo.`, "", Constants.TYPE_ALERT.error, "", e);
    }
  }

  const clearForm = () => {
    setBranch(new Branch());
    setTipping(new TippingInfo());
    setPaymentsAccount(new PaymentsAccounts())
    setIsSave(true);
    setLastEmailManager("");
  }

  return (
    <section>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <RenderModal content={modal} confirm={copyCatalog}></RenderModal>
      <CreateNewUserAuth userSaved={userSaved} messages={messages} ref={childPassRef} />
      <Modal size="lg"
        backdrop="static"
        keyboard={false}
        as={Modal.Dialog} centered show={showModalBranch}>
        <Modal.Header className="m-success">
          <Modal.Title className="h6 text-white">CREAR ESTABLECIMIENTO</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleHideModalBranch} />
        </Modal.Header>
        <Modal.Body>
          <section className="d-flex align-items-center">
            <Container>
              <div className="bg-white w-100 fmxw-500 m-auto">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">Completa todos los campos requeridos</h5>
                </div>
              </div>
              <Form className="mt-4" onSubmit={addUpdateBranch}>
                <Row className="justify-content-center form-bg-image">
                  <Col md={12}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Nombre establecimiento*</Form.Label>
                      <InputGroup>
                        <Form.Control value={branch.name} type="text" name="name" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="country" className="mb-4">
                      <Form.Label>País*</Form.Label>
                      <Form.Select value={branch.country} name="country" onChange={handleChangeForm} required>
                        {
                          countrys.map((item, i) => <option key={i} value={item}>{item}</option>)
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="city" className="mb-4">
                      <Form.Label>Ciudad*</Form.Label>
                      <InputGroup>
                        <Form.Control value={branch.city} type="text" name="city" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="address" className="mb-4">
                      <Form.Label>Dirección*</Form.Label>
                      <InputGroup>
                        <Form.Control value={branch.address} type="text" name="address" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="phone_number" className="mb-4">
                      <Form.Label>Número telefónico*</Form.Label>
                      <InputGroup>
                        <Form.Control value={branch.phone_number} type="number" name="phone_number" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="currency" className="mb-4">
                      <Form.Label>Moneda*</Form.Label>
                      <Form.Select value={branch.currency} name="currency" onChange={handleChangeForm} required>
                        {
                          currencys.map((item, i) => <option key={i} value={`${item.code}`}>{item.code}-{item.symbol}</option>)
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={!isSave ? 8 : 12}>
                    <Form.Group id="manager_email" className="mb-4">
                      <Form.Label>Email administrador*</Form.Label>
                      <InputGroup>
                        <Form.Control value={branch.manager_email} type="text" name="manager_email" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  {!isSave ? <Col md={4}>
                    <RecoverPassword email={branch.manager_email}></RecoverPassword>
                  </Col> : ""}
                </Row>

                <Row className="align-items-center">
                  <Col md={6} sm={12}>
                    <Form.Group id="opening_time" className="mb-4">
                      <Form.Label>Horario apertura*</Form.Label>
                      <InputGroup>
                        <Form.Control value={branch.opening_time} type="time" name="opening_time" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group id="closing_time" className="mb-4">
                      <Form.Label>Horario cierre*</Form.Label>
                      <InputGroup>
                        <Form.Control value={branch.closing_time} type="time" name="closing_time" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="latitud_georeference" className="mb-4">
                      <Form.Label>Latitud*</Form.Label>
                      <InputGroup>
                        <Form.Control value={branch.latitud_georeference} type="text" name="latitud_georeference" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="longitud_georeference" className="mb-4">
                      <Form.Label>Longitud*</Form.Label>
                      <InputGroup>
                        <Form.Control value={branch.longitud_georeference} type="text" name="longitud_georeference" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group id="stripe" className="mb-4">
                      <Form.Label>Stripe ID*</Form.Label>
                      <InputGroup>
                        <Form.Control value={paymentsAccount?.stripe} type="text" name="stripe" onChange={handlePaymentsAccount} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Form.Label>Propinas<small>(Puedes ingresar 3 opciones de propina)*</small></Form.Label>
                  <Col xs={6} md={4}>
                    <Form.Group id="level_one" className="mb-4">
                      <InputGroup className="d-flex align-items-center">
                        <Form.Control value={tipping?.level_one} type="number" name="level_one" onChange={handleChangeFormTipping} max="100" min="0" required step="any" />%
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Group id="level_two" className="mb-4">
                      <InputGroup className="d-flex align-items-center">
                        <Form.Control value={tipping?.level_two} type="number" name="level_two" onChange={handleChangeFormTipping} max="100" min="0" required step="any" />%
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Group id="level_three" className="mb-4">
                      <InputGroup className="d-flex align-items-center">
                        <Form.Control value={tipping?.level_three} type="number" name="level_three" onChange={handleChangeFormTipping} max="100" min="0" required step="any" />%
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Label className="mt-3">ID Tablet <small>(El id lo encuentras en la parte superior del menú en la app de staff desde la tablet que quieres dejar como predeterminada)</small></Form.Label>
                    <Form.Group id="id_tablet" className="mb-4">
                      <InputGroup>
                        <Form.Control value={branch.id_tablet} type="text" name="id_tablet" onChange={handleChangeForm} />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                {
                  isBarest ?
                    <Fragment>
                      <h6 className="bold mt-2 mb-4 d-block">
                        Gastos de servicio barest
                      </h6>
                      <Row>
                        <Col xs={12} md={6}>
                          <Form.Label className="mt-3">Porcentaje servicio barest</Form.Label>
                          <Form.Group id="percentage_service_barest" className="mt-2">
                            <InputGroup className="d-flex align-items-center">
                              <Form.Control value={branch?.percentage_service_barest} type="number" name="percentage_service_barest" onChange={handleChangeForm} max="100" min="0" step="any" />%
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Fragment> : null
                }

                <div className="text-right mt-6">
                  <Button variant="link" className="text-gray ms-auto" onClick={clearForm}>Limpiar</Button>
                  <Button variant="primary" type="submit">
                    {isSave ? "Guardar" : "Actualizar"}
                  </Button>
                </div>

                {
                  !isSave ?
                    <div className="my-4">
                      <hr></hr>
                      <h6 className="bold mt-2 mb-4 d-block">
                        ¿Deseas copiar el catálogo de otro establecimiento? <FontAwesomeIcon onClick={() => setOpenCopyCatalog(!openCopyCatalog)} icon={openCopyCatalog ? faMinus : faPlus} className={`mx-1 ${openCopyCatalog ? "text-danger" : "text-soft-green"} c-pointer`} />
                      </h6>
                      <Collapse in={openCopyCatalog}>
                        <Row>
                          <Col xs={12} md={8}>
                            <Form.Group id="branch-catalog">
                              <Form.Label>Establecimiento</Form.Label>
                              <Form.Select value={branchSelected} name="branch-selected" onChange={(event) => setBranchSelected(event.target.value)}>
                                {
                                  branchs.map((item, i) => item.id !== branch.id ? <option key={i} value={`${item.id}`}>{item.name}</option> : null)
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4} className="d-flex align-items-end">
                            <Button variant="primary" onClick={acceptCopyCatalog}>Copiar catálogo</Button>
                          </Col>
                        </Row>
                      </Collapse>
                    </div> : ""
                }
              </Form>
            </Container>
          </section>
        </Modal.Body>
      </Modal>
    </section>
  )
});