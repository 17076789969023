import React, { useState, useRef, useCallback, useEffect, useContext, useMemo } from "react";
import { Col, Row, Form } from '@themesberg/react-bootstrap';
import "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import DataTable from "../../components/dataTable";
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import { CategoryAddUpdate } from "./CategoryAddUpdate";
import useAlert from "../../utils/hooks/useAlert";
import Preloader from "../../components/Preloader";
import { tableConfig } from './models/tableConfig'
import { getRolesUser, TransformTemplateViewDetail } from "../../utils/utils";
import { ViewDetailItemModel } from "../../components/models/ViewDetailItemModel";
import { appContext } from "../../state/context";

const CategoryData = () => {
  const { user, currentEnterprise } = useContext(appContext)
  const [loaded, setLoaded] = useState(false);
  const db = useFirestore();
  const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid);
  const branchesQuery = queryEnterprise.collection(Constants.BRANCHS);
  const [docCurrentBranch, setDocCurrentBranch] = useState("0");
  const [branchs, setBranchs] = useState([]);
  const [categories, setCategories] = useState([]);
  const { handleShowAlert, RenderAlert } = useAlert();
  const childAddCategoryPassRef = useRef();
  const categoriesQuery = branchesQuery.doc(docCurrentBranch).collection(Constants.CATEGORIES);
  const { data: categoriesDataObserver } = useFirestoreCollectionData(categoriesQuery.orderBy("position_order"));
  const [viewDetail, setViewDetail] = useState();

  const userRoles = user?.roles_array ? getRolesUser(user.roles_array) : [];
  const isBarest = userRoles.includes(Constants.BAREST) || userRoles.includes(Constants.ADMIN);

  const branchesQuerySearch = useMemo(() => {
    return isBarest ? queryEnterprise.collection(Constants.BRANCHS) : queryEnterprise.collection(Constants.BRANCHS).where("manager_email", "==", user.email)
  }, [isBarest, user, queryEnterprise]);

  useEffect(() => {
    setCategories(categoriesDataObserver.map(item => (
      {
        ...item, id: item['NO_ID_FIELD'],
        enabled: item.is_enabled ? "Si" : "No",
        available_bar: item.is_available_in_bar ? "Si" : "No"
      }
    )))
  }, [categoriesDataObserver])

  useEffect(() => {
    getBranchs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEnterprise])

  const titlesView = {
    name: "Nombre Categoría",
    position_order: "Posición",
    is_enabled: "¿Habilitada?",
    is_available_in_bar: "¿Habilitada en barra?",
    sub_categories: "Sub Categorías"
  };

  const openAddUpdateCategories = useCallback((isUpdate, branch) => {
    childAddCategoryPassRef.current.addOrUpdateCategory(isUpdate, branch);
  }, [childAddCategoryPassRef]);

  const getBranchs = () => {
    setBranchs([]);
    setLoaded(true);
    branchesQuerySearch.get().then(query => {
      setLoaded(false);
      query.forEach((doc) => {
        setBranchs(prevState => [...prevState, { ...doc.data(), id: doc.id }])
      })
      if (query.docs.length > 0)
        setDocCurrentBranch(query.docs[0].id);

    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al traer tus establecimiento asignados.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const getSubCategories = async (item) => {
    return await categoriesQuery.doc(item.id)
      .collection(Constants.SUBCATEGORIES)
      .get();
  }

  const editCategory = async (item) => {
    openAddUpdateCategories(true, item);
  };

  const viewCategory = async (item) => {
    let itemTemp = { ...item };
    let subCategories = [];
    const subCategTemp = await getSubCategories(item) ?? [];
    subCategTemp.forEach((doc) => {
      subCategories.push(doc.data()?.name);
    })
    itemTemp.is_available_in_bar = itemTemp.is_available_in_bar ? "Si" : "No";
    itemTemp.is_enabled = itemTemp.is_enabled ? "Si" : "No";
    itemTemp.sub_categories = subCategories;
    let viewDetailtCategory = TransformTemplateViewDetail(titlesView, itemTemp);
    setViewDetail(
      new ViewDetailItemModel(
        `Categoría ${item.name}`,
        `Datos del categoría ${item.name}`,
        viewDetailtCategory
      )
    );
  };

  const removeCategory = (item) => {
    setLoaded(true);
    categoriesQuery.doc(item.id).delete().then(() => {
      setLoaded(false);
      showAlert(`Categoría ${item.name} eliminada correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al eliminar la categoría.`, "", Constants.TYPE_ALERT.error, "", error);
    });
  };

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  };

  return (
    <>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <CategoryAddUpdate currentBranch={docCurrentBranch} categories={categories} ref={childAddCategoryPassRef} />
      <div className="table-settings mb-1">
        <Row className="align-items-center">
          <Col xs={12} md={6}>
            <Form.Group className="mb-2">
              <Form.Label>Buscar por establecimiento:</Form.Label>
              <Form.Select id="branch" onChange={(e) => setDocCurrentBranch(e.target.value)}>
                {
                  branchs.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
                }
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </div>
      <DataTable
        items={categories}
        tableConfig={tableConfig}
        addNewHandler={() => openAddUpdateCategories(false)}
        editItemHandler={editCategory}
        removeItemHandler={removeCategory}
        viewItemHandler={viewCategory}
        contentViewDetail={viewDetail}
        addNewLabel="Agregar Nuevo"
        searchFilterKey="name"
      />
    </>
  );
};

export default CategoryData;
