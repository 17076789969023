import React, { useState, useRef, useCallback, useEffect, useContext } from "react";
import { Col, Row, Form, InputGroup, Button, Card } from '@themesberg/react-bootstrap';
import "firebase/firestore";
import { useFirestore, useFirestoreCollectionData, useStorage } from "reactfire";
import { appContext } from "../../../state/context";
import DataTable from "../../../components/dataTable";
import { AlertModal } from "../../../utils/models/AlertModal";
import { Constants } from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import { tableConfig } from '../models/tableConfig'
import Preloader from "../../../components/Preloader";
import { formatDateTimeString, getPreviousDay, toDateInputValue, toDateTime, getDateToFirebase, formatValidateDate } from "../../../utils/utils"
import BranchSelect from "../../../components/BrachSelect";
import { GenerateReportZ } from "./GenerateReportZ";
import { downloadFileStorage } from "../../../utils/services";

const ReportZ = () => {
  const { currentEnterprise } = useContext(appContext)
  const [loaded, setLoaded] = useState(false);
  const db = useFirestore();
  const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid);
  const [branchCurrent, setBranchCurrent] = useState({ id: "0" });
  const [timeStart, setTimeStart] = useState(toDateInputValue(getPreviousDay()));
  const [timeEnd, setTimeEnd] = useState(toDateInputValue(new Date()));
  const [reportsz, setReportsz] = useState([]);
  const { handleShowAlert, RenderAlert } = useAlert();
  const childGenerateReportZ = useRef();
  const reportsQuery = queryEnterprise.collection(Constants.BRANCHS).doc(branchCurrent.id).collection(Constants.REPORTZ);
  const { data: reportsDataObserver } =
    useFirestoreCollectionData(reportsQuery
      .where("start_date_report", ">=", getDateToFirebase(formatValidateDate(`${timeStart} 00:00:00`)))
      .where("start_date_report", "<=", getDateToFirebase(formatValidateDate(`${timeEnd} 23:59:00`)))
      .orderBy("start_date_report", "desc")
    );
  const storage = useStorage();
  const changeTimeStart = evt => setTimeStart(evt.target.value);
  const changeTimeEnd = evt => setTimeEnd(evt.target.value);

  useEffect(() => {
    setReportsz(reportsDataObserver.map(item => (
      {
        ...item,
        create_date: formatDateTimeString(toDateTime(item.create_date.seconds)),
        id: item.NO_ID_FIELD,
        end_date_report: item.end_date_report ? formatDateTimeString(toDateTime(item.end_date_report.seconds)) : null,
        start_date_report: item.start_date_report ? formatDateTimeString(toDateTime(item.start_date_report.seconds)) : null,
      }
    )))
  }, [reportsDataObserver])

  const openCreateNewReportZ = useCallback((isUpdate, branch) => {
    childGenerateReportZ.current.createNewReportZ(branch);
  }, [childGenerateReportZ]);


  const removeReport = async (item) => {
    setLoaded(true);
    try {
      if (item.file_pdf_path) {
        const gsReferencePDF = storage.ref(item.file_pdf_path);
        await gsReferencePDF.delete();
      }
      if (item.file_pdf_path) {
        const gsReferenceExcel = storage.ref(item.file_excel_path);
        await gsReferenceExcel.delete();
      }
      reportsQuery.doc(item.id).delete().then(() => {
        setLoaded(false);
        showAlert(`Reporte eliminado correctamente`, "", Constants.TYPE_ALERT.success, "");
      }).catch((error) => {
        setLoaded(false);
        showAlert(`Ocurrió un error al eliminar el reporte.`, "", Constants.TYPE_ALERT.error, "", error);
      });
    } catch (err) {
      setLoaded(false);
      showAlert(`Ocurrió un error al eliminar el reporte.`, "", Constants.TYPE_ALERT.error, "", err);
    }
  };

  const downloadAll = async () => {
    for (const file of reportsz) {
      const gsReferenceExcel = storage.ref(file.file_excel_path);
      await downloadFileStorage(gsReferenceExcel, file.file_excel_path);
      const gsReferencePDF = storage.ref(file.file_pdf_path);
      await downloadFileStorage(gsReferencePDF, file.file_pdf_path);
    }
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  };

  const branchSelected = (branch) => {
    setBranchCurrent(branch);
    if (branch.hasOwnProperty('id')) {
    }
  }

  return (
    <>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <GenerateReportZ ref={childGenerateReportZ} branch={branchCurrent} />
      <div className="table-settings mb-1">
        <Row className="align-items-center mb-4">
          <Col xs={12} md={4} lg={3} xl={3}>
            <BranchSelect branchSelected={branchSelected}></BranchSelect>
          </Col>
          <Col xs={12} md={4} lg={3} xl={3}>
            <Form.Group className="mb-2">
              <Form.Label>Fecha inicio:</Form.Label>
              <InputGroup>
                <Form.Control type="date" id="timeStart" name="timeStart" defaultValue={timeStart} onBlur={changeTimeStart}></Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={4} lg={3} xl={3}>
            <Form.Group className="mb-2">
              <Form.Label>Fecha fin:</Form.Label>
              <InputGroup>
                <Form.Control type="date" id="timeEnd" name="timeEnd" defaultValue={timeEnd} onBlur={changeTimeEnd}></Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={4} lg={3} xl={3}>
            <Button>Buscar</Button>
          </Col>
        </Row>
      </div>
      <DataTable
        items={reportsz}
        tableConfig={tableConfig}
        addNewHandler={() => openCreateNewReportZ(false)}
        isEditVisible={false}
        isViewVisible={false}
        removeItemHandler={removeReport}
        contentViewDetail={null}
        addNewLabel="Generar informe Z"
        searchFilterKey={null}
      />
      <Card bg="primary" className="px-4 py-4 mt-4 text-white">
        <Row className="justify-content-between">
          <Col>Total reportes: {reportsz.length * 2}</Col>
          <Col className="text-right">
            <Button variant="white" type="submit" onClick={downloadAll}>
              Descargar todo
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ReportZ;
