import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Col, Row, Button, Container, Modal, ListGroup } from '@themesberg/react-bootstrap';
import './ModalGeneral/ModalGeneral.scss';

export const ViewDetailItem = forwardRef(({ contentViewDetail, customDetailBody }, ref) => {
  const [showModal, setShowModal] = useState(false);
  const handleHideModal = () => setShowModal(false);
  const { titleHeader, titleBody, contentBody } = contentViewDetail || {};

  useImperativeHandle(ref, () => ({
    handleShowModal() { setShowModal(true) }
  }));

  return (
    <section>
      <Modal as={Modal.Dialog} size="lg" centered show={showModal} onHide={handleHideModal}>
        <Modal.Header className="m-success">
          <Modal.Title className="h6 text-white">{titleHeader ?? ""}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleHideModal} />
        </Modal.Header>
        <Modal.Body>
          <section>
            <Container>
              <div className="mb-4 mt-2">
                <h5 className="mb-0">{titleBody ?? ""}</h5>
              </div>
              <Row>
                {
                  contentBody ?
                    contentBody.map((item, index) => {
                      return <Col xs={6} md={4} key={index} className="text-break">
                        <h6 className="font-weight-bold">{item.title}</h6>
                        <div className="mb-4">
                          {
                            Array.isArray(item.value) ?
                              <ListGroup>
                                {item.value.map((res, i) =>
                                  <ListGroup.Item className="p-0" key={i}>
                                    {res}
                                  </ListGroup.Item>
                                )}
                              </ListGroup>
                              : (typeof item.value === "object") ?
                                <ListGroup>
                                  {Object.keys(item.value).map((res, i) =>
                                    <ListGroup.Item className="p-0" key={i}>
                                      {item.value[res]}
                                    </ListGroup.Item>
                                  )}
                                </ListGroup>
                                : item.value
                          }
                        </div>
                      </Col>
                    })
                    : ''
                }
              </Row>
              {customDetailBody && (
                <Row>
                  {customDetailBody}
                </Row>
              )}
            </Container>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="text-gray ms-auto" onClick={handleHideModal}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </section>
  )
});