import firebase from 'firebase/app';
import fetchIntercept from "fetch-intercept";

export const FetchInterceptorRequest = () => {

  fetchIntercept.register({
    request: async function (url, config) {

      const user = firebase.auth().currentUser;
      const token = await user.getIdToken();
      config.headers.Authorization = 'Bearer ' + token;
      config.headers["Content-Type"] = 'application/json';
      console.log(config);
      return [url, config];
    },

    requestError: function (error) {
      return Promise.reject(error);
    },

    response: function (response) {
      return response;
    },

    responseError: function (error) {
      return Promise.reject(error);
    }
  });
}