import React, { useState, useImperativeHandle, forwardRef, useContext } from "react";
import 'react-phone-input-2/lib/style.css'
import { Col, Row, Form, Button, Container, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { useFirestore } from 'reactfire';
import 'firebase/firestore';
import { AlertModal } from "../../../utils/models/AlertModal";
import { Constants } from "../../../utils/constants";
import Preloader from "../../../components/Preloader";
import useAlert from "../../../utils/hooks/useAlert";
import { castFields } from "../../../utils/utils";
import { appContext } from "../../../state/context"
import Printers from '../models/Printers';

const PrintersAddUpdate = forwardRef(({ selectedBranch, printers, getPrinters }, ref) => {
  const { currentEnterprise } = useContext(appContext)
  const [printer, setPrinter] = useState(new Printers());
  const [loaded, setLoaded] = useState(false);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [isSave, setIsSave] = useState(true);
  const [showModalPrinters, setShowModalPrinters] = useState(false);
  const db = useFirestore();
  const queryBranch = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid).collection(Constants.BRANCHS);

  useImperativeHandle(ref, () => ({
    addOrUpdatePrinters(isUpdate, printer) {
      console.log(isUpdate);
      console.log(printer);
      if (isUpdate) {
        setIsSave(false);
        setPrinter(printer);
      }
      else {
        clearForm();
        setIsSave(true);
      }
      handleShowModalPrinters();
    }
  }));

  const handleHideModalPrinter = () => setShowModalPrinters(false);
  const handleShowModalPrinters = () => { setShowModalPrinters(true) }

  const handleChangeForm = e => {
    const { name, value, type } = e.target;
    setPrinter(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const addUpdatePrinter = (e) => {
    e.preventDefault();
    isSave ? addPrinter() : updatePrinter()
  }

  const addPrinter = () => {
    let printerSave = JSON.parse(JSON.stringify(printer));
    delete printerSave["id"];
    setLoaded(true);
    queryBranch.doc(selectedBranch.id)
      .collection(Constants.PRINTERS).add(printerSave).then(() => {
        clearAddUpdate();
        showAlert(`Impresora ${printer.name} creada correctamente`, "", Constants.TYPE_ALERT.success, "");
      }).catch((error) => {
        setLoaded(false);
        showAlert(`Ocurrió un error al guardar la impresora ${printer.name}.`, "", Constants.TYPE_ALERT.error, "", error);
      })
  }

  const updatePrinter = () => {
    setLoaded(true);
    let printerUpdate = JSON.parse(JSON.stringify(printer));
    delete printerUpdate["id"];
    queryBranch.doc(selectedBranch.id)
      .collection(Constants.PRINTERS).doc(printer.id).update(printerUpdate).then(() => {
        clearAddUpdate();
        showAlert(`Impresora ${printer.name} actualizada correctamente`, "", Constants.TYPE_ALERT.success, "");
      }).catch((error) => {
        setLoaded(false);
        showAlert(`Ocurrió un error al actualizar la impresora ${printer.name}.`, "", Constants.TYPE_ALERT.error, "", error);
      })
  }

  const clearAddUpdate = () => {
    setLoaded(false);
    clearForm();
    handleHideModalPrinter();
    getPrinters();
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const clearForm = () => {
    setPrinter(new Printers());
    setIsSave(true);
  }

  return (
    <section>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <Modal size="lg"
        backdrop="static"
        keyboard={false}
        as={Modal.Dialog} centered show={showModalPrinters}>
        <Modal.Header className="m-success">
          <Modal.Title className="h6 text-white">CREAR NUEVA IMPRESORA</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleHideModalPrinter} />
        </Modal.Header>
        <Modal.Body>
          <section className="d-flex align-items-center">
            <Container>
              <div className="bg-white w-100 fmxw-500 m-auto">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">Completa todos los campos requeridos</h5>
                </div>
              </div>
              <Form className="mt-4" onSubmit={addUpdatePrinter}>
                <Row className="justify-content-center form-bg-image">
                  <Col md={12}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Nombre Impresora*</Form.Label>
                      <small> (El mimso nombre con el que aparece configurada la impresora en la red)</small>
                      <InputGroup>
                        <Form.Control value={printer.name} type="text" name="name" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="ip" className="mb-4">
                      <Form.Label>IP*</Form.Label>
                      <InputGroup>
                        <Form.Control value={printer.ip} type="text" name="ip" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="type" className="mb-4">
                      <Form.Label>Impresora de</Form.Label>
                      <Form.Select id="type" name="type" value={printer.type} onChange={handleChangeForm} required>
                        {
                          Object.keys(Constants.TYPE_PRINTERS).map(t => <option key={t} value={t}>{Constants.TYPE_PRINTERS[t]}</option>)
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-right mt-4 mb-3">
                  <Button variant="link" className="text-gray ms-auto" onClick={clearForm}>Limpiar</Button>
                  <Button variant="primary" type="submit">
                    {isSave ? "Guardar" : "Actualizar"}
                  </Button>
                </div>
              </Form>
            </Container>
          </section>
        </Modal.Body>
      </Modal>
    </section>
  )
})

export default PrintersAddUpdate;