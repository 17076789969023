import React, { memo } from "react";
import { createPortal } from "react-dom";
import { Modal, Button } from "@themesberg/react-bootstrap";
import classnames from 'classnames'
import { Constants } from "../../utils/constants";
import "./ModalGeneral.scss";

const getTypeLabel = type => {
  switch (type) {
    case Constants.TYPE_ALERT.error:
      return "¡ERROR!"
    
    case Constants.TYPE_ALERT.warning:
      return "¡ADVERTENCIA!"
  
    default:
      return ''
  }
}

const ModalGeneral = memo(({ content, closeModal, showModal, confirm }) => {
  const domEl = document.getElementById("modal");
  if (!domEl) return null;

  return createPortal(
    <Modal as={Modal.Dialog} centered show={showModal} onHide={closeModal}>
      <Modal.Header
        className={classnames({
          'm-success': content.type === Constants.TYPE_ALERT.success,
          'm-error': content.type === Constants.TYPE_ALERT.error,
          'm-warn': content.type === Constants.TYPE_ALERT.warning
        })}
      >
        <Modal.Title className="h6 text-white">{content.title}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <div>
          <h6
            classnames={classnames({
              'mt-success': content.type === Constants.TYPE_ALERT.success,
              'mt-error': content.type === Constants.TYPE_ALERT.error,
              'mt-warn': content.type === Constants.TYPE_ALERT.warning
            })}
          >
            {getTypeLabel(content.type)}
          </h6>
        </div>
        {content.body}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={closeModal}
        >
          Cerrar
        </Button>
        {content.typeModal === Constants.TYPE_MODAL.confirm ? (
          <Button
            variant="success"
            onClick={() => {
              confirm();
              closeModal();
            }}
          >
            Aceptar
          </Button>
        ) : (
          ""
        )}
      </Modal.Footer>
    </Modal>,
    domEl
  );
});

export default ModalGeneral;
