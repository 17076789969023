import React, { useState, useImperativeHandle, forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
  Modal,
} from "@themesberg/react-bootstrap";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import useModal from "../../utils/hooks/useModal";

export const ForgotPassword = forwardRef((props, ref) => {
  const { handleShow, RenderModal } = useModal();
  const [modal, setModal] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");

  const handleHideModal = () => setShowModal(false);

  useImperativeHandle(ref, () => ({
    handleShowModal() {
      setShowModal(true);
    },
  }));

  const recoverPassword = () => {
    if (email === "" || email === undefined) return;

    setModal(
      new AlertModal(
        "Por favor sigue las instrucciones que se enviaron a tu correo electónico para recuperar tu contraseña.",
        "Correo enviado",
        Constants.TYPE_ALERT.success
      )
    );
    handleShow();
    handleHideModal();
  };

  return (
    <section>
      <RenderModal content={modal}></RenderModal>
      <Modal
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={handleHideModal}
      >
        <Modal.Header>
          <Modal.Title className="h6 text-white">
            Recupera tu contraseña
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleHideModal}
          />
        </Modal.Header>
        <Modal.Body>
          <section className="d-flex align-items-center">
            <Container>
              <Row className="justify-content-center form-bg-image">
                <Col
                  xs={12}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div className="bg-white w-100 fmxw-500">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <h5 className="mb-0">
                        Por favor ingresa tu correo electrónico.
                      </h5>
                      <p>
                        Puedes recuperar tu contraseña ingresando tu correo
                        electrónico registrado.
                      </p>
                    </div>
                    <Form className="mt-4">
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Email</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            required
                            type="email"
                            onChange={(event) =>
                              setEmail(event.target.value.trim())
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleHideModal}
          >
            Cancelar
          </Button>
          <Button variant="primary" type="submit" onClick={recoverPassword}>
            Enviar al correo
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
});
