import React, { useState, useEffect } from "react";
import { Col, Row } from '@themesberg/react-bootstrap';
import "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import DataTable from "../../components/dataTable";
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import { tableConfig } from './models/tableConfig'
import { formatDateTimeString, toDateTime } from "../../utils/utils";
import Preloader from "../../components/Preloader";
import BranchSelect from "../../components/BrachSelect";
import useModal from "../../utils/hooks/useModal";

const QueueData = () => {
  const [loaded, setLoaded] = useState(false);
  const { handleShow, RenderModal } = useModal();
  const [modal, setModal] = useState({});
  const db = useFirestore();
  const queryQueue = db.collection(Constants.PRINTS);
  const [branchCurrent, setBranchCurrent] = useState({ id: "0" });
  const [prints, setPrints] = useState([]);
  const { handleShowAlert, RenderAlert } = useAlert();
  const { data: queueDataObserver } = useFirestoreCollectionData(queryQueue.where("branch_id", "==", branchCurrent?.id));

  useEffect(() => {
    setPrints(queueDataObserver.map(item => (
      {
        ...item, id: item['NO_ID_FIELD'],
        create_date: formatDateTimeString(toDateTime(item.create_date.seconds))
      }
    )))
  }, [queueDataObserver])

  const removePrint = (item) => {
    setLoaded(true);
    queryQueue.doc(item.id).delete().then(() => {
      setLoaded(false);
      showAlert(`Impresión eliminada correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al eliminar la impresión.`, "", Constants.TYPE_ALERT.error, "", error);
    });
  };

  const removeAllPrint = (item) => {
    setLoaded(true);
    prints.forEach(elem => {
      queryQueue.doc(elem.id).delete().then(() => {
        console.log("Eliminado");
      }).catch((error) => {
        setLoaded(false);
        showAlert(`Ocurrió un error al eliminar la impresión.`, "", Constants.TYPE_ALERT.error, "", error);
      });
    })
    setLoaded(false);
  };

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  };

  const branchSelected = (branch) => {
    setBranchCurrent(branch);
    if (branch.hasOwnProperty('id')) {
    }
  }

  const confirmRemoveAll = () => {
    if (prints.length > 0) {
      setModal(
        new AlertModal(
          `¿Seguro deseas eliminar todos los registros de cola de impresión para el establecimiento ${branchCurrent.name}?`,
          "Eliminar registro",
          Constants.TYPE_ALERT.warning,
          Constants.TYPE_MODAL.confirm
        )
      );
      handleShow();
    }
  };

  return (
    <>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <RenderModal content={modal} confirm={removeAllPrint}></RenderModal>
      <div className="table-settings mb-1">
        <Row className="align-items-center">
          <Col xs={12} md={6}>
            <BranchSelect branchSelected={branchSelected}></BranchSelect>
          </Col>
        </Row>
      </div>
      <DataTable
        items={prints}
        tableConfig={tableConfig}
        addNewHandler={() => confirmRemoveAll()}
        removeItemHandler={removePrint}
        isViewVisible={false}
        isEditVisible={false}
        contentViewDetail={null}
        addNewLabel="Eliminar todos"
        searchFilterKey="branch_id"
      />
    </>
  );
};

export default QueueData;
