import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from "react-router-dom";
import { useSigninCheck, useUser, useFirestore } from 'reactfire';
import { Routes } from "../routes";
import { appContext } from "../../state/context";
import { getRolesUser } from '../../utils/utils';
import { Constants } from '../../utils/constants';

const ProtectedRoute = ({ children, path, allowedRoles }) => {
  const db = useFirestore();
  const { status, data: signInCheckResult } = useSigninCheck();
  const { data: authUser } = useUser();
  const [loadingData, setLoadingData] = useState(true)
  const { user, setUser, setEnterprises, enterprises, setCurrentEnterprise, currentEnterprise } = useContext(appContext);
  let roles = [];
  let userAllowedForCurrentRoute = true;

  useEffect(() => {
    const hydrateUserInfo = async () => {
      if (signInCheckResult && !user && authUser) {
        db.collection(Constants.OTHER_USERS_INFO).doc(authUser.uid).onSnapshot(async (doc) => {
          const userInfo = doc.data();
          setUser({ ...userInfo, uid: doc.id, email: authUser.email })

          if (enterprises.length === 0) {
            const enterprisesInfo = []
            const role = userInfo.roles_array[0];
            const currentRoleInfo = role['role']
            const currentRole = Object.keys(currentRoleInfo).filter(rol => currentRoleInfo[rol])
            const enterprise = await role.entity_uuid.get();
            enterprisesInfo.push({ ...enterprise.data(), uid: enterprise.id });
            setEnterprises(enterprisesInfo);
            setCurrentEnterprise({ ...enterprisesInfo[0], currentRole, index: 0 });


            const userRoles = userInfo?.roles_array ? getRolesUser(userInfo.roles_array) : [];
            if (userRoles.includes(Constants.BAREST)) {
              db.collection(Constants.ENTERPRISE).get().then((res) => {
                let enterprises = [];
                res.forEach(doc => {
                  enterprises.push({ ...doc.data(), uid: doc.id })
                })
                setEnterprises(enterprises);
                if (enterprises.length) setCurrentEnterprise({ ...enterprises[0], currentRole, index: 0 });
              })
            }
          }
        });
      }
    }

    hydrateUserInfo();
  }, [authUser, db, setCurrentEnterprise, setEnterprises, setUser, signInCheckResult, user, enterprises]);

  useEffect(() => {
    if ((user || !signInCheckResult?.signedIn) && currentEnterprise) {
      setLoadingData(false);
    }
  }, [user, signInCheckResult, currentEnterprise])

  if (signInCheckResult && user?.roles_array) {
    roles = user.roles_array.map(rolesItem => {
      return Object.keys(rolesItem.role).filter(rol => rolesItem.role[rol])
    }).flat();

    userAllowedForCurrentRoute = allowedRoles
      ? roles.reduce((prev, role) => {
        if (allowedRoles.includes(role)) { return true; }
        return prev;
      }, false)
      : true;
  }

  if (loadingData) {
    return null
  }

  return (
    <Route path={path} render={(props) => {
      if (status === 'success' && signInCheckResult?.signedIn) {
        return userAllowedForCurrentRoute ? (
          React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { ...props });
            }
            return child;
          })
        ) : (
          <Redirect
            to={{
              pathname: Routes.NotAuthorized.path,
              state: {
                prevLocation: path,
                error: "Not Authorized",
              },
            }}
          />
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: "/",
              state: {
                prevLocation: path,
                error: "You need to login first!",
              }
            }}
          />
        );
      }
    }}
    />
  );
};

export default ProtectedRoute;
