export async function sendEmail(templateEmail) {
  await fetch(`${process.env.REACT_APP_API_URL}/mailer`, {
    method: "POST",
    body: JSON.stringify(templateEmail),
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export async function downloadFileStorage(gsReference, pathFile) {
  await gsReference.getDownloadURL().then(function (url) {
    // This can be downloaded directly:
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function (event) {
      const blob = xhr.response;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = pathFile.split("/")[pathFile.split("/").length - 1];
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    };
    xhr.open('GET', url);
    xhr.send();

  }).catch(function (error) {
    switch (error.code) {
      case 'storage/object-not-found':
        console.log('storage/object-not-found')
        break;

      case 'storage/unauthorized':
        console.log('storage/unauthorized')
        break;

      case 'storage/canceled':
        console.log('storage/canceled')
        break;

      case 'storage/unknown':
        console.log('storage/unknown')
        break;

      default:
        console.log('storage/unknown')
        break;
    }
  });
}