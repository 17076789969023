import React, { useState, useImperativeHandle, forwardRef, useRef, useCallback, useEffect, useContext } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Col, Row, Form, Button, Container, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { useFirestore, useFirebaseApp } from 'reactfire';
import 'firebase/firestore';
import { AlertModal } from "../../utils/models/AlertModal";
import ContactInfo from './models/ContactInfo';
import { Constants } from "../../utils/constants";
import { CreateNewUserAuth } from "../../components/CreateNewUser";
import Enterprise from './models/EnterpriseModel';
import Preloader from "../../components/Preloader";
import { TemplateEmail } from "../../utils/models/TemplateEmail";
import useAlert from "../../utils/hooks/useAlert";
import { castFields } from "../../utils/utils";
import { User } from "../../utils/models/User";
import { appContext } from "../../state/context"
import RecoverPassword from "../../components/RecoverPassword";

export const EnterpriseAddUpdate = forwardRef((props, ref) => {
  const { enterprises, setEnterprises } = useContext(appContext);
  const [loaded, setLoaded] = useState(false);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [isSave, setIsSave] = useState(true);
  const [showModalEnterprise, setShowModalEnterprise] = useState(false);
  const [lastEmailAdminEnterprise, setLastEmailAdminEnterprise] = useState("");
  const [enterprise, setEnterprise] = useState(new Enterprise());
  const [contactInfo, setContactInfo] = useState(new ContactInfo());
  const firebase = useFirebaseApp();
  const db = useFirestore();
  const queryEnterprise = db.collection(Constants.ENTERPRISE);
  const childPassRef = useRef();

  const messages = {
    success: "Se ha enviado un correo al administrador de la empresa",
    error: "No se pudo crear y enviar el email al administrador de la empresa"
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    addOrUpdateEnterprise(isUpdate, enterpriseItem) {
      if (isUpdate) {
        setIsSave(false);
        setLastEmailAdminEnterprise(enterpriseItem.contact_info?.contact_email);
        setEnterprise(enterpriseItem);
        setContactInfo(enterpriseItem.contact_info);
      }
      else {
        clearForm();
        setIsSave(true);
      }
      handleShowModalEnterprise();
    }
  }));

  const handleHideModalEnterprise = () => setShowModalEnterprise(false);
  const handleShowModalEnterprise = () => { setShowModalEnterprise(true) }

  const handleChangeForm = e => {
    const { name, value, type } = e.target;
    setEnterprise(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const handleChangeFormContactInfo = e => {
    const { name, value, type } = e.target;
    setContactInfo(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const handleIsNameEnterprise = (event) => {
    const name = event.target.value;
    setLoaded(true);
    queryEnterprise.where("enterprise_id", "==", name).get().then(querySnapshot => {
      setLoaded(false);
      if (querySnapshot.docs.length > 0) {
        showAlert(`Ya existe una empresa con el nombre ${name}`, "", Constants.TYPE_ALERT.success, "");
        setEnterprise(prevState => ({ ...prevState, enterprise_id: "" }));
      }
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al traer la empresa ${name}.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const addUpdateEnterprise = (e) => {
    e.preventDefault();
    let enterpriseModelFB = {};
    if (isSave) {
      enterpriseModelFB = {
        enterprise_id: enterprise.enterprise_id,
        name: enterprise.name,
        address: enterprise.address,
        is_active: enterprise.is_active,
        contact_info: JSON.parse(JSON.stringify(contactInfo))
      }
      addEnterprise(enterpriseModelFB)
    } else {
      enterpriseModelFB = {
        name: enterprise.name,
        address: enterprise.address,
        is_active: enterprise.is_active,
        contact_info: JSON.parse(JSON.stringify(contactInfo))
      }
      updateEnterprise(enterpriseModelFB)
    }
  }

  const addEnterprise = (enterpriseModel) => {
    setLoaded(true);
    queryEnterprise.add(enterpriseModel).then(async (ref) => {
      await addNewUserAuth(ref.id);
      const dataEnterprise = await ref.get();
      const docInsertEnterprise = { ...dataEnterprise.data(), uid: ref.id };
      setEnterprises([...enterprises, docInsertEnterprise]);
      clearAddUpdate();
      setLoaded(false);
      showAlert(`Empresa ${enterpriseModel.name} creada correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch(error => {
      setLoaded(false);
      showAlert(`Ocurrió un error al guardar la empresa.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const updateEnterprise = (enterpriseModel) => {
    setLoaded(true);
    queryEnterprise.doc(enterprise.id).update(enterpriseModel).then(async () => {
      if (lastEmailAdminEnterprise !== contactInfo.contact_email)
        await addNewUserAuth();
      clearAddUpdate();
      setLoaded(false);
      showAlert(`Empresa ${enterpriseModel.name} actualizada correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch(error => {
      setLoaded(false);
      showAlert(`Ocurrió un error al guardar la empresa.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }


  const userSaved = (user) => { console.log(user) }

  const addNewUserAuth = useCallback(async (enterpriseID) => {
    const ischeckedEmail = await firebase.auth().fetchSignInMethodsForEmail(contactInfo.contact_email);
    const params = {
      EMAIL: contactInfo.contact_email,
      ENTERPRISE: enterprise.name,
      ROL: "administrador",
      URL: process.env.REACT_APP_URL_BAREST
    }
    const templateEmail = new TemplateEmail(
      contactInfo.contact_email,
      "Nuevo administrador",
      ischeckedEmail.length ? Constants.NEW_USER_ADMIN_ENTERPRISE : Constants.NEW_ASSIGN_ENTERPRISE,
      params
    )
    let user = new User();
    user.roles_array.push(Constants.ROLES);
    user.roles_array[0]["role"]["ADMIN"] = true;
    user.email = contactInfo.contact_email;
    user = JSON.parse(JSON.stringify(user));
    user.roles_array[0]["entity_uuid"] = db.doc(`/${Constants.ENTERPRISE}/${enterpriseID}`);
    childPassRef.current.addOrUpdateNewUser(user, templateEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childPassRef, contactInfo, enterprise]);

  const clearAddUpdate = () => {
    setLoaded(false);
    clearForm();
    handleHideModalEnterprise();
    props.getEnterprises();
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const clearForm = () => {
    setEnterprise(new Enterprise());
    setLastEmailAdminEnterprise("");
    setContactInfo(new ContactInfo());
    setIsSave(true);
  }

  return (
    <section>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <CreateNewUserAuth userSaved={userSaved} messages={messages} ref={childPassRef} />
      <Modal size="lg"
        backdrop="static"
        keyboard={false}
        as={Modal.Dialog} centered show={showModalEnterprise}>
        <Modal.Header className="m-success">
          <Modal.Title className="h6 text-white">CREAR EMPRESA</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleHideModalEnterprise} />
        </Modal.Header>
        <Modal.Body>
          <section className="d-flex align-items-center">
            <Container>
              <div className="bg-white w-100 fmxw-500 m-auto">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">Completa todos los campos requeridos</h5>
                </div>
              </div>
              <Form className="mt-4" onSubmit={addUpdateEnterprise}>
                <Row className="justify-content-center form-bg-image">
                  <Col xs={12} md={6}>
                    <Form.Group id="enterprise_id" className="mb-4">
                      <Form.Label>CIF Empresa*</Form.Label>
                      <InputGroup>
                        <Form.Control value={enterprise.enterprise_id} type="text" name="enterprise_id" disabled={isSave ? false : true} onBlur={handleIsNameEnterprise} onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Nombre de la Empresa*</Form.Label>
                      <InputGroup>
                        <Form.Control value={enterprise.name} type="text" name="name" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="address" className="mb-4">
                      <Form.Label>Dirección*</Form.Label>
                      <InputGroup>
                        <Form.Control value={enterprise.address} type="text" name="address" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <h5 className="bold mb-4">Información de contacto</h5>
                <Row>
                  <Col md={12}>
                    <Form.Group id="contact_name" className="mb-4">
                      <Form.Label>Nombre*</Form.Label>
                      <InputGroup>
                        <Form.Control value={contactInfo.contact_name} type="text" name="contact_name" onChange={handleChangeFormContactInfo} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={!isSave ? 8 : 12}>
                    <Form.Group id="contact_email" className="mb-4">
                      <Form.Label>Email*</Form.Label>
                      <InputGroup>
                        <Form.Control value={contactInfo.contact_email} type="email" name="contact_email" onChange={handleChangeFormContactInfo} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  {!isSave ?
                    <Col md={4}>
                      <RecoverPassword email={contactInfo.contact_email}></RecoverPassword>
                    </Col> : ""}
                </Row>
                <Row>
                  <Col xs={4} md={2}>
                    <Form.Group className="mb-4">
                      <Form.Label>Código*</Form.Label>
                      <PhoneInput
                        country={'es'}
                        value={contactInfo.contact_country_call_code}
                        enableSearch={true}
                        inputStyle={{
                          width: "100%",
                          height: "42px",
                        }}
                        onChange={phone => setContactInfo(prevState => ({ ...prevState, contact_country_call_code: phone }))}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={8} md={4}>
                    <Form.Group id="contact_mobile_number" className="mb-4">
                      <Form.Label>Número de teléfono*</Form.Label>
                      <InputGroup>
                        <Form.Control value={contactInfo.contact_mobile_number} type="number" name="contact_mobile_number" onChange={handleChangeFormContactInfo} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col>
                    {!isSave ?
                      <Col xs={12} md="auto">
                        <Form.Group id="is_active" className="mb-4">
                          <Form.Label>Estado</Form.Label>
                          <Form.Select value={enterprise.is_active} name="is_active" onChange={handleChangeForm} required>
                            <option value={true}>Activo</option>
                            <option value={false}>Inactivo</option>
                          </Form.Select>
                        </Form.Group>
                      </Col> : ""}
                  </Col>
                </Row>
                <div className="text-right mt-4 mb-3">
                  <Button variant="link" className="text-gray ms-auto" onClick={clearForm}>Limpiar</Button>
                  <Button variant="primary" type="submit">
                    {isSave ? "Guardar" : "Actualizar"}
                  </Button>
                </div>
              </Form>
            </Container>
          </section>
        </Modal.Body>
      </Modal>
    </section>
  )
});