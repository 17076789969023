import React, { useState, useImperativeHandle, forwardRef, useEffect, useContext } from "react";
import { Col, Row, Form, Button, Container, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { useFirestore } from 'reactfire';
import 'firebase/firestore';
import firebase from 'firebase/app';
import { AlertModal } from "../../../utils/models/AlertModal";
import { Constants } from "../../../utils/constants";
import { appContext } from "../../../state/context"
import Preloader from "../../../components/Preloader";
import useAlert from "../../../utils/hooks/useAlert";
import { castFields, getDateToFirebase } from "../../../utils/utils";
import { ReportZDAO } from "../models/ReportZDAO";

export const GenerateReportZ = forwardRef(({ branch }, ref) => {
  const db = useFirestore();
  const { currentEnterprise } = useContext(appContext);
  const [loaded, setLoaded] = useState(false);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [showModalReport, setShowModalReport] = useState(false);
  const [reportzDAO, setReportzDAO] = useState(new ReportZDAO());
  const [tableBars, setTableBars] = useState([]);
  const [journals, setJournals] = useState([]);
  const enterpriseID = currentEnterprise?.uid;
  const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(enterpriseID);
  const user = firebase.auth().currentUser;
  const [valuesReports, setValuesReports] = useState(
    {
      totalRequest: 0,
      totalProducts: 0,
      totalTransaction: 0,
      totalAmountNet: 0,
      totalAmountGross: 0,
      totalAmountCommissions: 0,
      totalAmountTips: 0,
      totalAllTransactions: 0,
      mediaTicket: NaN
    })

  useImperativeHandle(ref, () => ({
    createNewReportZ() {
      clearForm();
      handleShowModalReport();
    }
  }));

  useEffect(() => {
    getTablesBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch])

  useEffect(() => {
    if (valuesReports.mediaTicket != null && !isNaN(valuesReports.mediaTicket)) {
      valuesReports["start_date_report"] = new Date(reportzDAO.start_date).toString();
      valuesReports["end_date_report"] = new Date(reportzDAO.end_date).toString();
      setLoaded(true);
      const data = {
        journals: journals,
        valuesReports: valuesReports,
        enterprise: currentEnterprise,
        branch: branch,
        employee: reportzDAO.employee,
        currentDate: new Date().toString()
      }
      console.log(user);
      fetch(`${process.env.REACT_APP_API_URL}/generateReportZ`, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }).then(async (res) => {
        setLoaded(false);
        const response = await res.json();
        showAlert(response.message, "", Constants.TYPE_ALERT.success, "", null);
        setShowModalReport(false);
        clearForm();
      }).catch((error) => {
        setLoaded(false);
        showAlert(`Ocurrió un error generar el reporte.`, "Error", Constants.TYPE_ALERT.error, "", error);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesReports])

  const getTablesBar = () => {
    const tablesBarsQuery = queryEnterprise.collection(Constants.BRANCHS).doc(branch?.id).collection(Constants.TABLES_BAR);
    tablesBarsQuery.get()
      .then(querySnapshot => {
        const mappedTablesAndBars = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data?.status?.deleted === false || data?.status?.deleted === undefined) {
            mappedTablesAndBars.push({ ...data, id: doc.id })
          }
        });
        setTableBars(mappedTablesAndBars);
      })
      .catch(error => {
        showAlert(`Ocurrió un error al traer las mesas/barras.`, "", Constants.TYPE_ALERT.error, "", error);
      });
  }

  const handleChangeForm = e => {
    const { name, value, type } = e.target;
    setReportzDAO(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const handleHideModalReport = () => setShowModalReport(false);
  const handleShowModalReport = () => { setShowModalReport(true) }

  const createReport = (e) => {
    e.preventDefault();
    getJournals();
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const getJournals = () => {
    if (new Date(reportzDAO.end_date) >= new Date(reportzDAO.start_date) &&
      new Date(reportzDAO.end_date) <= new Date()) {
      setLoaded(true);
      const journalsQuery = db
        .collection(Constants.JOURNALS)
        .where("branch_id", "==", branch?.id)
        .where("open_date_time", ">=", getDateToFirebase(new Date(reportzDAO.start_date)))
        .where("open_date_time", "<=", getDateToFirebase(new Date(reportzDAO.end_date)))
        .orderBy("open_date_time", "desc");

      const getTableInfo = journalTableBarId => {
        const tablesAndBarsId = tableBars.map(item => item?.id);
        const tableIndex = tablesAndBarsId.findIndex(id => journalTableBarId.includes(id));
        return tableIndex >= 0 ? tableBars[tableIndex] : null;
      }

      const getRequestProducts = async (journalId, requestId) => {
        const products = [];
        const productsQuery = await db.collection(Constants.JOURNALS).doc(journalId).collection(Constants.REQUESTS).doc(requestId).collection(Constants.PRODUCT_ORDER).get();
        productsQuery.forEach(doc => {
          products.push({ ...doc.data(), id: doc.id });
        });
        return products;
      }

      const getTransactions = async (journalId) => {
        const transactions = [];
        const transactionsQuery = await db.collection(Constants.JOURNALS)
          .doc(journalId)
          .collection(Constants.TRANSACTION)
          .get();
        transactionsQuery.forEach(doc => {
          transactions.push({ ...doc.data(), id: doc.id });
        });
        return transactions;
      }

      const getPayments = async (journalId) => {
        const payments = [];
        const paymentsQuery = await db.collection(Constants.JOURNALS)
          .doc(journalId)
          .collection(Constants.PAYMENTS)
          .where("is_paid", "==", true)
          .get();
        paymentsQuery.forEach(doc => {
          payments.push({ ...doc.data(), id: doc.id });
        });
        return payments;
      }

      const getJournalRequests = async journalId => {
        const requestsItems = [];
        const requestsQuery = await db.collection(Constants.JOURNALS).doc(journalId).collection(Constants.REQUESTS).get();
        for (const doc of requestsQuery.docs) {
          requestsItems.push({
            ...doc.data(),
            id: doc.id,
            products: (await getRequestProducts(journalId, doc.id)).concat(await getProductsRemove(journalId, doc.id))
          });
        };
        return requestsItems;
      }

      const getProductsRemove = async (journalId, requestId) => {
        const productRemoveItems = [];
        const productRemoveQuery = await db.collection(Constants.JOURNALS)
          .doc(journalId)
          .collection(Constants.PRODUCT_REMOVE)
          .where("request_id", "==", requestId)
          .get();
        for (const doc of productRemoveQuery.docs) {
          productRemoveItems.push({
            ...doc.data(),
            id: doc.id
          });
        };
        return productRemoveItems;
      }

      journalsQuery.get()
        .then(querySnapshot => {
          let mappedJournals = [];
          querySnapshot.forEach(doc => {
            mappedJournals.push({ ...doc.data(), id: doc.id });
          });

          mappedJournals = mappedJournals.map(async journal => {
            return {
              ...journal,
              refPath: journal.tableBarId?.path,
              tableInfo: getTableInfo(journal.tableBarId?.path),
              request: await getJournalRequests(journal.id),
              transaction: await getTransactions(journal.id),
              payments: await getPayments(journal.id),
              employee: reportzDAO.employee
            };
          })

          Promise.all(mappedJournals).then(value => {
            setJournals(value);
            getDataOrders(value);
            setLoaded(false);
          })
        })
        .catch(error => {
          setLoaded(false);
          showAlert(`Ocurrió un error al traer las mesas/barras.`, "", Constants.TYPE_ALERT.error, "", error);
        });
    } else {
      showAlert(`La fecha final no puede ser menor a la inicial. Las fechas no pueden ser mayor a la fecha y hora actual.`, "", Constants.TYPE_ALERT.error, "", null);
    }
  }

  const getDataOrders = (journals) => {
    let totalRequests = 0;
    let totalProducts = 0;
    let totalTransaction = 0;
    let totalAmountNet = 0;
    let totalAmountGross = 0;
    let totalAmountCommissions = 0;
    let totalAmountTips = 0;
    let totalAllTransactions = 0;

    journals.forEach(journal => {
      journal.request.forEach(request => {
        totalRequests++;
        request.products.forEach(prod => {
          if (Constants.STATUS_PRODUCT_REMOVE[prod.status] === undefined) {
            totalProducts++;
          }
        })
      })
    })

    journals.forEach(journal => {
      journal.transaction.forEach(trx => {
        if (trx.status === Constants.TYPE_STATUS_TRANSACTION.approved) {
          let amountNetTotal = ((trx?.services_charges_value ?? 0) + (trx?.services_charges_stripe_value ?? 0));
          totalTransaction++;
          totalAmountNet += (trx.amount + (trx?.tip_value ?? 0) - amountNetTotal);
          totalAmountGross += trx.amount + (trx?.tip_value ?? 0);
          totalAmountCommissions += amountNetTotal;
          totalAmountTips += trx?.tip_value ?? 0;
        }
        totalAllTransactions++;
      })
    })

    const mediaTicket = totalAmountGross / totalTransaction;

    let valuesReportsItems = {
      totalRequest: totalRequests,
      totalProducts: totalProducts,
      totalTransaction: totalTransaction,
      totalAmountNet: totalAmountNet,
      totalAmountGross: totalAmountGross,
      totalAmountCommissions: totalAmountCommissions,
      totalAmountTips: totalAmountTips,
      totalAllTransactions: totalAllTransactions,
      mediaTicket: isNaN(mediaTicket) ? 0 : mediaTicket
    }

    setValuesReports(valuesReportsItems);
  }

  const clearForm = () => {
    setReportzDAO(new ReportZDAO());
  }

  return (
    <section>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <Modal size="lg"
        backdrop="static"
        keyboard={false}
        as={Modal.Dialog} centered show={showModalReport}>
        <Modal.Header className="m-success">
          <Modal.Title className="h6 text-white">GENERAR INFORME</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleHideModalReport} />
        </Modal.Header>
        <Modal.Body>
          <section className="d-flex align-items-center">
            <Container>
              <Form className="mt-4" onSubmit={createReport}>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="start_date" className="mb-4">
                      <Form.Label>Fecha y hora inicial*</Form.Label>
                      <InputGroup>
                        <Form.Control value={reportzDAO.start_date} type="datetime-local" name="start_date" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="end_date" className="mb-4">
                      <Form.Label>Fecha y hora final*</Form.Label>
                      <InputGroup>
                        <Form.Control value={reportzDAO.end_date} type="datetime-local" name="end_date" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group id="employee" className="mb-4">
                      <Form.Label>Nombre empleado</Form.Label>
                      <InputGroup>
                        <Form.Control value={reportzDAO?.employee} type="text" name="employee" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-right mt-3">
                  <Button variant="primary" type="submit">
                    Generar informe Z
                  </Button>
                </div>
              </Form>
            </Container>
          </section>
        </Modal.Body>
      </Modal>
    </section>
  )
});