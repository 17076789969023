export class User {
    id;
    first_name;
    last_name;
    gender;
    postal_code;
    birth_date;
    country_info;
    mobile_number;
    roles_array;
    first_login;
    email;

    constructor(
        _first_name,
        _last_name,
        _gender,
        _postal_code,
        _birth_date,
        _country_info,
        _mobile_number,
        _roles_array,
        _first_login,
        _email
    ) {
        this.first_name = _first_name ?? "";
        this.last_name = _last_name ?? "";
        this.gender = _gender ?? "M";
        this.postal_code = _postal_code ?? 0;
        this.birth_date = _birth_date ?? new Date();
        this.country_info = _country_info ?? {};
        this.mobile_number = _mobile_number ?? 0;
        this.roles_array = _roles_array ?? [];
        this.first_login = _first_login ?? true;
        this.email = _email ?? "";
    }
}