import { formatDateTimeStringInputDate } from "../../../utils/utils";

export class ReportZDAO {
  start_date;
  end_date;
  employee;

  constructor(
    _start_date,
    _end_date,
    _employee
  ) {
    this.start_date = _start_date ?? formatDateTimeStringInputDate(new Date());
    this.end_date = _end_date ?? formatDateTimeStringInputDate(new Date());
    this.employee = _employee ?? "";
  }
}