export const getDashboardData = async (timeInterval, branch) => {
  console.log(timeInterval);
  console.log(branch);
  // TODO use the actual endpoint and also use the url params
  const res = await fetch(`${process.env.REACT_APP_API_URL}/getDashboardInformation?branch_id=${branch}&time_window=${timeInterval.toUpperCase()}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await res.json();
}