import React, { memo } from 'react';
import { createPortal } from 'react-dom';
import { Alert, Button } from '@themesberg/react-bootstrap';
import { Constants } from "../../utils/constants";
import './Alerts.scss';

const Alerts = memo(({ content, closeAlert, i }) => {
  const domEl = document.getElementById('alerts')
  if (!domEl) return null

  return createPortal(
    <Alert
      variant={
        content.type === Constants.TYPE_ALERT.error ? "danger" :
          content.type === Constants.TYPE_ALERT.warning ? "warning" :
            content.type === Constants.TYPE_ALERT.success ? "info" : ""
      }>

      <div className="d-flex  align-items-center justify-content-between">
        <div className="font-small text-break">
          {content.body}
        </div>
        <Button variant="close" size="xs" onClick={() => closeAlert(i)} />
      </div>
    </Alert>,
    domEl
  );
})

export default Alerts;