import React from 'react';
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  return (
    <>
      <Sidebar />

      <main className="content">
        <Navbar />
        <Component {...rest} />
      </main>
    </>
  );
};

export default RouteWithSidebar;
