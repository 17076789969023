import React, { useState } from "react";
import 'firebase/auth';
import { AlertModal } from "../../utils/models/AlertModal";
import { useFirebaseApp } from 'reactfire';
import { Button } from "@themesberg/react-bootstrap";
import { Constants } from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import Preloader from "../../components/Preloader";
import useModal from "../../utils/hooks/useModal";
import "./RecoverPassword.scss";

const RecoverPassword = ({ email, textButton = "Restablecer contraseña" }) => {
    const firebase = useFirebaseApp();
    const { handleShowAlert, RenderAlert } = useAlert();
    const { handleShow, RenderModal } = useModal();
    const [modal, setModal] = useState({});
    const [loading, setLoading] = useState(false);

    const sendEmailRecoverPassword = () => {
        if (email.trim().length > 0) {
            setLoading(true);
            firebase.auth().sendPasswordResetEmail(email)
                .then(() => {
                    showAlert(
                        `Correo de recuperación de contraseña enviado correctamente a ${email}`,
                        "",
                        Constants.TYPE_ALERT.success
                    );
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error)
                    showAlert(
                        `Error enviando correo para recuperar contraseña. ${error.message}`,
                        "",
                        Constants.TYPE_ALERT.error
                    );
                    setLoading(false);
                })
        } else {
            showAlert(
                "Error enviando correo para recuperar contraseña. El correo tiene que ser válido",
                "",
                Constants.TYPE_ALERT.error
            );
        }
    }

    const confirmRecoverPassword = () => {
        setModal(
            new AlertModal(
                `¿Seguro deseas enviar un correo de restauración de contraseña a ${email}?`,
                "¿Deseas restablecer contraseña?",
                Constants.TYPE_ALERT.warning,
                Constants.TYPE_MODAL.confirm
            )
        );
        handleShow();
    };


    const showAlert = (body, title, typeAlert, typeModal, err) => {
        handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
    }

    return (
        <>
            <RenderModal content={modal} confirm={sendEmailRecoverPassword}></RenderModal>
            <Preloader show={loading ? true : false} />
            <RenderAlert></RenderAlert>
            <Button variant="tertiary" type="button" onClick={() => confirmRecoverPassword()}>{textButton}</Button>
        </>
    );
};

export default RecoverPassword;
