import React from 'react';
import ReactDOM from 'react-dom';
import { FirebaseAppProvider } from 'reactfire';
import { firebaseConfig } from './utils/config/firebase'
import { AppCheckFirebase } from './appCheck';
// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
    <AppCheckFirebase />
  </FirebaseAppProvider>,
  document.getElementById("root")
);
