export const tableConfig = [
  {
    label: 'NOMBRE DE USUARIO',
    key: 'username'
  },
  {
    label: 'EMAIL',
    key: 'email'
  },
  {
    label: 'NOMBRES',
    key: 'first_name'
  },
  {
    label: 'APELLIDOS',
    key: 'last_name'
  },
]
