
import React, { useState } from "react";
import { Col, Row, Card, Button } from '@themesberg/react-bootstrap';
import Progress from "../../components/Progress";
import { Constants } from '../../utils/constants';
import './index.scss';

const DashboardProductCard = ({ category, data }) => {
  const [typeFilter, setTypeFilter] = useState(Constants.TYPE_TABLES_BAR.TABLE);

  const handleSetTypeFilter = type => setTypeFilter(type);

  const filteredProducts = data.filter !== undefined ? data.filter(product => {
    if (typeFilter === Constants.TYPE_TABLES_BAR.TABLE) {
      return product.type === Constants.KITCHEN.toLowerCase();
    }

    return product.type === Constants.TYPE_TABLES_BAR.BAR.toLowerCase();
  }) : [];

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xs={12}>
            <div>
              <h5 className="mb-0 text-info" style={{ minWidth: 90 }}>
                Productos
              </h5>
            </div>
          </Col>
          <Col xs={12}>
            <div>
              <h5 className="mb-0" style={{ minWidth: 90 }}>
                Ranking consumidos:{" "}
                <span className="text-info">{category}</span>
              </h5>
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex align-items-center justify-content-between my-4">
              <Button
                variant={
                  typeFilter === Constants.TYPE_TABLES_BAR.TABLE
                    ? "secondary"
                    : "primary"
                }
                size="sm"
                style={{ width: "48%" }}
                onClick={() =>
                  handleSetTypeFilter(Constants.TYPE_TABLES_BAR.TABLE)
                }
              >
                Mesas
              </Button>
              <Button
                variant={
                  typeFilter === Constants.TYPE_TABLES_BAR.BAR
                    ? "secondary"
                    : "primary"
                }
                size="sm"
                style={{ width: "48%" }}
                onClick={() =>
                  handleSetTypeFilter(Constants.TYPE_TABLES_BAR.BAR)
                }
              >
                Barras
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <ul className="products-list">
              {filteredProducts.map((product, index) => (
                <li key={`${product.name}-${product.variance}-${index}`} className="product-item">
                  <span className="label">{product.name}</span>
                  <Progress
                    variant="secondary"
                    value={product.variance * 100}
                    showValue={false}
                  />
                  <div className="value-items">
                    <span className="text-info">{product.total_before}</span>
                    <span className="text-warning">{product.total}</span>
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DashboardProductCard;
