import React, { useState } from "react";
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Button, Container, Form, InputGroup } from '@themesberg/react-bootstrap';
import { toDateTime, formatDateString, toDateInputValue } from "../../../utils/utils"
import countryCodes from "../../../utils/feeds/countryCodes.json"
import { Constants } from "../../../utils/constants";
import Profile1 from "./profile-admin.png";



const ProfileCard = ({ userData, role, updateUserInfo, showAlert }) => {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState({ newPassword: '', currentPassword: '' });
  const [userInfo, setUserInfo] = useState({...userData, birth_date: toDateInputValue( userData.birth_date ? new Date(toDateTime(userData.birth_date.seconds)) : new Date() )})

  const handleChangeForm = e => {
    const { name, value } = e.target;
    setUserInfo(prevState => ({ ...prevState, [name]: value }));
  };
  
  const handleChangeFormPassword = e => {
    const { name, value } = e.target;
    setPasswordInfo(prevState => ({ ...prevState, [name]: value }));
  };
  
  const updateUser = e => {
    e.preventDefault();
    const dataToUpdate = {
      ...userInfo,
      country_info: typeof userInfo['country_info'] === 'string' ? countryCodes.find(el => el['Calling-code'] === userInfo['country_info']) : userInfo.country_info
    }

    updateUserInfo({ ...dataToUpdate, birth_date: firebase.firestore.Timestamp.fromDate(new Date(dataToUpdate.birth_date)) });
    setEditing(false);
  }
 
  const updatePassword = async (e) => {
    e.preventDefault();
    const { currentPassword, newPassword } = passwordInfo;
    const user = firebase.auth().currentUser;

    const reauthenticate = currentPassword => {
      var cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
      return user.reauthenticateWithCredential(cred);
    }

    try {
      setLoading(true);
      await reauthenticate(currentPassword)
      await user.updatePassword(newPassword)
      setLoading(false);
      setEditing(false);
      showAlert(`Contraseña actualizada correctamente`, "", Constants.TYPE_ALERT.success, "");
    } catch (err) {
      setLoading(false);
      const { code } = err;
      let wording = '';

      switch (code) {
        case Constants.PASSWORD_ERRORS.WEAK_PASSWORD:
          wording = 'La contraseña debe contener al menos 6 caracteres.'
          break;
        
        case Constants.PASSWORD_ERRORS.WRONG_PASSWORD:
          wording = 'La contraseña actual ingresada no corresponde.'
          break;
      
        default:
          wording = 'La contraseña actual ingresada no corresponde.'
          break;
      }

      showAlert(wording, "", Constants.TYPE_ALERT.error, "", null);
    }
  }

  const emptyValue = <span>--</span>

  return (
    <Card border="light" className="p-0 mb-4">
      <div className="profile-cover rounded-top" />
      <Card.Body className="pb-5">
        <Card.Img
          src={Profile1}
          alt="Neil Portrait"
          className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4"
        />
        {!editing ? (
          <div className="text-center">
            {userData.first_login && (
              <p className="text-danger">Por favor completa tu información antes de continuar.</p>
            )}
            <Card.Title>

              { userData.first_name || userData.last_name ? (
                `${userData.first_name} ${userData.last_name}`
              ) : <span>Usuario</span> }
              <span
                className="mx-2"
                style={{
                  color: "#93a5be",
                  fontWeight: 100,
                  fontSize: 14,
                  verticalAlign: "middle",
                }}
              >
                | {role}
              </span>
            </Card.Title>
            <Card.Subtitle className="fw-normal mb-2">
              Fecha de nacimiento:{" "}
              {userData.birth_date.seconds ? formatDateString(toDateTime(userData.birth_date.seconds)) : emptyValue }
            </Card.Subtitle>
            <Card.Text className="text-gray mb-2">
              Número Móvil: {userData.mobile_number || emptyValue }
            </Card.Text>
            <Card.Text className="text-gray mb-4">
              Código Postal: {userData.postal_code || emptyValue }
            </Card.Text>

            <Button
              variant="primary"
              className="me-2"
              onClick={() => setEditing(true)}
            >
              <FontAwesomeIcon icon={faPen} className="me-1" /> Editar
            </Button>
          </div>
        ) : (
          <section className="d-flex align-items-center">
            <Container>
              <div className="bg-white w-100 fmxw-500 m-auto">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">Completa todos los campos requeridos</h5>
                </div>
              </div>
              <Form className="mt-4" onSubmit={updateUser}>
                <Row className="justify-content-center form-bg-image">
                  <Col md={6}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Nombre:</Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={userInfo.first_name}
                          type="text"
                          name="first_name"
                          onChange={handleChangeForm}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Apellido:</Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={userInfo.last_name}
                          type="text"
                          name="last_name"
                          onChange={handleChangeForm}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Código País:</Form.Label>
                      <InputGroup>
                        <Form.Control
                          as="select"
                          className="my-1 mr-sm-2"
                          name="country_info"
                          value={userInfo.country_info["Calling-code"]}
                          onChange={handleChangeForm}
                        >
                          <option defaultValue>Selecciona...</option>
                          {countryCodes.map((code) => (
                            <option
                              value={code["Calling-code"]}
                              key={code["Alpha-2"]}
                            >
                              {code["Alpha-2"]} - {code["Calling-code"]}
                            </option>
                          ))}
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Número Móvil:</Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={userInfo.mobile_number}
                          type="number"
                          name="mobile_number"
                          onChange={handleChangeForm}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Código Postal:</Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={userInfo.postal_code}
                          type="number"
                          name="postal_code"
                          onChange={handleChangeForm}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Fecha de nacimiento:</Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={userInfo.birth_date}
                          type="date"
                          name="birth_date"
                          onChange={handleChangeForm}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Género:</Form.Label>
                      <div className="mb-3">
                        <Form.Check
                          inline
                          label="M"
                          name="gender"
                          type={"radio"}
                          value="M"
                          onChange={handleChangeForm}
                          checked={userInfo.gender === "M"}
                        />
                        <Form.Check
                          inline
                          label="F"
                          name="gender"
                          type={"radio"}
                          value="F"
                          onChange={handleChangeForm}
                          checked={userInfo.gender === "F"}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-right mt-2 mb-4">
                  <Button
                    variant="secondary"
                    className="mx-2"
                    onClick={() => setEditing(false)}
                  >
                    Cancelar
                  </Button>
                  <Button variant="primary" type="submit">
                    Guardar Datos
                  </Button>
                </div>
              </Form>
              <hr />  
              <Form className="mt-4" onSubmit={updatePassword}>
                <Row className="justify-content-center form-bg-image">
                  <p>Actualizar contraseña</p>
                  <Col md={6}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Contraseña actual:</Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={passwordInfo.currentPassword}
                          type="text"
                          name="currentPassword"
                          onChange={handleChangeFormPassword}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Nueva contraseña:</Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={passwordInfo.newPassword}
                          type="text"
                          name="newPassword"
                          onChange={handleChangeFormPassword}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-right mt-2 mb-4">
                  <Button variant="primary" type="submit">
                    { loading && (
                      <FontAwesomeIcon icon={faSpinner} spin className="mx-2" />
                    )}
                    Actualizar
                  </Button>
                </div>
              </Form>
            </Container>
          </section>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProfileCard;