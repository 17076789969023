import React, { useState, useRef } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { AlertModal } from "../../utils/models/AlertModal";
import { Constants } from "../../utils/constants";
import Preloader from "../../components/Preloader";
import useAlert from "../../utils/hooks/useAlert";
import { TransformTemplateViewDetail, toDateTime, getObjectKeyTrue, toDateInputValue } from "../../utils/utils";
import { ViewDetailItemModel } from "../../components/models/ViewDetailItemModel";
import { useEffect } from "react";
import { tableConfig } from "./models/tableConfig";
import DataTable from "../../components/dataTable";
import { UsersAddUpdate } from "./UsersAddUpdate";

export default () => {
  const db = useFirestore();
  const [loading, setLoading] = useState(false);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [viewDetail, setViewDetail] = useState();
  const userPassRef = useRef();
  /* const userRoles = user?.roles_array ? getRolesUser(user.roles_array) : []; */
  const queryUsers = db.collection(Constants.USER_INFO);
  const { data: userDataObserver } = useFirestoreCollectionData(queryUsers);
  const [usersBarest, setUsersBarest] = useState([]);

  useEffect(() => {
    setUsersBarest(userDataObserver.map(item => ({
      ...item,
      id: item['NO_ID_FIELD'],
      gender: getObjectKeyTrue(item.gender),
      birth_date: item.birth_date ? toDateInputValue(toDateTime(item.birth_date.seconds)) : toDateInputValue(new Date())
    })))
  }, [userDataObserver])


  const titlesView = {
    username: "Nombre de usuario",
    first_name: "Nombres",
    last_name: "Apellidos",
    email: "Email",
    birth_date: "Fecha de nacimiento",
  }


  const viewUser = (items) => {
    const item = JSON.parse(JSON.stringify(items));
    item.gender = Constants.GENDER[item.gender];
    let viewDetail = TransformTemplateViewDetail(titlesView, item);
    setViewDetail(new ViewDetailItemModel(
      "Usuario",
      "Datos del usuario:",
      viewDetail
    ));
  }

  const removeUser = item => {
    setLoading(true);
    queryUsers.doc(item.id).delete().then(() => {
      setLoading(false);
      showAlert(`${item.email} eliminado correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoading(false);
      showAlert(`Ocurrió un error al eliminar el usuario ${item.email}.`, "", Constants.TYPE_ALERT.error, "", error);
    });
  }

  const editUser = (item) => userPassRef.current.addOrUpdateUser(item);

  const showAlert = (body, title, typeAlert, typeModal, err) => handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));

  return (
    <>
      <Preloader show={loading ? true : false} />
      <UsersAddUpdate ref={userPassRef} />
      <RenderAlert></RenderAlert>
      <DataTable
        items={usersBarest}
        tableConfig={tableConfig}
        addNewHandler={null}
        editItemHandler={editUser}
        removeItemHandler={removeUser}
        viewItemHandler={viewUser}
        contentViewDetail={viewDetail}
        addNewLabel=""
        searchFilterKey="email"
      />
    </>
  );
};
