export const tableConfig = [
  {
    label: 'NOMBRE',
    key: 'name'
  },
  {
    label: 'Descripción',
    key: 'description'
  },
  {
    label: 'Precio',
    key: 'price'
  },
  {
    label: 'Habilitada',
    key: 'enabled'
  },
  {
    label: 'Habilitada en barra',
    key: 'available_bar'
  }
]

