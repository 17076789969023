export default class Category {
  id;
  UID_branch_document_reference;
  branch_id;
  name;
  position_order;
  is_enabled;
  is_available_in_bar;
  sub_categories;

  constructor(
    _UID_branch_document_reference,
    _name,
    _position_order,
    _is_enabled,
    _is_available_in_bar,
    _branch_id
  ) {
    this.UID_branch_document_reference = _UID_branch_document_reference;
    this.name = _name ?? "";
    this.position_order = _position_order ?? 0;
    this.is_enabled = _is_enabled ?? true;
    this.is_available_in_bar = _is_available_in_bar ?? false;
    this.sub_categories = [];
    this.branch_id = _branch_id ?? "";
  }
}