import React, { useContext, useEffect, useState, useMemo } from "react";
import { Form } from '@themesberg/react-bootstrap';
import { useFirestore } from "reactfire";
import { appContext } from "../state/context";
import { getRolesUser } from "../utils/utils";
import { Constants } from "../utils/constants";
import useAlert from "../utils/hooks/useAlert";
import { AlertModal } from "../utils/models/AlertModal";
import Preloader from "./Preloader";

const BranchSelect = ({ branchSelected }) => {
  const { user, currentEnterprise } = useContext(appContext)
  const [branch, setBranchs] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { handleShowAlert, RenderAlert } = useAlert();
  const db = useFirestore();
  const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid);

  const userRoles = user?.roles_array ? getRolesUser(user.roles_array) : [];
  const isBarest = userRoles.includes(Constants.BAREST) || userRoles.includes(Constants.ADMIN);

  const branchesQuerySearch = useMemo(() => {
    return isBarest ? queryEnterprise.collection(Constants.BRANCHS) : queryEnterprise.collection(Constants.BRANCHS).where("manager_email", "==", user.email)
  }, [isBarest, user, queryEnterprise]);

  useEffect(() => {
    const getBranchXEnterprise = async () => {
      let branchs = [];
      setLoaded(true);
      await branchesQuerySearch.get()
        .then(querySnapshot => {
          setLoaded(false);
          querySnapshot.forEach(async (doc) => {
            branchs.push({ ...doc.data(), id: doc.id });
          })
          setBranchs(branchs);
          branchSelected(branchs.length ? branchs[0] : {});
        })
        .catch(error => {
          setLoaded(false);
          showAlert(`Ocurrió un error al traer los establecimientos.`, "", Constants.TYPE_ALERT.error, "", error);
        });
    }

    getBranchXEnterprise();
    // eslint-disable-next-line
  }, [currentEnterprise])

  const changeBranch = (evt) => {
    const branchCurrent = branch.find(elem => elem.id === evt.target.value.trim());
    branchSelected(branchCurrent);
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  return (
    <>
      <RenderAlert></RenderAlert>
      <Preloader show={loaded ? true : false} />
      <Form.Group className="mb-2">
        <Form.Label>Buscar por establecimiento:</Form.Label>
        <Form.Select id="typeTableBar" onChange={changeBranch}>
          {
            branch.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
          }
        </Form.Select>
      </Form.Group>
    </>
  )
}

export default BranchSelect;