
import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFirestore } from 'reactfire';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Breadcrumb, Card } from '@themesberg/react-bootstrap';

// API
import { getDashboardData } from '../../external'

// state
import { appContext } from "../../state/context";

// components
import Preloader from "../../components/Preloader";

// utils
import { AlertModal } from "../../utils/models/AlertModal";
import useAlert from "../../utils/hooks/useAlert";
import { Constants } from "../../utils/constants";
import { getRolesUser } from "../../utils/utils";

import DashboardCard from "../../components/dashboard-card";
import DashboardProductCard from "../../components/dashboard-product-card";
import { timeIntervals } from "../../utils/models/Lists";

export default (props) => {
  const { handleShowAlert, RenderAlert } = useAlert();
  const { user, currentEnterprise } = useContext(appContext);
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [data, setData] = useState(null);

  // filters
  const [timeInterval, setTimeInterval] = useState(timeIntervals[0]['value']);
  const [branchSelected, setBranchSelected] = useState("");

  // main query
  const db = useFirestore();
  const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid);

  const userRoles = user?.roles_array ? getRolesUser(user.roles_array) : [];
  const isBarestOrAdmin = userRoles.includes(Constants.BAREST) || userRoles.includes(Constants.ADMIN);

  useEffect(() => {
    const getBranchXEnterprise = () => {
      const branchesQuerySearch = isBarestOrAdmin ? queryEnterprise.collection(Constants.BRANCHS) : queryEnterprise.collection(Constants.BRANCHS).where("manager_email", "==", user.email)
      setLoading(true);

      branchesQuerySearch.get().then(querySnapshot => {
        setLoading(false);
        const mappedBranches = [];
        querySnapshot.forEach(async (doc) => {
          mappedBranches.push({ ...doc.data(), id: doc.id })
        });
        setBranches(mappedBranches);
        if (mappedBranches.length > 0)
          setBranchSelected(mappedBranches[0]['id'])
      }).catch(error => {
        setLoading(false);
        showAlert(`Ocurrió un error al traer los establecimientos.`, "", Constants.TYPE_ALERT.error, "", error);
      });

    }

    getBranchXEnterprise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEnterprise]);

  useEffect(() => {
    if (branches.length && branchSelected) {
      getDashboardData(timeInterval, branchSelected).then(res => {
        setLoading(false);
        setData(res);
      }).catch(error => {
        setLoading(false);
        showAlert(`Error al obtener la información.`, "", Constants.TYPE_ALERT.error, "", error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, branchSelected, timeInterval]);

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const changeTimeInterval = evt => {
    setLoading(true);
    setTimeInterval(evt.target.value);
  }

  const changeBranch = (evt) => {
    setLoading(true);
    setBranchSelected(evt.target.value.trim());
  }

  return (
    <>
      <Preloader show={loading ? true : false} />
      <RenderAlert></RenderAlert>
      <Row className="justify-content-md-center">
        <Col xs={12} xl={isBarestOrAdmin ? 8 : 12} className="mb-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-end py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Dashboard</h4>
              <p className="mb-0">A continuación se muestran los datos que reflejan<br />la productividad e interacción con Barest. </p>
            </div>

            <Form.Group>
              <Form.Label>Intervalo de tiempo:</Form.Label>
              <Form.Select id="timeInterval" value={timeInterval} onChange={changeTimeInterval}>
                {
                  timeIntervals.map((item, i) => <option key={i} value={item.value}>{item.name}</option>)
                }
              </Form.Select>
            </Form.Group>
          </div>
        </Col>

        {isBarestOrAdmin && (
          <Col xs={12} xl={4} style={{ marginTop: 14 }} className="mb-4">
            <Card border="light" className="shadow-sm px-4">
              <div className="d-flex flex-column justify-content-between flex-wrap flex-md-nowrap py-4">
                <h4><span className="text-info">Establecimientos:</span> {branches.length}</h4>
                <Form.Group className="mb-2">
                  <Form.Label>Buscar establecimiento:</Form.Label>
                  <Form.Select id="TimeInterval" onChange={changeBranch} value={branchSelected}>
                    {
                      branches.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
                    }
                  </Form.Select>
                </Form.Group>
              </div>
            </Card>
          </Col>
        )}
      </Row>

      {data && (
        <Row>
          <Col xs={12} xl={8}>
            <Row className="justify-content-md-center">
              <Col xs={12} className="mb-4">
                <DashboardCard
                  category="Mesas"
                  id="mesas"
                  timeInterval={timeInterval}
                  data={data?.tables_summary}
                  loading={loading}
                />
              </Col>

              <Col xs={12} className="mb-4">
                <DashboardCard
                  category="Pedidos"
                  id="pedidos"
                  timeInterval={timeInterval}
                  data={data?.request_summary}
                  loading={loading}
                />
              </Col>

              <Col xs={12} className="mb-4">
                <DashboardCard
                  category="Facturación"
                  id="facturacion"
                  timeInterval={timeInterval}
                  data={data?.billing_summary}
                  loading={loading}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={4}>
            <DashboardProductCard
              category={timeIntervals.filter(interval => interval.value === timeInterval)[0].name}
              data={data?.products_summary}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
