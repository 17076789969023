import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef, useCallback, useContext } from "react";
import { Col, Row, Form, Button, Container, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { useFirestore, useFirestoreCollectionData, useStorage } from 'reactfire';
import 'firebase/firestore';
import MultiSelect from "react-multi-select-component";
import { v4 as uuidv4 } from 'uuid';
import { AlertModal } from "../../utils/models/AlertModal";
import { appContext } from "../../state/context";
import Product from './models/ProductsModel';
import { Constants } from "../../utils/constants";
import Orderitems from "../../components/OrderItems";
import useAlert from "../../utils/hooks/useAlert";
import Preloader from "../../components/Preloader";
import { allergensList } from "../../utils/models/Lists";
import { castFields } from "../../utils/utils";

export const ProductAddUpdate = forwardRef(({ nameBranch, currentBranch, currentCategorie, currentSubCategorie, products, currencyBranch, currentRefProduct }, ref) => {
  const { currentEnterprise } = useContext(appContext)
  const [loaded, setLoaded] = useState(false);
  const [allergensField, setAllergensField] = useState([]);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [isSave, setIsSave] = useState(true);
  const [editOrder, setEditOrder] = useState(false);
  const [product, setProduct] = useState(new Product());
  const [productsOrder, setProductsOrder] = useState([]);
  const [changeImage, setchangeImage] = useState(false);
  const [imageFile, setImageFile] = useState({
    fileUpload: "",
    fileName: ""
  });
  const [showModalProduct, setShowModalProduct] = useState(false);
  const storage = useStorage();
  const db = useFirestore();
  const enterpriseID = currentEnterprise?.uid
  const queryProduct = db.collection(Constants.PRODUCT);
  const queryProductLasItem = queryProduct.where("UID_subcategory_document_reference", "==", currentRefProduct).orderBy("position_order", "desc");
  const { data: productsLastItemDataObserver } = useFirestoreCollectionData(queryProductLasItem);
  const childPassRef = useRef();

  const modelItems = {
    id: "position_order",
    name: "name"
  }

  useEffect(() => {
    if (!editOrder)
      setProduct(prevState => ({ ...prevState, position_order: productsLastItemDataObserver.length > 0 ? productsLastItemDataObserver[0].position_order + 1 : 0 }))
  }, [productsLastItemDataObserver, editOrder]);


  useImperativeHandle(ref, () => ({
    addOrUpdateProduct(isUpdate, productItem) {
      if (isUpdate) {
        setIsSave(false);
        setProduct(productItem);
        let itemsAllergens = productItem?.allergens ?? [];
        itemsAllergens = itemsAllergens.map(res => (
          {
            label: res,
            value: res
          }
        ))
        setAllergensField(itemsAllergens);
      }
      else {
        clearForm();
        setAllergensField([]);
        setProduct(prevState => ({ ...prevState, position_order: productsLastItemDataObserver.length > 0 ? productsLastItemDataObserver[0].position_order + 1 : 0 }))
      }
      handleShowModalProduct();
    }
  }));

  const openOrderItems = useCallback(() => {
    setEditOrder(true);
    childPassRef.current.handleShowModal();
  }, [childPassRef]);

  const handleHideModalProduct = () => setShowModalProduct(false);
  const handleShowModalProduct = () => { setShowModalProduct(true) }

  const handleChangeForm = e => {
    const { name, value, type } = e.target;
    setProduct(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const imageProductView = imageFile.fileUpload ? URL.createObjectURL(imageFile.fileUpload) : "";

  const imageUpload = async () => {
    let urlImage = "";
    try {
      setLoaded(true);
      const newRef = storage.ref(`${Constants.URL_IMAGES_PRODUCTS}/${nameBranch}`).child(imageFile.fileName);
      const image = await newRef.put(imageFile.fileUpload);
      urlImage = await image.ref.getDownloadURL();
      setLoaded(false);
    } catch (error) {
      setLoaded(false);
      showAlert(`Ocurrió un error al subir la imágen del producto.`, "", Constants.TYPE_ALERT.error, "", error);
    }
    return urlImage;
  }

  const preLoadImage = (event) => {
    const file = event.target.files;
    const fileImage = {
      fileUpload: file[0],
      fileName: `${product.name}-${uuidv4()}`,
    }
    setImageFile(fileImage);
    setchangeImage(true);
  }

  const isProductName = (event) => {
    setLoaded(true);
    const name = event.target.value;
    queryProduct.where("name", "==", name)
      .where("UID_subcategory_document_reference", "==", currentRefProduct)
      .get()
      .then(querySnapshot => {
        setLoaded(false);
        if (querySnapshot.docs.length > 0) {
          showAlert(`Ya existe un producto con el nombre ${name}`, "", Constants.TYPE_ALERT.success, "");
          setProduct(prevState => ({ ...prevState, name: "" }));
        }
      }).catch((error) => {
        setLoaded(false);
        showAlert(`Ocurrió un error al traer el producto ${name}.`, "", Constants.TYPE_ALERT.error, "", error);
      })
  }

  const addUpdateProduct = async (e) => {
    e.preventDefault();
    const productModelFB = {
      UID_subcategory_document_reference: db.doc(`${Constants.ENTERPRISE}/${enterpriseID}/${Constants.BRANCHS}/${currentBranch}/${Constants.CATEGORIES}/${currentCategorie}/${Constants.SUBCATEGORIES}/${currentSubCategorie}`),
      sku: product.sku,
      name: product.name,
      description: product.description,
      price: product.price,
      position_order: product.position_order,
      is_enabled: product.is_enabled,
      is_available_in_bar: product.is_available_in_bar,
      require_kitchen: product.require_kitchen,
      timer_cook: product.require_kitchen ? product.timer_cook : null,
      allergens: Array.from(allergensField, x => x.value),
      branch_id: currentBranch,
      sub_category_id: currentSubCategorie
    }

    if (!isSave) productModelFB["image_url"] = product.image_url;
    if (changeImage) productModelFB["image_url"] = await imageUpload();
    await updateOrderPosition();
    isSave ? addProduct(productModelFB) : updateProduct(productModelFB);
  }

  const addProduct = (productModel) => {
    setLoaded(true);
    queryProduct.add(
      productModel
    ).then(() => {
      setLoaded(false);
      clearForm();
      handleHideModalProduct();
      showAlert(`Producto ${product.name} creado correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al guardar el producto.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const updateProduct = (productModel) => {
    setLoaded(true);
    queryProduct.doc(product.id).update(
      productModel
    ).then(() => {
      setLoaded(false);
      clearForm();
      handleHideModalProduct();
      showAlert(`Producto ${product.name} actualizado correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al actualizar el producto.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const updateOrderPosition = async () => {
    try {
      setLoaded(true);
      if (productsOrder.length > 1) {
        const batch = db.batch();
        productsOrder.forEach((res, index) => {
          if (res.id && res.name !== product.name) {
            let itemUpdate = queryProduct.doc(res.id);
            batch.update(itemUpdate, { "position_order": index });
          }
        })
        await batch.commit().then().catch();
      }
      setLoaded(false);
    } catch (error) {
      showAlert(`Ocurrió un error al actualizar los productos.`, "", Constants.TYPE_ALERT.error, "", error);
      setLoaded(false);
    }
  }

  const orderProducts = (productsItems) => {
    setProduct(prevState => ({ ...prevState, position_order: productsItems.findIndex(res => res.name === product.name) }));
    setProductsOrder(productsItems);
  }


  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const clearForm = () => {
    setProduct(new Product("", productsLastItemDataObserver[0]?.position_order + 1));
    setchangeImage(false);
    setIsSave(true);
    setImageFile({
      fileUpload: "",
      fileName: ""
    })

  }

  return (
    <section>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <Modal size="lg"
        backdrop="static"
        keyboard={false}
        as={Modal.Dialog} centered show={showModalProduct}>
        <Modal.Header className="m-success">
          <Modal.Title className="h6 text-white">CREAR PRODUCTO</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleHideModalProduct} />
        </Modal.Header>
        <Modal.Body>
          <Orderitems items={isSave ? (productsOrder.length > 0 ? [...productsOrder] : [...products, product]) : products} modelItems={modelItems} saveOrderItems={orderProducts} ref={childPassRef} />
          <section className="d-flex align-items-center">
            <Container>
              <div className="bg-white w-100 fmxw-500 m-auto">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">Completa todos los campos requeridos</h5>
                </div>
              </div>
              <Form className="mt-4" onSubmit={addUpdateProduct}>
                {
                  (product.image_url || imageProductView) ?
                    <div className="text-center">
                      <img src={changeImage ? imageProductView : product.image_url} className="w-50 my-3 img-icon" alt="imágen producto" />
                    </div> : ""
                }
                <Row className="justify-content-center form-bg-image">
                  <Col xs={12} md={6}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Nombre*</Form.Label>
                      <InputGroup>
                        <Form.Control value={product.name} type="text" name="name" onBlur={isProductName} onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="sku" className="mb-4">
                      <Form.Label>SKU</Form.Label>
                      <InputGroup>
                        <Form.Control value={product.sku} type="text" name="sku" onChange={handleChangeForm} />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group id="description" className="mb-4">
                      <Form.Label>Descripción*</Form.Label>
                      <Form.Control as="textarea" rows={3} value={product.description} name="description" onChange={handleChangeForm} required />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-center form-bg-image">
                  <Col xs={12} md={6}>
                    <Form.Group id="price" className="mb-4">
                      <Form.Label>Precio({currencyBranch?.code})*</Form.Label>
                      <InputGroup className="d-flex align-items-center">
                        <div className="input-group-prepend">
                          <div className="input-group-text">{currencyBranch?.symbol}</div>
                        </div>
                        <Form.Control value={product.price} type="number" name="price" onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="is_enabled" className="mb-4">
                      <Form.Label>¿Habilitada?*</Form.Label>
                      <Form.Select value={product.is_enabled} name="is_enabled" onChange={handleChangeForm} required>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="is_available_in_bar" className="mb-4">
                      <Form.Label>¿Habilitada para bar?*</Form.Label>
                      <Form.Select value={product.is_available_in_bar} name="is_available_in_bar" onChange={handleChangeForm} required>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="require_kitchen" className="mb-4">
                      <Form.Label>¿Requiere cocinar?*</Form.Label>
                      <Form.Select value={product.require_kitchen} name="require_kitchen" onChange={handleChangeForm} required>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                {
                  product.require_kitchen ?
                    <Row>
                      <Col md={12}>
                        <Form.Group id="timer_cook" className="mb-4">
                          <Form.Label>Tiempo de cocina(Minutos)*</Form.Label>
                          <InputGroup className="d-flex align-items-center">
                            <Form.Control value={product.timer_cook} type="number" name="timer_cook" onChange={handleChangeForm} required />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row> : ""
                }
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="position_order" className="mb-4">
                      <Form.Label>Orden de visualización*</Form.Label>
                      <div className="d-flex align-items-center">
                        <InputGroup className="pe-2">
                          <Form.Control value={product.position_order} type="number" name="position_order" onChange={handleChangeForm} readOnly required />
                        </InputGroup>
                        <Button variant="secondary" type="button" onClick={openOrderItems} disabled={product.name ? false : true}>Ordenar</Button>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-4">
                      <Form.Label>{isSave ? "Subir" : "Cambiar"} imágen del producto*</Form.Label>
                      <input type="file" accept="image/png, image/jpeg" className="form-control-file" onChange={preLoadImage} required={isSave} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="allergies_field" className="mb-4">
                      <Form.Label>Alérgenos*</Form.Label>
                      <MultiSelect
                        options={allergensList}
                        value={allergensField}
                        onChange={setAllergensField}
                        labelledBy="Seleccionar..."
                        selectAllLabel="Seleccionar todos"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-right mt-4">
                  <Button variant="link" className="text-gray ms-auto" onClick={clearForm}>Limpiar</Button>
                  <Button variant="primary" type="submit">
                    {isSave ? "Guardar" : "Actualizar"}
                  </Button>
                </div>
              </Form>
            </Container>
          </section>
        </Modal.Body>
      </Modal>
    </section>
  )
});