import { currencyString } from '../../utils/utils'

export const getDataForCategory = (id, data) => {
  const { total_current_period, total_before_period } = data;

  let dataToRender = {
    currentValues: total_current_period.values || [],
    beforeValues: total_before_period.values || [],
    currentTicketAverage: total_current_period.ticket_average || '',
    beforeTicketAverage: total_before_period.ticket_average || '',
  }

  switch (id) {
    case 'mesas':
      dataToRender = {
        ...dataToRender,
        active: total_current_period.total_tables,
        currentTotal: total_current_period.total_tables,
        beforeTotal: total_before_period.total_tables,
      };
      break;
    
    case 'pedidos':
      dataToRender = {
        ...dataToRender,
        active: total_current_period.total_request,
        currentTotal: total_current_period.total_request,
        beforeTotal: total_before_period.total_request,
      };
      break;
    
    case 'facturacion':
      dataToRender = {
        ...dataToRender,
        active: null,
        currentTotal: currencyString(total_current_period.total_billing),
        beforeTotal: currencyString(total_before_period.total_billing),
      };
      break;
  
    default:
      dataToRender = {
        ...dataToRender,
        active: total_current_period.total_tables,
        currentTotal: total_current_period.total_tables,
        beforeTotal: total_before_period.total_tables,
      };
      break;
  }

  return dataToRender;
}