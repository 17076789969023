import React, { useState, Fragment } from "react";
import ModalGeneral from "../../components/ModalGeneral/ModalGeneral";

const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => setIsVisible(false);
  const handleShow = () => setIsVisible(true);

  const RenderModal = ({ confirm, content }) => (
    <Fragment>
      {isVisible ? (
        <ModalGeneral
          closeModal={handleClose}
          showModal={isVisible}
          content={content}
          confirm={confirm}
        ></ModalGeneral>
      ) : null}
    </Fragment>
  );

  return {
    handleShow,
    handleClose,
    RenderModal,
  };
};

export default useModal;
