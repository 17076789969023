export class TableAndBarsModel {
  uuid;
  branchId;
  type;
  number;
  unique_qr_code_location;
  max_capacity;
  status;
  is_available;
  current_journal;

  constructor(
    _uuid,
    _branchId,
    _type,
    _number,
    _unique_qr_code_location,
    _max_capacity,
    _status,
    _is_available,
    _current_journal
  ) {
    this.uuid = _uuid ?? "";
    this.branchId = _branchId ?? "";
    this.type = _type ?? "";
    this.number = _number ?? "";
    this.unique_qr_code_location = _unique_qr_code_location ?? "";
    this.max_capacity = _max_capacity ?? 1;
    this.status = _status ?? true;
    this.is_available = _is_available ?? true;
    this.current_journal = _current_journal ?? "";
  }
}
