import React from 'react';
import { Pagination, Nav } from '@themesberg/react-bootstrap';

const PaginationBarest = ({ itemsPerPage, currentPage, totalItems, paginate }) => {
  const pageNumbers = Array.from({ length: Math.ceil(totalItems / itemsPerPage) }, (item, index) => index + 1);

  return (
    <Nav>
      <Pagination className="mb-2 mb-lg-0">
        {
          currentPage === 1 ? ""
            : <Pagination.Prev onClick={() => paginate(currentPage - 1)}>
              Anterior
            </Pagination.Prev>
        }
        {pageNumbers.map(number => (
          <Pagination.Item key={number} onClick={() => paginate(number)} active={currentPage === number ? true : false}>
            {number}
          </Pagination.Item>
        ))}
        {
          currentPage >= pageNumbers.length ? ""
            : <Pagination.Next onClick={() => paginate(currentPage + 1)}>
              Siguiente
            </Pagination.Next>
        }
      </Pagination>
    </Nav>
  );
};

export default PaginationBarest;