
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card } from '@themesberg/react-bootstrap';

// components
import InlineLoader from "../../components/InlineLoader"
import ValueItems from './ValueItems'
import { getDataForCategory } from './categoryData';

// utils
import { Constants } from '../../utils/constants';
import { currencyString } from '../../utils/utils'

// styles
import './index.scss';

const DashboardCard = ({ id, category, timeInterval, data, loading }) => {
  const { variance } = data;
  const [dataToRender, setDataToRender] = useState(getDataForCategory(id, data));

  useEffect(() => {
    setDataToRender(getDataForCategory(id, data));
  }, [data, id])
  
  const getCardHeader = () => {
    const percentageIcon = parseInt(variance) < 0 ? faAngleDown : faAngleUp;
    const percentageColor = parseInt(variance) < 0 ? "text-danger" : "text-success";

    switch (timeInterval) {
      case Constants.TIME_INTERVALS.day:
        return (
          <>
            <Col xs={12} className="d-flex align-items-baseline justify-content-between" style={{ flexWrap: 'wrap' }}>
              <div>
                <h5 className="mb-0 text-info" style={{ minWidth: 90 }}>{category}:</h5>
              </div>
              {id !== 'facturacion' && (
                <div className="cardinfo--header-data">
                  <p className="mb-0 fw-bold">
                    {id === 'mesas' ? 'Activas' : 'En curso'} <span className="text-info" style={{fontSize: '1.25rem', marginLeft: 5}}>{dataToRender.active}</span>
                  </p>
                </div>
              )}
              <div className="cardinfo--header-data xl">
                <div className="mb-0 fw-bold">
                  Total hoy <span className="text-info" style={{fontSize: '1.25rem', marginLeft: 5}}>{dataToRender.currentTotal}</span>
                  <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor}`} style={{marginLeft: 5}}/>
                  <span className="text-success" style={{fontSize: '.8rem'}}>{variance * 100}%</span>                  
                  
                  {id === 'facturacion' && (
                    <p style={{ display: 'block', fontWeight: 300, fontSize: 12, marginBottom: 0 }}>
                      Ticket medio:
                      <strong className="mx-2">{currencyString(dataToRender.currentTicketAverage)}</strong>
                    </p>
                  )}
                </div>
              </div>
              <div className="cardinfo--header-data xl">
                <div className="mb-0 fw-bold">
                  Total ayer <span className="text-warning" style={{fontSize: '1.25rem', marginLeft: 5}}>{dataToRender.beforeTotal}</span>
                  
                  {id === 'facturacion' && (
                    <p style={{ display: 'block', fontWeight: 300, fontSize: 12, marginBottom: 0 }}>
                      Ticket medio:
                      <strong className="mx-2">{currencyString(dataToRender.beforeTicketAverage)}</strong>
                    </p>
                  )}
                </div>
              </div>
            </Col>
          </>
        )
      
      case Constants.TIME_INTERVALS.week:
        return (
          <>
            <div style={{marginRight: 20}}>
              <h5 className="mb-0 text-info">{category}:</h5>
            </div>
            <div className="cardinfo--header-data">
              <p className="mb-0 fw-bold">
                Semana actual <span className="text-info mx-1" style={{fontSize: '1.25rem', marginLeft: 5}}>{dataToRender.currentTotal}</span>
                {id === 'pedidos' && (
                  <span className="mx-1" style={{ fontWeight: 300, fontSize: 12, marginBottom: 0 }}>
                    Ticket medio:
                    <strong className="mx-1">{currencyString(dataToRender.currentTicketAverage)}</strong>
                  </span>
                )}
                <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor}`}/>
                <span className="text-success" style={{fontSize: '.8rem'}}>{variance * 100}%</span>   
              </p>
            </div>
            <div className="cardinfo--header-data">
              <p className="mb-0 fw-bold">
                Semana anterior <span className="text-warning" style={{fontSize: '1.25rem', marginLeft: 5}}>{dataToRender.beforeTotal}</span>
                {id === 'pedidos' && (
                  <span className="mx-1" style={{ fontWeight: 300, fontSize: 12, marginBottom: 0 }}>
                    Ticket medio:
                    <strong className="mx-1">{currencyString(dataToRender.beforeTicketAverage)}</strong>
                  </span>
                )}
              </p>
            </div>
          </>
        )
     
      case Constants.TIME_INTERVALS.month:
        return (
          <>
            <div style={{marginRight: 20}}>
              <h5 className="mb-0 text-info">{category}:</h5>
            </div>
            <div className="cardinfo--header-data">
              <p className="mb-0 fw-bold">
                Mes actual <span className="text-info" style={{fontSize: '1.25rem', marginLeft: 5}}>{dataToRender.currentTotal}</span>
                {id === 'pedidos' && (
                  <span className="mx-1" style={{ fontWeight: 300, fontSize: 12, marginBottom: 0 }}>
                    Ticket medio:
                    <strong className="mx-1">{currencyString(dataToRender.currentTicketAverage)}</strong>
                  </span>
                )}
                <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor}`} style={{marginLeft: 5}}/>
                <span className="text-success" style={{fontSize: '.8rem'}}>{variance * 100}%</span>   
              </p>
            </div>
            <div className="cardinfo--header-data">
              <p className="mb-0 fw-bold">
                Mes anterior <span className="text-warning" style={{fontSize: '1.25rem', marginLeft: 5}}>{dataToRender.beforeTotal}</span>
                {id === 'pedidos' && (
                  <span className="mx-1" style={{ fontWeight: 300, fontSize: 12, marginBottom: 0 }}>
                    Ticket medio:
                    <strong className="mx-1">{currencyString(dataToRender.beforeTicketAverage)}</strong>
                  </span>
                )}
              </p>
            </div>
          </>
        )

      default:
        return <p>day</p>
    }
  }

  const dataByTimeInterval = () => {
    switch (timeInterval) {      
      case Constants.TIME_INTERVALS.week:
        const days = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
        return (
          <ul className="date-item">
            {days.map((item, index) => (
              <li key={item}>
                <p>{item}</p>
                <ValueItems id={id} data={dataToRender} index={index}></ValueItems>
              </li>
            ))}
          </ul>
        )
      
      case Constants.TIME_INTERVALS.month:
        const weeks = new Array(4).fill('');
        return (
          <ul className="date-item">
            {weeks.map((_, index) => (
              <li key={index}>
                <p>Semana {index + 1}</p>
                <ValueItems id={id} data={dataToRender} index={index}></ValueItems>
              </li>
            ))}
          </ul>
        )
    
      default:
        return <p>empty</p>
    }
  }

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        {loading ? (
          <InlineLoader />
        ) : (
          <>
            <Row>
              <Col xs={12} className="d-flex align-items-baseline justify-content-between" style={{ flexWrap: 'wrap' }}>
                {getCardHeader()}
              </Col>
            </Row>
            { timeInterval !== Constants.TIME_INTERVALS.day && (
              <Row className="d-block d-xl-flex align-items-center">
                <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
                  {dataByTimeInterval()}
                </Col>
              </Row>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default DashboardCard;
