import React, { useState, useImperativeHandle, forwardRef, useEffect, useContext, useRef, useCallback } from "react";
import { faPlus, faMinus, faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Form, Button, Container, InputGroup, Modal, Badge, Collapse } from '@themesberg/react-bootstrap';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import 'firebase/firestore';
import { AlertModal } from "../../utils/models/AlertModal";
import Category from './models/CategoryModel';
import { Constants } from "../../utils/constants";
import Orderitems from "../../components/OrderItems";
import useAlert from "../../utils/hooks/useAlert";
import Preloader from "../../components/Preloader";
import { SubCategoryAddUpdate } from "../subCategories/SubCategoryAddUpdate";
import { appContext } from "../../state/context";
import { castFields } from "../../utils/utils";

export const CategoryAddUpdate = forwardRef(({ categories, currentBranch }, ref) => {
  const { currentEnterprise } = useContext(appContext)
  const [loaded, setLoaded] = useState(false);
  const [openAddSubCategory, setOpenAddSubCategory] = useState(false);
  const { handleShowAlert, RenderAlert } = useAlert();
  const [isSave, setIsSave] = useState(true);
  const [showModalCategory, setShowModalCategory] = useState(false);
  const [editOrder, setEditOrder] = useState(false);
  const [category, setCategory] = useState(new Category());
  const [categoriesOrder, setCategoriesOrder] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const db = useFirestore();
  const queryEnterprise = db.collection(Constants.ENTERPRISE).doc(currentEnterprise?.uid);
  const queryBranch = queryEnterprise.collection(Constants.BRANCHS).doc(currentBranch);
  const queryCategories = queryBranch.collection(Constants.CATEGORIES);
  const queryCategoriesLastItem = queryCategories.orderBy("position_order", "desc");
  const querySubCategories = queryCategories.doc(category.id ?? "0").collection(Constants.SUBCATEGORIES).orderBy("position_order");
  const { data: categoriesLastItemDataObserver } = useFirestoreCollectionData(queryCategoriesLastItem);
  const { data: subCategoriesDataObserver } = useFirestoreCollectionData(querySubCategories);
  const childPassRef = useRef();
  const childAddSubCategoryPassRef = useRef();

  const modelItems = {
    id: "position_order",
    name: "name"
  }

  useEffect(() => {
    if (!editOrder)
      setCategory(prevState => ({ ...prevState, position_order: categoriesLastItemDataObserver.length > 0 ? categoriesLastItemDataObserver[0].position_order + 1 : 0 }))
  }, [categoriesLastItemDataObserver, editOrder]);

  useEffect(() => {
    setSubCategories(subCategoriesDataObserver.map(item => ({ ...item, id: item['NO_ID_FIELD'] })))
  }, [subCategoriesDataObserver]);

  useImperativeHandle(ref, () => ({
    addOrUpdateCategory(isUpdate, categoryItem) {
      if (isUpdate) {
        setIsSave(false);
        setOpenAddSubCategory(false);
        setCategory(categoryItem);
        setCategoriesOrder([]);
      }
      else {
        clearForm();
        setCategory(prevState => ({ ...prevState, position_order: categoriesLastItemDataObserver.length > 0 ? categoriesLastItemDataObserver[0].position_order + 1 : 0 }))
        setIsSave(true);
      }
      handleShowModalCategory();
    }
  }));

  const hideSubCategorie = () => setOpenAddSubCategory(false);

  const openOrderItems = useCallback(() => {
    setEditOrder(true);
    childPassRef.current.handleShowModal();
  }, [childPassRef]);

  const openSubCategories = useCallback((isUpdate, branch) => {
    childAddSubCategoryPassRef.current.addOrUpdateSubCategory(isUpdate, branch);
  }, [childAddSubCategoryPassRef]);

  const handleHideModalCategory = () => setShowModalCategory(false);
  const handleShowModalCategory = () => { setShowModalCategory(true) }

  const handleChangeForm = e => {
    const { name, value, type } = e.target;
    setCategory(prevState => ({ ...prevState, [name]: castFields(value, type) }));
  };

  const isCategoryName = (event) => {
    setLoaded(true);
    const name = event.target.value;
    queryCategories.where("name", "==", name).get().then(querySnapshot => {
      setLoaded(false);
      if (querySnapshot.docs.length > 0) {
        showAlert(`Ya existe una categoría con el nombre ${name}`, "", Constants.TYPE_ALERT.success, "");
        setCategory(prevState => ({ ...prevState, name: "" }));
      }
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al traer la categoría ${name}.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const addUpdateCategory = async (e) => {
    e.preventDefault();
    const categoryModelFB = {
      UID_branch_document_reference: db.doc(`/${Constants.ENTERPRISE}/${currentEnterprise?.uid}/${Constants.BRANCHS}/${currentBranch}`),
      name: category.name,
      position_order: category.position_order,
      is_enabled: category.is_enabled,
      is_available_in_bar: category.is_available_in_bar,
      branch_id: currentBranch
    }
    await updateOrderPosition();
    isSave ? addCategory(categoryModelFB) : updateCategory(categoryModelFB);
  }

  const addCategory = (categoryModel) => {
    setLoaded(true);
    queryCategories.add(
      categoryModel
    ).then((docRef) => {
      setLoaded(false);
      saveSubCategories(docRef.id);
      handleHideModalCategory();
      showAlert(`Categoría ${category.name} creada correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al guardar la categoría.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const updateCategory = (categoryModel) => {
    setLoaded(true);
    queryCategories.doc(category.id).update(
      categoryModel
    ).then(() => {
      setLoaded(false);
      clearForm();
      handleHideModalCategory();
      showAlert(`Categoría ${category.name} actualizada correctamente`, "", Constants.TYPE_ALERT.success, "");
    }).catch((error) => {
      setLoaded(false);
      showAlert(`Ocurrió un error al actualizar la categoría.`, "", Constants.TYPE_ALERT.error, "", error);
    })
  }

  const saveSubCategories = async (idCategory) => {
    if (subCategories.length > 0) {
      const batch = db.batch();
      subCategories.forEach((doc) => {
        doc.category_id = idCategory;
        const docRef = queryCategories.doc(idCategory).collection(Constants.SUBCATEGORIES).doc();
        batch.set(docRef, doc);
      });

      await batch.commit().then(() => {
        clearForm();
        handleHideModalCategory();
        showAlert(`Subcategorías guardadas correctamente`, "", Constants.TYPE_ALERT.success, "");
      }).catch((error) => {
        showAlert(`Ocurrió un error al guardadar las subcategorías.`, "", Constants.TYPE_ALERT.error, "", error);
      })
    }
  }

  const updateOrderPosition = async () => {
    if (categoriesOrder.length > 1) {
      const batch = db.batch();
      categoriesOrder.forEach((res, index) => {
        if (res.id && res.name !== category.name) {
          let itemUpdate = queryCategories.doc(res.id);
          batch.update(itemUpdate, { "position_order": index });
        }
      })
      await batch.commit().then().catch();
    }
  }

  const addSubCategories = (allSubCategories) => {
    setSubCategories([...allSubCategories]);
  }

  const showAlert = (body, title, typeAlert, typeModal, err) => {
    handleShowAlert(new AlertModal(body, title, typeAlert, typeModal, err));
  }

  const editSubCategory = (subCategory) => {
    setOpenAddSubCategory(true);
    openSubCategories(true, subCategory);
  }

  const orderCategories = (categoriesItems) => {
    setCategory(prevState => ({ ...prevState, position_order: categoriesItems.findIndex(res => res.name === category.name) }));
    setCategoriesOrder(categoriesItems);
  }

  const clearForm = () => {
    setCategory(new Category("", "", categoriesLastItemDataObserver.length > 0 ? categoriesLastItemDataObserver[0].position_order + 1 : 0));
    setSubCategories([]);
    setOpenAddSubCategory(false);
    setCategoriesOrder([]);
    setIsSave(true);
  }

  return (
    <section>
      <Preloader show={loaded ? true : false} />
      <RenderAlert></RenderAlert>
      <Modal size="lg"
        backdrop="static"
        keyboard={false}
        as={Modal.Dialog} centered show={showModalCategory}>
        <Modal.Header className="m-success">
          <Modal.Title className="h6 text-white">CREAR CATEGORIA</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleHideModalCategory} />
        </Modal.Header>
        <Modal.Body>
          <Orderitems items={isSave ? (categoriesOrder.length > 0 ? [...categoriesOrder] : [...categories, category]) : categories} modelItems={modelItems} saveOrderItems={orderCategories} ref={childPassRef} />
          <section className="d-flex align-items-center">
            <Container>
              <div className="bg-white w-100 fmxw-500 m-auto">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">Completa todos los campos requeridos</h5>
                </div>
              </div>
              <Form className="mt-4" onSubmit={addUpdateCategory}>
                <Row className="justify-content-center form-bg-image">
                  <Col xs={12} md={6}>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Nombre*</Form.Label>
                      <InputGroup>
                        <Form.Control value={category.name} type="text" name="name" onBlur={isCategoryName} onChange={handleChangeForm} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="is_enabled" className="mb-4">
                      <Form.Label>¿Habilitada?*</Form.Label>
                      <Form.Select value={category.is_enabled} name="is_enabled" onChange={handleChangeForm} required>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group id="is_available_in_bar" className="mb-4">
                      <Form.Label>¿Habilitada para bar?*</Form.Label>
                      <Form.Select value={category.is_available_in_bar} name="is_available_in_bar" onChange={handleChangeForm} required>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group id="position_order" className="mb-4">
                      <Form.Label>Orden de visualización*</Form.Label>
                      <div className="d-flex align-items-center">
                        <InputGroup className="pe-2">
                          <Form.Control value={category.position_order} type="number" name="position_order" onChange={handleChangeForm} readOnly required />
                        </InputGroup>
                        <Button variant="secondary" type="button" onClick={openOrderItems} disabled={category.name ? false : true}>Ordenar</Button>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h6 className="bold mt-2 mb-2 d-block">
                      Subcategorías <FontAwesomeIcon onClick={() => setOpenAddSubCategory(!openAddSubCategory)} icon={openAddSubCategory ? faMinus : faPlus} className={`mx-1 ${openAddSubCategory ? "text-danger" : "text-soft-green"} c-pointer`} />
                    </h6>
                    <div>
                      {
                        subCategories.map((item, index) =>
                          <Button className="m-1" variant="tertiary" key={index}>
                            {item.name}
                            <Badge variant="light">
                              {
                                isSave ? <FontAwesomeIcon className="text-danger" icon={faTimes} onClick={() => setSubCategories(subCategories.filter(res => res.name !== item.name))} /> :
                                  <FontAwesomeIcon icon={faPencilAlt} onClick={() => editSubCategory(item)} />
                              }
                            </Badge>
                          </Button>
                        )
                      }
                    </div>
                  </Col>
                </Row>
                <div className="text-right mt-4">
                  <Button variant="link" className="text-gray ms-auto" onClick={clearForm}>Limpiar</Button>
                  <Button variant="primary" type="submit">
                    {isSave ? "Guardar" : "Actualizar"}
                  </Button>
                </div>
              </Form>
              <hr></hr>
              <Collapse in={openAddSubCategory}>
                <section>
                  {
                    <SubCategoryAddUpdate
                      isSaveCategory={isSave}
                      currentBranch={currentBranch}
                      currentCategory={!isSave ? category.id : "0"}
                      addSubCategories={addSubCategories}
                      subCategories={subCategories}
                      ref={childAddSubCategoryPassRef}
                      hidePanelSubCategorie={hideSubCategorie} />
                  }
                </section>
              </Collapse>
            </Container>
          </section>
        </Modal.Body>
      </Modal>
    </section>
  )
});