import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import { Modal, Button } from '@themesberg/react-bootstrap';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const Orderitems = forwardRef(({ items, modelItems, saveOrderItems }, ref) => {

  const [allItems, setAllItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  useEffect(() => {
    setAllItems(items);
  }, [items])

  useImperativeHandle(ref, () => ({
    handleShowModal() { setShowModal(true) }
  }));

  const handleHideModal = () => setShowModal(false);

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    border: "0.0625rem solid rgba(46, 54, 80, 0.125)",
    ...draggableStyle
  });

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const itemsOrder = reorder(
      allItems,
      result.source.index,
      result.destination.index
    );

    setAllItems(itemsOrder);
  }

  const saveOrder = () => {
    saveOrderItems(allItems);
    handleHideModal();
  }

  return (
    <Modal size="md"
      backdrop="static"
      keyboard={false}
      as={Modal.Dialog} centered show={showModal}>
      <Modal.Header className="m-success">
        <Modal.Title className="h6 text-white">ORDENAR</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleHideModal} />
      </Modal.Header>
      <Modal.Body className="text-center m-auto w-100">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="w-100"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {allItems.map((item, index) => (
                  <Draggable className="list-group" key={`${item[modelItems["id"]]}-${index}`} draggableId={`${item[modelItems["id"]]}-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <div className="list-group-item list-group-item-action list-group-item-success"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item[modelItems["name"]]}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Modal.Footer>
          <div className="text-right mt-4">
            <Button variant="link" className="text-gray ms-auto" onClick={handleHideModal}>Cancelar</Button>
            <Button variant="primary" type="button" onClick={saveOrder}>Aceptar</Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
});

export default Orderitems;
