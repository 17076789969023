export default class StaffUser {
  email;
  is_active;
  creation_date_time;
  status_date_time;

  constructor(_email) {
    this.email = _email ?? '';
    this.is_active = true;
    this.creation_date_time = new Date();
    this.status_date_time = new Date();
  }
}