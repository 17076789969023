import React, { Suspense } from "react";
import { useFirebaseApp } from 'reactfire';
import 'firebase/app-check';
import { BrowserRouter } from "react-router-dom";
import { FetchInterceptorRequest } from './utils/interceptors/request';
import Preloader from "./components/Preloader";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";

import Ribbon from './components/Ribbon/Ribbon';
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";

// Create your reCAPTCHA v3 site key in the
// "Project Settings > App Check" section of the Firebase console
const APP_CHECK_TOKEN = '6LcrqfYhAAAAAFfPrGLP8iRwSySkAEsdcfMKIUnT';

export const AppCheckFirebase = () => {
  const firebaseApp = useFirebaseApp();
  const appCheck = firebaseApp.appCheck();
  appCheck.activate(APP_CHECK_TOKEN, true);

  return (
    <>
      <FetchInterceptorRequest></FetchInterceptorRequest>
      <Suspense fallback={<Preloader show={true} />}>
        {process.env.REACT_APP_ENV !== "PROD" ? <Ribbon /> : ''}
        <BrowserRouter>
          <ScrollToTop />
          <HomePage />
        </BrowserRouter>
        <div id='modal' />
        <div id='alerts' className="position-fixed" />
      </Suspense>
    </>
  );
}